const RenderLgvGraphicModeMetaData = ({ lgvData, selectedLgvGraphicMode }) => {
  const nullChecker = (value) => (value !== null ? value : "null");

  const isTruthy = (value) => (value ? "Yes" : "No");

  const sensorModeDetails = [
    {
      key: "verticalRightPLSDirty",
      title: "Vertical Right PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "verticalLeftPLSDirty",
      title: "Vertical Left PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "rightPLSDirty",
      title: "Right PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "leftPLSDirty",
      title: "Left PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "backwardPLSDirty",
      title: "Backward PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "forwardPLSDirty",
      title: "Forward PLS Dirty",
      dataFormatter: isTruthy,
    },
    {
      key: "angle",
      title: "Heading Angle",
      dataFormatter: nullChecker,
    },
    {
      key: "navQuality",
      title: "Navigation Quality (%)",
      dataFormatter: nullChecker,
    },
    {
      key: "seenReflectors",
      title: "Reflectors Seen",
      dataFormatter: nullChecker,
    },
    {
      key: "stateOfCharge",
      title: "State of Charge (%)",
      dataFormatter: nullChecker,
    },
    {
      key: "telescopicPolePosition",
      title: "Telescopic Pole Position",
      dataFormatter: nullChecker,
    },
  ];

  const alarmModeDetails = [
    {
      key: "alarmName",
      title: "Alarm Name",
      dataFormatter: nullChecker,
    },
    {
      key: "duration",
      title: "Alarm Duration (Mins)",
      dataFormatter: nullChecker,
    },
    {
      key: "associatedAlarms",
      title: "Associated Alarms",
      dataFormatter: nullChecker,
    },
  ];

  const RenderMetaData = ({ title, value }) => {
    return (
      <div className="lgv-meta-data">
        <p className="lgv-meta-data-title">{title}</p>
        <p className="lgv-meta-data-value">{value}</p>
      </div>
    );
  };

  return (
    selectedLgvGraphicMode === "alarm" ? alarmModeDetails : sensorModeDetails
  )?.map(({ key, title, dataFormatter }) => {
    if (lgvData[key] !== undefined) {
      return (
        <RenderMetaData title={title} value={dataFormatter(lgvData[key])} />
      );
    } else {
      return null;
    }
  });
};

export default RenderLgvGraphicModeMetaData;
