import { useEffect, useState } from "react";
import infoIcon from "../../assets/icons/info.png";
import {
  getLocalStorageItem,
  onDragging,
  onDraggingEnd,
} from "../../util/helper/helperFunctions";
import "../../assets/css/Status/removedStatus.css";
import moment from "moment";
import { DB_ERROR } from "../../util/helper/constants";

const formatDuration = (minutes) => {
  if (minutes >= 1440)
    return (
      <>
        <span className="rm-status-duration-number">
          {Math.floor(minutes / 1440)}
        </span>
        <span className="rm-status-duration-duration">
          day{minutes >= 2880 ? "s" : ""}
        </span>
      </>
    );
  if (minutes >= 60)
    return (
      <>
        <span className="rm-status-duration-number">
          {Math.floor(minutes / 60)}
        </span>
        <span className="rm-status-duration-duration">
          hour{minutes >= 120 ? "s" : ""}
        </span>
      </>
    );
  return (
    <>
      <span className="rm-status-duration-number">{minutes}</span>
      <span className="rm-status-duration-duration">
        min{minutes !== 1 ? "s" : ""}
      </span>
    </>
  );
};

const loader = () => {
  return (
    <div className="rm-loader">
      <h4 data-testid="loading-text">Loading...</h4>
      <div className="spinner"></div>
    </div>
  );
};

const RemovedStatus = ({ title, scrollable, socket }) => {
  const [removedStatusLoader, setRemovedStatusLoader] = useState(true);
  const [removedStatusData, setRemovedStatusData] = useState({});

  useEffect(() => {
    let auth = getLocalStorageItem("auth");
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (auth !== null && locationCodeLocal) {
      const initiateFleetStatusWebSocket = () => {
        socket.on(`${locationCodeLocal}_LGV_FLEET_STATUS`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== DB_ERROR
          ) {
            setRemovedStatusData((removedStatusData) => ({
              ...removedStatusData,
              fleetStatus: data.results.lgvStatus,
            }));
          }
        });
      };

      const initiateFleetCountWebSocket = () => {
        socket.on(`${locationCodeLocal}_LGV_FLEET_COUNT`, (data) => {
          if (
            data.results &&
            data.results !== undefined &&
            data.results !== DB_ERROR
          ) {
            if (data.results.fleetData.length !== 0) {
              setRemovedStatusData((removedStatusData) => ({
                ...removedStatusData,
                fleetCount: data.results.fleetData,
              }));
            }
          }
        });
      };
      initiateFleetStatusWebSocket();
      initiateFleetCountWebSocket();
    }
  }, [
    socket,
    getLocalStorageItem("locationCode"),
    removedStatusData.fleetStatus,
    removedStatusData.fleetCount,
  ]);

  useEffect(() => {
    if (
      (removedStatusData?.fleetStatus !== undefined &&
        removedStatusData?.fleetStatus.length !== 0) ||
      (removedStatusData?.fleetCount !== undefined &&
        removedStatusData?.fleetCount.length !== 0)
    ) {
      setRemovedStatusLoader(false);
    }
  }, [removedStatusData.fleetStatus, removedStatusData.fleetCount]);

  const renderFleetStatusAndCount = (removedStatusData) => {
    if (removedStatusData.fleetCount && removedStatusData.fleetStatus) {
      return (
        <p data-testid="empty-removed-lgv">
          {removedStatusData.fleetStatus.length} LGVs Out of System (Fleet Size:{" "}
          {removedStatusData.fleetCount[0]?.fleetSize})
        </p>
      );
    } else {
      return <p>0 LGVs Out of System (Fleet Size: 0)</p>;
    }
  };

  const renderFleetObjects = (removedStatusData) => {
    if (
      removedStatusData.fleetStatus &&
      removedStatusData.fleetStatus.length !== 0
    ) {
      return (
        <div
          className={`rm-status-section ${
            scrollable ? "rm-status-section-wrap" : ""
          } `}
        >
          {removedStatusData.fleetStatus
            ?.sort((prev, next) => next.duration - prev.duration)
            ?.map((fleet, index) => (
              <div
                data-testid="empty-removed-lgv"
                key={index}
                className="status-box"
              >
                <div className="status-top-section">
                  <p>
                    <span data-testid="fleet-status-test-id">
                      LGV: {fleet.lgv}
                    </span>
                  </p>
                </div>
                <div className="status-bottom-section">
                  {formatDuration(fleet.duration)}
                </div>
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <div
          className={`rm-status-section ${
            scrollable ? "rm-status-section-wrap" : ""
          } `}
        >
          <div class="empty-removed-status-container">
            <p>No Removed LGVs at the moment.</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      onMouseDown={(event) => onDragging(event)}
      onMouseUp={(event) => onDraggingEnd(event)}
      className="rm-lgv-fleet-status-container"
    >
      {removedStatusLoader ? (
        loader()
      ) : (
        <>
          <div className="fleet-container">
            <div className="fleet-status">
              <img src={infoIcon} />
              {renderFleetStatusAndCount(removedStatusData)}
            </div>
          </div>

          <div className="rm-status-container">
            <div className="rm-header-section">
              <h4>{title}</h4>
            </div>
            {renderFleetObjects(removedStatusData)}
          </div>
        </>
      )}
    </div>
  );
};

export default RemovedStatus;
