// Toggle this flag to fetch configs from \public\dummyAPI\plants folder
export const fetchFromLocalPlantsJSON = false;

export const chunkSizeForBlockStorage = 10; // chunk group being rendered, the smaller the better
export const delayInRenderingEachChunk = 200; // delay between each chunk being rendered, the smaller the better

export const DB_ERROR = "Error connecting to datasource!";

export const userDataObject = {
  businessPhones: [],
  displayName: "",
  givenName: "",
  jobTitle: null,
  mail: "",
  mobilePhone: null,
  officeLocation: "",
  preferredLanguage: null,
  surname: "",
  userPrincipalName: "",
  id: "",
};

export const heatmapHorizontalCount = 25;
export const heatmapVerticalCount = 57;

export const plantCarLatSize = 27;
export const plantCarLngSize = 27;

export const heatMapGridX = -1750.3403361344538;
export const heatMapGridY = 538.1567444620252;

export const navHeatmapMaxRetry = 10;

export const defaultMarkerData = {
  vehicleNumber: "1",
  status: "null",
  source: "null",
  destination: "null",
  segment: "null",
  currentPos: { lat: 1, lng: 1 },
  duration: "1",
  alarmName: "null",
  sku: "1",
  lpn: "1",
};

export const getColorPallets = () => {
  return [
    {
      name: "LGV LEGENDS",
      colors: {
        Available: "#008000",
        Loaded: "#0000FF",
        "In Operation/traffic": "#D96E0B",
        Deadlock: "#EF1FDA",
        Lost: "#B9B9B9",
        Removed: "#000000",
        "In Alarm": "#EE3232",
        "In Manual": "#800000",
        Charging: "#83C4D9",
      },
    },
    {
      name: "PRODUCTION LINE LEGENDS",
      colors: {
        "Line Running": "#4133E1",
        "Pallets waiting for pickup": "#42C721",
        "# LGVs to pickup at line": "#238749",
      },
    },
    {
      name: "TRAILER PALLET LEGENDS",
      colors: {
        Completed: "#42C721",
        Released: "#E1CC0F",
        Allocated: "#B9B9B9",
        "In Transit/Started": "#4133E1",
        "Can Allocate": "#D90000",
        New: "#FF5733",
      },
    },
  ];
};

export const mockPalletData = {
  location: "mock",
  position: 1,
  row: 2,
  level: 1,
  lpn: "00800275410100072393",
  item: "LESS15AK95V03TRIALS4",
  itemType: "LABL",
  qty: 220,
  lot: null,
  productionDate: "2023-04-03T07:57:00.000Z",
  expirationDate: "2024-10-05T00:00:00.000Z",
};

export const Level0Config = {
  A0101: ["B0101", "B0102", "B0201", "B0202"],
  A0102: ["B0103", "B0104", "B0203", "B0204"],
  A0103: ["B0105", "B0106", "B0205", "B0206"],
  A0104: ["B0107", "B0108", "B0207", "B0208"],
  A0105: ["B0109", "B0110", "B0209", "B0210"],
  A0106: ["B0111", "B0112", "B0211", "B0212"],
  A0107: ["B0113", "B0114", "B0213", "B0214"],
  A0108: ["B0115", "B0116", "B0215", "B0216"],
  A0201: ["B0301", "B0302", "B0401", "B0402"],
  A0202: ["B0303", "B0304", "B0403", "B0404"],
  A0203: ["B0305", "B0306", "B0405", "B0406"],
  A0204: ["B0307", "B0308", "B0407", "B0408"],
  A0205: ["B0309", "B0310", "B0409", "B0410"],
  A0206: ["B0311", "B0312", "B0411", "B0412"],
  A0207: ["B0313", "B0314", "B0413", "B0414"],
  A0208: ["B0315", "B0316", "B0415", "B0416"],
  A0301: ["B0501", "B0502", "B0601", "B0602"],
  A0302: ["B0503", "B0504", "B0603", "B0604"],
  A0303: ["B0505", "B0506", "B0605", "B0606"],
  A0304: ["B0507", "B0508", "B0607", "B0608"],
  A0305: ["B0509", "B0510", "B0609", "B0610"],
  A0306: ["B0511", "B0512", "B0611", "B0612"],
  A0307: ["B0513", "B0514", "B0613", "B0614"],
  A0308: ["B0515", "B0516", "B0615", "B0616"],
  A0401: ["B0701", "B0702", "B0801", "B0802"],
  A0402: ["B0703", "B0704", "B0803", "B0804"],
  A0403: ["B0705", "B0706", "B0805", "B0806"],
  A0404: ["B0707", "B0708", "B0807", "B0808"],
  A0405: ["B0709", "B0710", "B0809", "B0810"],
  A0406: ["B0711", "B0712", "B0811", "B0812"],
  A0407: ["B0713", "B0714", "B0813", "B0814"],
  A0408: ["B0715", "B0716", "B0815", "B0816"],
};

export const Level1Config = {
  B0101: ["C0101", "C0102", "C0201", "C0202"],
  B0102: ["C0103", "C0104", "C0203", "C0204"],
  B0103: ["C0105", "C0106", "C0205", "C0206"],
  B0104: ["C0107", "C0108", "C0207", "C0208"],
  B0105: ["C0109", "C0110", "C0209", "C0210"],
  B0106: ["C0111", "C0112", "C0211", "C0212"],
  B0107: ["C0113", "C0114", "C0213", "C0214"],
  B0108: ["C0115", "C0116", "C0215", "C0216"],
  B0109: ["C0117", "C0118", "C0217", "C0218"],
  B0110: ["C0119", "C0120", "C0219", "C0220"],
  B0111: ["C0121", "C0122", "C0221", "C0222"],
  B0112: ["C0123", "C0124", "C0223", "C0224"],
  B0113: ["C0125", "C0126", "C0225", "C0226"],
  B0114: ["C0127", "C0128", "C0227", "C0228"],
  B0115: ["C0129", "C0130", "C0229", "C0230"],
  B0116: ["C0131", "C0132", "C0231", "C0232"],
  B0201: ["C0301", "C0302", "C0401", "C0402"],
  B0202: ["C0303", "C0304", "C0403", "C0404"],
  B0203: ["C0305", "C0306", "C0405", "C0406"],
  B0204: ["C0307", "C0308", "C0407", "C0408"],
  B0205: ["C0309", "C0310", "C0409", "C0410"],
  B0206: ["C0311", "C0312", "C0411", "C0412"],
  B0207: ["C0313", "C0314", "C0413", "C0414"],
  B0208: ["C0315", "C0316", "C0415", "C0416"],
  B0209: ["C0317", "C0318", "C0417", "C0418"],
  B0210: ["C0319", "C0320", "C0419", "C0420"],
  B0211: ["C0321", "C0322", "C0421", "C0422"],
  B0212: ["C0323", "C0324", "C0423", "C0424"],
  B0213: ["C0325", "C0326", "C0425", "C0426"],
  B0214: ["C0327", "C0328", "C0427", "C0428"],
  B0215: ["C0329", "C0330", "C0429", "C0430"],
  B0216: ["C0331", "C0332", "C0431", "C0432"],
  B0301: ["C0501", "C0502", "C0601", "C0602"],
  B0302: ["C0503", "C0504", "C0603", "C0604"],
  B0303: ["C0505", "C0506", "C0605", "C0606"],
  B0304: ["C0507", "C0508", "C0607", "C0608"],
  B0305: ["C0509", "C0510", "C0609", "C0610"],
  B0306: ["C0511", "C0512", "C0611", "C0612"],
  B0307: ["C0513", "C0514", "C0613", "C0614"],
  B0308: ["C0515", "C0516", "C0615", "C0616"],
  B0309: ["C0517", "C0518", "C0617", "C0618"],
  B0310: ["C0519", "C0520", "C0619", "C0620"],
  B0311: ["C0521", "C0522", "C0621", "C0622"],
  B0312: ["C0523", "C0524", "C0623", "C0624"],
  B0313: ["C0525", "C0526", "C0625", "C0626"],
  B0314: ["C0527", "C0528", "C0627", "C0628"],
  B0315: ["C0529", "C0530", "C0629", "C0630"],
  B0316: ["C0531", "C0532", "C0631", "C0632"],
  B0401: ["C0701", "C0702", "C0801", "C0802"],
  B0402: ["C0703", "C0704", "C0803", "C0804"],
  B0403: ["C0705", "C0706", "C0805", "C0806"],
  B0404: ["C0707", "C0708", "C0807", "C0808"],
  B0405: ["C0709", "C0710", "C0809", "C0810"],
  B0406: ["C0711", "C0712", "C0811", "C0812"],
  B0407: ["C0713", "C0714", "C0813", "C0814"],
  B0408: ["C0715", "C0716", "C0815", "C0816"],
  B0409: ["C0717", "C0718", "C0817", "C0818"],
  B0410: ["C0719", "C0720", "C0819", "C0820"],
  B0411: ["C0721", "C0722", "C0821", "C0822"],
  B0412: ["C0723", "C0724", "C0823", "C0824"],
  B0413: ["C0725", "C0726", "C0825", "C0826"],
  B0414: ["C0727", "C0728", "C0827", "C0828"],
  B0415: ["C0729", "C0730", "C0829", "C0830"],
  B0416: ["C0731", "C0732", "C0831", "C0832"],
  B0501: ["C0901", "C0902", "C1001", "C1002"],
  B0502: ["C0903", "C0904", "C1003", "C1004"],
  B0503: ["C0905", "C0906", "C1005", "C1006"],
  B0504: ["C0907", "C0908", "C1007", "C1008"],
  B0505: ["C0909", "C0910", "C1009", "C1010"],
  B0506: ["C0911", "C0912", "C1011", "C1012"],
  B0507: ["C0913", "C0914", "C1013", "C1014"],
  B0508: ["C0915", "C0916", "C1015", "C1016"],
  B0509: ["C0917", "C0918", "C1017", "C1018"],
  B0510: ["C0919", "C0920", "C1019", "C1020"],
  B0511: ["C0921", "C0922", "C1021", "C1022"],
  B0512: ["C0923", "C0924", "C1023", "C1024"],
  B0513: ["C0925", "C0926", "C1025", "C1026"],
  B0514: ["C0927", "C0928", "C1027", "C1028"],
  B0515: ["C0929", "C0930", "C1029", "C1030"],
  B0516: ["C0931", "C0932", "C1031", "C1032"],
  B0601: ["C1101", "C1102", "C1201", "C1202"],
  B0602: ["C1103", "C1104", "C1203", "C1204"],
  B0603: ["C1105", "C1106", "C1205", "C1206"],
  B0604: ["C1107", "C1108", "C1207", "C1208"],
  B0605: ["C1109", "C1110", "C1209", "C1210"],
  B0606: ["C1111", "C1112", "C1211", "C1212"],
  B0607: ["C1113", "C1114", "C1213", "C1214"],
  B0608: ["C1115", "C1116", "C1215", "C1216"],
  B0609: ["C1117", "C1118", "C1217", "C1218"],
  B0610: ["C1119", "C1120", "C1219", "C1220"],
  B0611: ["C1121", "C1122", "C1221", "C1222"],
  B0612: ["C1123", "C1124", "C1223", "C1224"],
  B0613: ["C1125", "C1126", "C1225", "C1226"],
  B0614: ["C1127", "C1128", "C1227", "C1228"],
  B0615: ["C1129", "C1130", "C1229", "C1230"],
  B0616: ["C1131", "C1132", "C1231", "C1232"],
  B0701: ["C1301", "C1302", "C1401", "C1402"],
  B0702: ["C1303", "C1304", "C1403", "C1404"],
  B0703: ["C1305", "C1306", "C1405", "C1406"],
  B0704: ["C1307", "C1308", "C1407", "C1408"],
  B0705: ["C1309", "C1310", "C1409", "C1410"],
  B0706: ["C1311", "C1312", "C1411", "C1412"],
  B0707: ["C1313", "C1314", "C1413", "C1414"],
  B0708: ["C1315", "C1316", "C1415", "C1416"],
  B0709: ["C1317", "C1318", "C1417", "C1418"],
  B0710: ["C1319", "C1320", "C1419", "C1420"],
  B0711: ["C1321", "C1322", "C1421", "C1422"],
  B0712: ["C1323", "C1324", "C1423", "C1424"],
  B0713: ["C1325", "C1326", "C1425", "C1426"],
  B0714: ["C1327", "C1328", "C1427", "C1428"],
  B0715: ["C1329", "C1330", "C1429", "C1430"],
  B0716: ["C1331", "C1332", "C1431", "C1432"],
  B0801: ["C1501", "C1502", "C1601", "C1602"],
  B0802: ["C1503", "C1504", "C1603", "C1604"],
  B0803: ["C1505", "C1506", "C1605", "C1606"],
  B0804: ["C1507", "C1508", "C1607", "C1608"],
  B0805: ["C1509", "C1510", "C1609", "C1610"],
  B0806: ["C1511", "C1512", "C1611", "C1612"],
  B0807: ["C1513", "C1514", "C1613", "C1614"],
  B0808: ["C1515", "C1516", "C1615", "C1616"],
  B0809: ["C1517", "C1518", "C1617", "C1618"],
  B0810: ["C1519", "C1520", "C1619", "C1620"],
  B0811: ["C1521", "C1522", "C1621", "C1622"],
  B0812: ["C1523", "C1524", "C1623", "C1624"],
  B0813: ["C1525", "C1526", "C1625", "C1626"],
  B0814: ["C1527", "C1528", "C1627", "C1628"],
  B0815: ["C1529", "C1530", "C1629", "C1630"],
  B0816: ["C1531", "C1532", "C1631", "C1632"],
};

export const level2Config = [
  { cellName: "C0101", startX: 38784, startY: 51560, endX: 44964, endY: 60760 },
  { cellName: "C0102", startX: 44964, startY: 51560, endX: 51144, endY: 60760 },
  { cellName: "C0103", startX: 51144, startY: 51560, endX: 57324, endY: 60760 },
  { cellName: "C0104", startX: 57324, startY: 51560, endX: 63504, endY: 60760 },
  { cellName: "C0105", startX: 63504, startY: 51560, endX: 69684, endY: 60760 },
  { cellName: "C0106", startX: 69684, startY: 51560, endX: 75864, endY: 60760 },
  { cellName: "C0107", startX: 75864, startY: 51560, endX: 82044, endY: 60760 },
  { cellName: "C0108", startX: 82044, startY: 51560, endX: 88224, endY: 60760 },
  { cellName: "C0109", startX: 88224, startY: 51560, endX: 94404, endY: 60760 },
  {
    cellName: "C0110",
    startX: 94404,
    startY: 51560,
    endX: 100584,
    endY: 60760,
  },
  {
    cellName: "C0111",
    startX: 100584,
    startY: 51560,
    endX: 106764,
    endY: 60760,
  },
  {
    cellName: "C0112",
    startX: 106764,
    startY: 51560,
    endX: 112944,
    endY: 60760,
  },
  {
    cellName: "C0113",
    startX: 112944,
    startY: 51560,
    endX: 119124,
    endY: 60760,
  },
  {
    cellName: "C0114",
    startX: 119124,
    startY: 51560,
    endX: 125304,
    endY: 60760,
  },
  {
    cellName: "C0115",
    startX: 125304,
    startY: 51560,
    endX: 131484,
    endY: 60760,
  },
  {
    cellName: "C0116",
    startX: 131484,
    startY: 51560,
    endX: 137664,
    endY: 60760,
  },
  {
    cellName: "C0117",
    startX: 137664,
    startY: 51560,
    endX: 143844,
    endY: 60760,
  },
  {
    cellName: "C0118",
    startX: 143844,
    startY: 51560,
    endX: 150024,
    endY: 60760,
  },
  {
    cellName: "C0119",
    startX: 150024,
    startY: 51560,
    endX: 156204,
    endY: 60760,
  },
  {
    cellName: "C0120",
    startX: 156204,
    startY: 51560,
    endX: 162384,
    endY: 60760,
  },
  {
    cellName: "C0121",
    startX: 162384,
    startY: 51560,
    endX: 168564,
    endY: 60760,
  },
  {
    cellName: "C0122",
    startX: 168564,
    startY: 51560,
    endX: 174744,
    endY: 60760,
  },
  {
    cellName: "C0123",
    startX: 174744,
    startY: 51560,
    endX: 180924,
    endY: 60760,
  },
  {
    cellName: "C0124",
    startX: 180924,
    startY: 51560,
    endX: 187104,
    endY: 60760,
  },
  {
    cellName: "C0125",
    startX: 187104,
    startY: 51560,
    endX: 193284,
    endY: 60760,
  },
  {
    cellName: "C0126",
    startX: 193284,
    startY: 51560,
    endX: 199464,
    endY: 60760,
  },
  {
    cellName: "C0127",
    startX: 199464,
    startY: 51560,
    endX: 205644,
    endY: 60760,
  },
  {
    cellName: "C0128",
    startX: 205644,
    startY: 51560,
    endX: 211824,
    endY: 60760,
  },
  {
    cellName: "C0129",
    startX: 211824,
    startY: 51560,
    endX: 218004,
    endY: 60760,
  },
  {
    cellName: "C0130",
    startX: 218004,
    startY: 51560,
    endX: 224184,
    endY: 60760,
  },
  {
    cellName: "C0131",
    startX: 224184,
    startY: 51560,
    endX: 230364,
    endY: 60760,
  },
  {
    cellName: "C0132",
    startX: 230364,
    startY: 51560,
    endX: 236544,
    endY: 60760,
  },
  { cellName: "C0201", startX: 38784, startY: 60760, endX: 44964, endY: 69960 },
  { cellName: "C0202", startX: 44964, startY: 60760, endX: 51144, endY: 69960 },
  { cellName: "C0203", startX: 51144, startY: 60760, endX: 57324, endY: 69960 },
  { cellName: "C0204", startX: 57324, startY: 60760, endX: 63504, endY: 69960 },
  { cellName: "C0205", startX: 63504, startY: 60760, endX: 69684, endY: 69960 },
  { cellName: "C0206", startX: 69684, startY: 60760, endX: 75864, endY: 69960 },
  { cellName: "C0207", startX: 75864, startY: 60760, endX: 82044, endY: 69960 },
  { cellName: "C0208", startX: 82044, startY: 60760, endX: 88224, endY: 69960 },
  { cellName: "C0209", startX: 88224, startY: 60760, endX: 94404, endY: 69960 },
  {
    cellName: "C0210",
    startX: 94404,
    startY: 60760,
    endX: 100584,
    endY: 69960,
  },
  {
    cellName: "C0211",
    startX: 100584,
    startY: 60760,
    endX: 106764,
    endY: 69960,
  },
  {
    cellName: "C0212",
    startX: 106764,
    startY: 60760,
    endX: 112944,
    endY: 69960,
  },
  {
    cellName: "C0213",
    startX: 112944,
    startY: 60760,
    endX: 119124,
    endY: 69960,
  },
  {
    cellName: "C0214",
    startX: 119124,
    startY: 60760,
    endX: 125304,
    endY: 69960,
  },
  {
    cellName: "C0215",
    startX: 125304,
    startY: 60760,
    endX: 131484,
    endY: 69960,
  },
  {
    cellName: "C0216",
    startX: 131484,
    startY: 60760,
    endX: 137664,
    endY: 69960,
  },
  {
    cellName: "C0217",
    startX: 137664,
    startY: 60760,
    endX: 143844,
    endY: 69960,
  },
  {
    cellName: "C0218",
    startX: 143844,
    startY: 60760,
    endX: 150024,
    endY: 69960,
  },
  {
    cellName: "C0219",
    startX: 150024,
    startY: 60760,
    endX: 156204,
    endY: 69960,
  },
  {
    cellName: "C0220",
    startX: 156204,
    startY: 60760,
    endX: 162384,
    endY: 69960,
  },
  {
    cellName: "C0221",
    startX: 162384,
    startY: 60760,
    endX: 168564,
    endY: 69960,
  },
  {
    cellName: "C0222",
    startX: 168564,
    startY: 60760,
    endX: 174744,
    endY: 69960,
  },
  {
    cellName: "C0223",
    startX: 174744,
    startY: 60760,
    endX: 180924,
    endY: 69960,
  },
  {
    cellName: "C0224",
    startX: 180924,
    startY: 60760,
    endX: 187104,
    endY: 69960,
  },
  {
    cellName: "C0225",
    startX: 187104,
    startY: 60760,
    endX: 193284,
    endY: 69960,
  },
  {
    cellName: "C0226",
    startX: 193284,
    startY: 60760,
    endX: 199464,
    endY: 69960,
  },
  {
    cellName: "C0227",
    startX: 199464,
    startY: 60760,
    endX: 205644,
    endY: 69960,
  },
  {
    cellName: "C0228",
    startX: 205644,
    startY: 60760,
    endX: 211824,
    endY: 69960,
  },
  {
    cellName: "C0229",
    startX: 211824,
    startY: 60760,
    endX: 218004,
    endY: 69960,
  },
  {
    cellName: "C0230",
    startX: 218004,
    startY: 60760,
    endX: 224184,
    endY: 69960,
  },
  {
    cellName: "C0231",
    startX: 224184,
    startY: 60760,
    endX: 230364,
    endY: 69960,
  },
  {
    cellName: "C0232",
    startX: 230364,
    startY: 60760,
    endX: 236544,
    endY: 69960,
  },
  { cellName: "C0301", startX: 38784, startY: 69960, endX: 44964, endY: 79160 },
  { cellName: "C0302", startX: 44964, startY: 69960, endX: 51144, endY: 79160 },
  { cellName: "C0303", startX: 51144, startY: 69960, endX: 57324, endY: 79160 },
  { cellName: "C0304", startX: 57324, startY: 69960, endX: 63504, endY: 79160 },
  { cellName: "C0305", startX: 63504, startY: 69960, endX: 69684, endY: 79160 },
  { cellName: "C0306", startX: 69684, startY: 69960, endX: 75864, endY: 79160 },
  { cellName: "C0307", startX: 75864, startY: 69960, endX: 82044, endY: 79160 },
  { cellName: "C0308", startX: 82044, startY: 69960, endX: 88224, endY: 79160 },
  { cellName: "C0309", startX: 88224, startY: 69960, endX: 94404, endY: 79160 },
  {
    cellName: "C0310",
    startX: 94404,
    startY: 69960,
    endX: 100584,
    endY: 79160,
  },
  {
    cellName: "C0311",
    startX: 100584,
    startY: 69960,
    endX: 106764,
    endY: 79160,
  },
  {
    cellName: "C0312",
    startX: 106764,
    startY: 69960,
    endX: 112944,
    endY: 79160,
  },
  {
    cellName: "C0313",
    startX: 112944,
    startY: 69960,
    endX: 119124,
    endY: 79160,
  },
  {
    cellName: "C0314",
    startX: 119124,
    startY: 69960,
    endX: 125304,
    endY: 79160,
  },
  {
    cellName: "C0315",
    startX: 125304,
    startY: 69960,
    endX: 131484,
    endY: 79160,
  },
  {
    cellName: "C0316",
    startX: 131484,
    startY: 69960,
    endX: 137664,
    endY: 79160,
  },
  {
    cellName: "C0317",
    startX: 137664,
    startY: 69960,
    endX: 143844,
    endY: 79160,
  },
  {
    cellName: "C0318",
    startX: 143844,
    startY: 69960,
    endX: 150024,
    endY: 79160,
  },
  {
    cellName: "C0319",
    startX: 150024,
    startY: 69960,
    endX: 156204,
    endY: 79160,
  },
  {
    cellName: "C0320",
    startX: 156204,
    startY: 69960,
    endX: 162384,
    endY: 79160,
  },
  {
    cellName: "C0321",
    startX: 162384,
    startY: 69960,
    endX: 168564,
    endY: 79160,
  },
  {
    cellName: "C0322",
    startX: 168564,
    startY: 69960,
    endX: 174744,
    endY: 79160,
  },
  {
    cellName: "C0323",
    startX: 174744,
    startY: 69960,
    endX: 180924,
    endY: 79160,
  },
  {
    cellName: "C0324",
    startX: 180924,
    startY: 69960,
    endX: 187104,
    endY: 79160,
  },
  {
    cellName: "C0325",
    startX: 187104,
    startY: 69960,
    endX: 193284,
    endY: 79160,
  },
  {
    cellName: "C0326",
    startX: 193284,
    startY: 69960,
    endX: 199464,
    endY: 79160,
  },
  {
    cellName: "C0327",
    startX: 199464,
    startY: 69960,
    endX: 205644,
    endY: 79160,
  },
  {
    cellName: "C0328",
    startX: 205644,
    startY: 69960,
    endX: 211824,
    endY: 79160,
  },
  {
    cellName: "C0329",
    startX: 211824,
    startY: 69960,
    endX: 218004,
    endY: 79160,
  },
  {
    cellName: "C0330",
    startX: 218004,
    startY: 69960,
    endX: 224184,
    endY: 79160,
  },
  {
    cellName: "C0331",
    startX: 224184,
    startY: 69960,
    endX: 230364,
    endY: 79160,
  },
  {
    cellName: "C0332",
    startX: 230364,
    startY: 69960,
    endX: 236544,
    endY: 79160,
  },
  { cellName: "C0401", startX: 38784, startY: 79160, endX: 44964, endY: 88360 },
  { cellName: "C0402", startX: 44964, startY: 79160, endX: 51144, endY: 88360 },
  { cellName: "C0403", startX: 51144, startY: 79160, endX: 57324, endY: 88360 },
  { cellName: "C0404", startX: 57324, startY: 79160, endX: 63504, endY: 88360 },
  { cellName: "C0405", startX: 63504, startY: 79160, endX: 69684, endY: 88360 },
  { cellName: "C0406", startX: 69684, startY: 79160, endX: 75864, endY: 88360 },
  { cellName: "C0407", startX: 75864, startY: 79160, endX: 82044, endY: 88360 },
  { cellName: "C0408", startX: 82044, startY: 79160, endX: 88224, endY: 88360 },
  { cellName: "C0409", startX: 88224, startY: 79160, endX: 94404, endY: 88360 },
  {
    cellName: "C0410",
    startX: 94404,
    startY: 79160,
    endX: 100584,
    endY: 88360,
  },
  {
    cellName: "C0411",
    startX: 100584,
    startY: 79160,
    endX: 106764,
    endY: 88360,
  },
  {
    cellName: "C0412",
    startX: 106764,
    startY: 79160,
    endX: 112944,
    endY: 88360,
  },
  {
    cellName: "C0413",
    startX: 112944,
    startY: 79160,
    endX: 119124,
    endY: 88360,
  },
  {
    cellName: "C0414",
    startX: 119124,
    startY: 79160,
    endX: 125304,
    endY: 88360,
  },
  {
    cellName: "C0415",
    startX: 125304,
    startY: 79160,
    endX: 131484,
    endY: 88360,
  },
  {
    cellName: "C0416",
    startX: 131484,
    startY: 79160,
    endX: 137664,
    endY: 88360,
  },
  {
    cellName: "C0417",
    startX: 137664,
    startY: 79160,
    endX: 143844,
    endY: 88360,
  },
  {
    cellName: "C0418",
    startX: 143844,
    startY: 79160,
    endX: 150024,
    endY: 88360,
  },
  {
    cellName: "C0419",
    startX: 150024,
    startY: 79160,
    endX: 156204,
    endY: 88360,
  },
  {
    cellName: "C0420",
    startX: 156204,
    startY: 79160,
    endX: 162384,
    endY: 88360,
  },
  {
    cellName: "C0421",
    startX: 162384,
    startY: 79160,
    endX: 168564,
    endY: 88360,
  },
  {
    cellName: "C0422",
    startX: 168564,
    startY: 79160,
    endX: 174744,
    endY: 88360,
  },
  {
    cellName: "C0423",
    startX: 174744,
    startY: 79160,
    endX: 180924,
    endY: 88360,
  },
  {
    cellName: "C0424",
    startX: 180924,
    startY: 79160,
    endX: 187104,
    endY: 88360,
  },
  {
    cellName: "C0425",
    startX: 187104,
    startY: 79160,
    endX: 193284,
    endY: 88360,
  },
  {
    cellName: "C0426",
    startX: 193284,
    startY: 79160,
    endX: 199464,
    endY: 88360,
  },
  {
    cellName: "C0427",
    startX: 199464,
    startY: 79160,
    endX: 205644,
    endY: 88360,
  },
  {
    cellName: "C0428",
    startX: 205644,
    startY: 79160,
    endX: 211824,
    endY: 88360,
  },
  {
    cellName: "C0429",
    startX: 211824,
    startY: 79160,
    endX: 218004,
    endY: 88360,
  },
  {
    cellName: "C0430",
    startX: 218004,
    startY: 79160,
    endX: 224184,
    endY: 88360,
  },
  {
    cellName: "C0431",
    startX: 224184,
    startY: 79160,
    endX: 230364,
    endY: 88360,
  },
  {
    cellName: "C0432",
    startX: 230364,
    startY: 79160,
    endX: 236544,
    endY: 88360,
  },
  { cellName: "C0501", startX: 38784, startY: 88360, endX: 44964, endY: 97560 },
  { cellName: "C0502", startX: 44964, startY: 88360, endX: 51144, endY: 97560 },
  { cellName: "C0503", startX: 51144, startY: 88360, endX: 57324, endY: 97560 },
  { cellName: "C0504", startX: 57324, startY: 88360, endX: 63504, endY: 97560 },
  { cellName: "C0505", startX: 63504, startY: 88360, endX: 69684, endY: 97560 },
  { cellName: "C0506", startX: 69684, startY: 88360, endX: 75864, endY: 97560 },
  { cellName: "C0507", startX: 75864, startY: 88360, endX: 82044, endY: 97560 },
  { cellName: "C0508", startX: 82044, startY: 88360, endX: 88224, endY: 97560 },
  { cellName: "C0509", startX: 88224, startY: 88360, endX: 94404, endY: 97560 },
  {
    cellName: "C0510",
    startX: 94404,
    startY: 88360,
    endX: 100584,
    endY: 97560,
  },
  {
    cellName: "C0511",
    startX: 100584,
    startY: 88360,
    endX: 106764,
    endY: 97560,
  },
  {
    cellName: "C0512",
    startX: 106764,
    startY: 88360,
    endX: 112944,
    endY: 97560,
  },
  {
    cellName: "C0513",
    startX: 112944,
    startY: 88360,
    endX: 119124,
    endY: 97560,
  },
  {
    cellName: "C0514",
    startX: 119124,
    startY: 88360,
    endX: 125304,
    endY: 97560,
  },
  {
    cellName: "C0515",
    startX: 125304,
    startY: 88360,
    endX: 131484,
    endY: 97560,
  },
  {
    cellName: "C0516",
    startX: 131484,
    startY: 88360,
    endX: 137664,
    endY: 97560,
  },
  {
    cellName: "C0517",
    startX: 137664,
    startY: 88360,
    endX: 143844,
    endY: 97560,
  },
  {
    cellName: "C0518",
    startX: 143844,
    startY: 88360,
    endX: 150024,
    endY: 97560,
  },
  {
    cellName: "C0519",
    startX: 150024,
    startY: 88360,
    endX: 156204,
    endY: 97560,
  },
  {
    cellName: "C0520",
    startX: 156204,
    startY: 88360,
    endX: 162384,
    endY: 97560,
  },
  {
    cellName: "C0521",
    startX: 162384,
    startY: 88360,
    endX: 168564,
    endY: 97560,
  },
  {
    cellName: "C0522",
    startX: 168564,
    startY: 88360,
    endX: 174744,
    endY: 97560,
  },
  {
    cellName: "C0523",
    startX: 174744,
    startY: 88360,
    endX: 180924,
    endY: 97560,
  },
  {
    cellName: "C0524",
    startX: 180924,
    startY: 88360,
    endX: 187104,
    endY: 97560,
  },
  {
    cellName: "C0525",
    startX: 187104,
    startY: 88360,
    endX: 193284,
    endY: 97560,
  },
  {
    cellName: "C0526",
    startX: 193284,
    startY: 88360,
    endX: 199464,
    endY: 97560,
  },
  {
    cellName: "C0527",
    startX: 199464,
    startY: 88360,
    endX: 205644,
    endY: 97560,
  },
  {
    cellName: "C0528",
    startX: 205644,
    startY: 88360,
    endX: 211824,
    endY: 97560,
  },
  {
    cellName: "C0529",
    startX: 211824,
    startY: 88360,
    endX: 218004,
    endY: 97560,
  },
  {
    cellName: "C0530",
    startX: 218004,
    startY: 88360,
    endX: 224184,
    endY: 97560,
  },
  {
    cellName: "C0531",
    startX: 224184,
    startY: 88360,
    endX: 230364,
    endY: 97560,
  },
  {
    cellName: "C0532",
    startX: 230364,
    startY: 88360,
    endX: 236544,
    endY: 97560,
  },
  {
    cellName: "C0601",
    startX: 38784,
    startY: 97560,
    endX: 44964,
    endY: 106760,
  },
  {
    cellName: "C0602",
    startX: 44964,
    startY: 97560,
    endX: 51144,
    endY: 106760,
  },
  {
    cellName: "C0603",
    startX: 51144,
    startY: 97560,
    endX: 57324,
    endY: 106760,
  },
  {
    cellName: "C0604",
    startX: 57324,
    startY: 97560,
    endX: 63504,
    endY: 106760,
  },
  {
    cellName: "C0605",
    startX: 63504,
    startY: 97560,
    endX: 69684,
    endY: 106760,
  },
  {
    cellName: "C0606",
    startX: 69684,
    startY: 97560,
    endX: 75864,
    endY: 106760,
  },
  {
    cellName: "C0607",
    startX: 75864,
    startY: 97560,
    endX: 82044,
    endY: 106760,
  },
  {
    cellName: "C0608",
    startX: 82044,
    startY: 97560,
    endX: 88224,
    endY: 106760,
  },
  {
    cellName: "C0609",
    startX: 88224,
    startY: 97560,
    endX: 94404,
    endY: 106760,
  },
  {
    cellName: "C0610",
    startX: 94404,
    startY: 97560,
    endX: 100584,
    endY: 106760,
  },
  {
    cellName: "C0611",
    startX: 100584,
    startY: 97560,
    endX: 106764,
    endY: 106760,
  },
  {
    cellName: "C0612",
    startX: 106764,
    startY: 97560,
    endX: 112944,
    endY: 106760,
  },
  {
    cellName: "C0613",
    startX: 112944,
    startY: 97560,
    endX: 119124,
    endY: 106760,
  },
  {
    cellName: "C0614",
    startX: 119124,
    startY: 97560,
    endX: 125304,
    endY: 106760,
  },
  {
    cellName: "C0615",
    startX: 125304,
    startY: 97560,
    endX: 131484,
    endY: 106760,
  },
  {
    cellName: "C0616",
    startX: 131484,
    startY: 97560,
    endX: 137664,
    endY: 106760,
  },
  {
    cellName: "C0617",
    startX: 137664,
    startY: 97560,
    endX: 143844,
    endY: 106760,
  },
  {
    cellName: "C0618",
    startX: 143844,
    startY: 97560,
    endX: 150024,
    endY: 106760,
  },
  {
    cellName: "C0619",
    startX: 150024,
    startY: 97560,
    endX: 156204,
    endY: 106760,
  },
  {
    cellName: "C0620",
    startX: 156204,
    startY: 97560,
    endX: 162384,
    endY: 106760,
  },
  {
    cellName: "C0621",
    startX: 162384,
    startY: 97560,
    endX: 168564,
    endY: 106760,
  },
  {
    cellName: "C0622",
    startX: 168564,
    startY: 97560,
    endX: 174744,
    endY: 106760,
  },
  {
    cellName: "C0623",
    startX: 174744,
    startY: 97560,
    endX: 180924,
    endY: 106760,
  },
  {
    cellName: "C0624",
    startX: 180924,
    startY: 97560,
    endX: 187104,
    endY: 106760,
  },
  {
    cellName: "C0625",
    startX: 187104,
    startY: 97560,
    endX: 193284,
    endY: 106760,
  },
  {
    cellName: "C0626",
    startX: 193284,
    startY: 97560,
    endX: 199464,
    endY: 106760,
  },
  {
    cellName: "C0627",
    startX: 199464,
    startY: 97560,
    endX: 205644,
    endY: 106760,
  },
  {
    cellName: "C0628",
    startX: 205644,
    startY: 97560,
    endX: 211824,
    endY: 106760,
  },
  {
    cellName: "C0629",
    startX: 211824,
    startY: 97560,
    endX: 218004,
    endY: 106760,
  },
  {
    cellName: "C0630",
    startX: 218004,
    startY: 97560,
    endX: 224184,
    endY: 106760,
  },
  {
    cellName: "C0631",
    startX: 224184,
    startY: 97560,
    endX: 230364,
    endY: 106760,
  },
  {
    cellName: "C0632",
    startX: 230364,
    startY: 97560,
    endX: 236544,
    endY: 106760,
  },
  {
    cellName: "C0701",
    startX: 38784,
    startY: 106760,
    endX: 44964,
    endY: 115960,
  },
  {
    cellName: "C0702",
    startX: 44964,
    startY: 106760,
    endX: 51144,
    endY: 115960,
  },
  {
    cellName: "C0703",
    startX: 51144,
    startY: 106760,
    endX: 57324,
    endY: 115960,
  },
  {
    cellName: "C0704",
    startX: 57324,
    startY: 106760,
    endX: 63504,
    endY: 115960,
  },
  {
    cellName: "C0705",
    startX: 63504,
    startY: 106760,
    endX: 69684,
    endY: 115960,
  },
  {
    cellName: "C0706",
    startX: 69684,
    startY: 106760,
    endX: 75864,
    endY: 115960,
  },
  {
    cellName: "C0707",
    startX: 75864,
    startY: 106760,
    endX: 82044,
    endY: 115960,
  },
  {
    cellName: "C0708",
    startX: 82044,
    startY: 106760,
    endX: 88224,
    endY: 115960,
  },
  {
    cellName: "C0709",
    startX: 88224,
    startY: 106760,
    endX: 94404,
    endY: 115960,
  },
  {
    cellName: "C0710",
    startX: 94404,
    startY: 106760,
    endX: 100584,
    endY: 115960,
  },
  {
    cellName: "C0711",
    startX: 100584,
    startY: 106760,
    endX: 106764,
    endY: 115960,
  },
  {
    cellName: "C0712",
    startX: 106764,
    startY: 106760,
    endX: 112944,
    endY: 115960,
  },
  {
    cellName: "C0713",
    startX: 112944,
    startY: 106760,
    endX: 119124,
    endY: 115960,
  },
  {
    cellName: "C0714",
    startX: 119124,
    startY: 106760,
    endX: 125304,
    endY: 115960,
  },
  {
    cellName: "C0715",
    startX: 125304,
    startY: 106760,
    endX: 131484,
    endY: 115960,
  },
  {
    cellName: "C0716",
    startX: 131484,
    startY: 106760,
    endX: 137664,
    endY: 115960,
  },
  {
    cellName: "C0717",
    startX: 137664,
    startY: 106760,
    endX: 143844,
    endY: 115960,
  },
  {
    cellName: "C0718",
    startX: 143844,
    startY: 106760,
    endX: 150024,
    endY: 115960,
  },
  {
    cellName: "C0719",
    startX: 150024,
    startY: 106760,
    endX: 156204,
    endY: 115960,
  },
  {
    cellName: "C0720",
    startX: 156204,
    startY: 106760,
    endX: 162384,
    endY: 115960,
  },
  {
    cellName: "C0721",
    startX: 162384,
    startY: 106760,
    endX: 168564,
    endY: 115960,
  },
  {
    cellName: "C0722",
    startX: 168564,
    startY: 106760,
    endX: 174744,
    endY: 115960,
  },
  {
    cellName: "C0723",
    startX: 174744,
    startY: 106760,
    endX: 180924,
    endY: 115960,
  },
  {
    cellName: "C0724",
    startX: 180924,
    startY: 106760,
    endX: 187104,
    endY: 115960,
  },
  {
    cellName: "C0725",
    startX: 187104,
    startY: 106760,
    endX: 193284,
    endY: 115960,
  },
  {
    cellName: "C0726",
    startX: 193284,
    startY: 106760,
    endX: 199464,
    endY: 115960,
  },
  {
    cellName: "C0727",
    startX: 199464,
    startY: 106760,
    endX: 205644,
    endY: 115960,
  },
  {
    cellName: "C0728",
    startX: 205644,
    startY: 106760,
    endX: 211824,
    endY: 115960,
  },
  {
    cellName: "C0729",
    startX: 211824,
    startY: 106760,
    endX: 218004,
    endY: 115960,
  },
  {
    cellName: "C0730",
    startX: 218004,
    startY: 106760,
    endX: 224184,
    endY: 115960,
  },
  {
    cellName: "C0731",
    startX: 224184,
    startY: 106760,
    endX: 230364,
    endY: 115960,
  },
  {
    cellName: "C0732",
    startX: 230364,
    startY: 106760,
    endX: 236544,
    endY: 115960,
  },
  {
    cellName: "C0801",
    startX: 38784,
    startY: 115960,
    endX: 44964,
    endY: 125160,
  },
  {
    cellName: "C0802",
    startX: 44964,
    startY: 115960,
    endX: 51144,
    endY: 125160,
  },
  {
    cellName: "C0803",
    startX: 51144,
    startY: 115960,
    endX: 57324,
    endY: 125160,
  },
  {
    cellName: "C0804",
    startX: 57324,
    startY: 115960,
    endX: 63504,
    endY: 125160,
  },
  {
    cellName: "C0805",
    startX: 63504,
    startY: 115960,
    endX: 69684,
    endY: 125160,
  },
  {
    cellName: "C0806",
    startX: 69684,
    startY: 115960,
    endX: 75864,
    endY: 125160,
  },
  {
    cellName: "C0807",
    startX: 75864,
    startY: 115960,
    endX: 82044,
    endY: 125160,
  },
  {
    cellName: "C0808",
    startX: 82044,
    startY: 115960,
    endX: 88224,
    endY: 125160,
  },
  {
    cellName: "C0809",
    startX: 88224,
    startY: 115960,
    endX: 94404,
    endY: 125160,
  },
  {
    cellName: "C0810",
    startX: 94404,
    startY: 115960,
    endX: 100584,
    endY: 125160,
  },
  {
    cellName: "C0811",
    startX: 100584,
    startY: 115960,
    endX: 106764,
    endY: 125160,
  },
  {
    cellName: "C0812",
    startX: 106764,
    startY: 115960,
    endX: 112944,
    endY: 125160,
  },
  {
    cellName: "C0813",
    startX: 112944,
    startY: 115960,
    endX: 119124,
    endY: 125160,
  },
  {
    cellName: "C0814",
    startX: 119124,
    startY: 115960,
    endX: 125304,
    endY: 125160,
  },
  {
    cellName: "C0815",
    startX: 125304,
    startY: 115960,
    endX: 131484,
    endY: 125160,
  },
  {
    cellName: "C0816",
    startX: 131484,
    startY: 115960,
    endX: 137664,
    endY: 125160,
  },
  {
    cellName: "C0817",
    startX: 137664,
    startY: 115960,
    endX: 143844,
    endY: 125160,
  },
  {
    cellName: "C0818",
    startX: 143844,
    startY: 115960,
    endX: 150024,
    endY: 125160,
  },
  {
    cellName: "C0819",
    startX: 150024,
    startY: 115960,
    endX: 156204,
    endY: 125160,
  },
  {
    cellName: "C0820",
    startX: 156204,
    startY: 115960,
    endX: 162384,
    endY: 125160,
  },
  {
    cellName: "C0821",
    startX: 162384,
    startY: 115960,
    endX: 168564,
    endY: 125160,
  },
  {
    cellName: "C0822",
    startX: 168564,
    startY: 115960,
    endX: 174744,
    endY: 125160,
  },
  {
    cellName: "C0823",
    startX: 174744,
    startY: 115960,
    endX: 180924,
    endY: 125160,
  },
  {
    cellName: "C0824",
    startX: 180924,
    startY: 115960,
    endX: 187104,
    endY: 125160,
  },
  {
    cellName: "C0825",
    startX: 187104,
    startY: 115960,
    endX: 193284,
    endY: 125160,
  },
  {
    cellName: "C0826",
    startX: 193284,
    startY: 115960,
    endX: 199464,
    endY: 125160,
  },
  {
    cellName: "C0827",
    startX: 199464,
    startY: 115960,
    endX: 205644,
    endY: 125160,
  },
  {
    cellName: "C0828",
    startX: 205644,
    startY: 115960,
    endX: 211824,
    endY: 125160,
  },
  {
    cellName: "C0829",
    startX: 211824,
    startY: 115960,
    endX: 218004,
    endY: 125160,
  },
  {
    cellName: "C0830",
    startX: 218004,
    startY: 115960,
    endX: 224184,
    endY: 125160,
  },
  {
    cellName: "C0831",
    startX: 224184,
    startY: 115960,
    endX: 230364,
    endY: 125160,
  },
  {
    cellName: "C0832",
    startX: 230364,
    startY: 115960,
    endX: 236544,
    endY: 125160,
  },
  {
    cellName: "C0901",
    startX: 38784,
    startY: 125160,
    endX: 44964,
    endY: 134360,
  },
  {
    cellName: "C0902",
    startX: 44964,
    startY: 125160,
    endX: 51144,
    endY: 134360,
  },
  {
    cellName: "C0903",
    startX: 51144,
    startY: 125160,
    endX: 57324,
    endY: 134360,
  },
  {
    cellName: "C0904",
    startX: 57324,
    startY: 125160,
    endX: 63504,
    endY: 134360,
  },
  {
    cellName: "C0905",
    startX: 63504,
    startY: 125160,
    endX: 69684,
    endY: 134360,
  },
  {
    cellName: "C0906",
    startX: 69684,
    startY: 125160,
    endX: 75864,
    endY: 134360,
  },
  {
    cellName: "C0907",
    startX: 75864,
    startY: 125160,
    endX: 82044,
    endY: 134360,
  },
  {
    cellName: "C0908",
    startX: 82044,
    startY: 125160,
    endX: 88224,
    endY: 134360,
  },
  {
    cellName: "C0909",
    startX: 88224,
    startY: 125160,
    endX: 94404,
    endY: 134360,
  },
  {
    cellName: "C0910",
    startX: 94404,
    startY: 125160,
    endX: 100584,
    endY: 134360,
  },
  {
    cellName: "C0911",
    startX: 100584,
    startY: 125160,
    endX: 106764,
    endY: 134360,
  },
  {
    cellName: "C0912",
    startX: 106764,
    startY: 125160,
    endX: 112944,
    endY: 134360,
  },
  {
    cellName: "C0913",
    startX: 112944,
    startY: 125160,
    endX: 119124,
    endY: 134360,
  },
  {
    cellName: "C0914",
    startX: 119124,
    startY: 125160,
    endX: 125304,
    endY: 134360,
  },
  {
    cellName: "C0915",
    startX: 125304,
    startY: 125160,
    endX: 131484,
    endY: 134360,
  },
  {
    cellName: "C0916",
    startX: 131484,
    startY: 125160,
    endX: 137664,
    endY: 134360,
  },
  {
    cellName: "C0917",
    startX: 137664,
    startY: 125160,
    endX: 143844,
    endY: 134360,
  },
  {
    cellName: "C0918",
    startX: 143844,
    startY: 125160,
    endX: 150024,
    endY: 134360,
  },
  {
    cellName: "C0919",
    startX: 150024,
    startY: 125160,
    endX: 156204,
    endY: 134360,
  },
  {
    cellName: "C0920",
    startX: 156204,
    startY: 125160,
    endX: 162384,
    endY: 134360,
  },
  {
    cellName: "C0921",
    startX: 162384,
    startY: 125160,
    endX: 168564,
    endY: 134360,
  },
  {
    cellName: "C0922",
    startX: 168564,
    startY: 125160,
    endX: 174744,
    endY: 134360,
  },
  {
    cellName: "C0923",
    startX: 174744,
    startY: 125160,
    endX: 180924,
    endY: 134360,
  },
  {
    cellName: "C0924",
    startX: 180924,
    startY: 125160,
    endX: 187104,
    endY: 134360,
  },
  {
    cellName: "C0925",
    startX: 187104,
    startY: 125160,
    endX: 193284,
    endY: 134360,
  },
  {
    cellName: "C0926",
    startX: 193284,
    startY: 125160,
    endX: 199464,
    endY: 134360,
  },
  {
    cellName: "C0927",
    startX: 199464,
    startY: 125160,
    endX: 205644,
    endY: 134360,
  },
  {
    cellName: "C0928",
    startX: 205644,
    startY: 125160,
    endX: 211824,
    endY: 134360,
  },
  {
    cellName: "C0929",
    startX: 211824,
    startY: 125160,
    endX: 218004,
    endY: 134360,
  },
  {
    cellName: "C0930",
    startX: 218004,
    startY: 125160,
    endX: 224184,
    endY: 134360,
  },
  {
    cellName: "C0931",
    startX: 224184,
    startY: 125160,
    endX: 230364,
    endY: 134360,
  },
  {
    cellName: "C0932",
    startX: 230364,
    startY: 125160,
    endX: 236544,
    endY: 134360,
  },
  {
    cellName: "C1001",
    startX: 38784,
    startY: 134360,
    endX: 44964,
    endY: 143560,
  },
  {
    cellName: "C1002",
    startX: 44964,
    startY: 134360,
    endX: 51144,
    endY: 143560,
  },
  {
    cellName: "C1003",
    startX: 51144,
    startY: 134360,
    endX: 57324,
    endY: 143560,
  },
  {
    cellName: "C1004",
    startX: 57324,
    startY: 134360,
    endX: 63504,
    endY: 143560,
  },
  {
    cellName: "C1005",
    startX: 63504,
    startY: 134360,
    endX: 69684,
    endY: 143560,
  },
  {
    cellName: "C1006",
    startX: 69684,
    startY: 134360,
    endX: 75864,
    endY: 143560,
  },
  {
    cellName: "C1007",
    startX: 75864,
    startY: 134360,
    endX: 82044,
    endY: 143560,
  },
  {
    cellName: "C1008",
    startX: 82044,
    startY: 134360,
    endX: 88224,
    endY: 143560,
  },
  {
    cellName: "C1009",
    startX: 88224,
    startY: 134360,
    endX: 94404,
    endY: 143560,
  },
  {
    cellName: "C1010",
    startX: 94404,
    startY: 134360,
    endX: 100584,
    endY: 143560,
  },
  {
    cellName: "C1011",
    startX: 100584,
    startY: 134360,
    endX: 106764,
    endY: 143560,
  },
  {
    cellName: "C1012",
    startX: 106764,
    startY: 134360,
    endX: 112944,
    endY: 143560,
  },
  {
    cellName: "C1013",
    startX: 112944,
    startY: 134360,
    endX: 119124,
    endY: 143560,
  },
  {
    cellName: "C1014",
    startX: 119124,
    startY: 134360,
    endX: 125304,
    endY: 143560,
  },
  {
    cellName: "C1015",
    startX: 125304,
    startY: 134360,
    endX: 131484,
    endY: 143560,
  },
  {
    cellName: "C1016",
    startX: 131484,
    startY: 134360,
    endX: 137664,
    endY: 143560,
  },
  {
    cellName: "C1017",
    startX: 137664,
    startY: 134360,
    endX: 143844,
    endY: 143560,
  },
  {
    cellName: "C1018",
    startX: 143844,
    startY: 134360,
    endX: 150024,
    endY: 143560,
  },
  {
    cellName: "C1019",
    startX: 150024,
    startY: 134360,
    endX: 156204,
    endY: 143560,
  },
  {
    cellName: "C1020",
    startX: 156204,
    startY: 134360,
    endX: 162384,
    endY: 143560,
  },
  {
    cellName: "C1021",
    startX: 162384,
    startY: 134360,
    endX: 168564,
    endY: 143560,
  },
  {
    cellName: "C1022",
    startX: 168564,
    startY: 134360,
    endX: 174744,
    endY: 143560,
  },
  {
    cellName: "C1023",
    startX: 174744,
    startY: 134360,
    endX: 180924,
    endY: 143560,
  },
  {
    cellName: "C1024",
    startX: 180924,
    startY: 134360,
    endX: 187104,
    endY: 143560,
  },
  {
    cellName: "C1025",
    startX: 187104,
    startY: 134360,
    endX: 193284,
    endY: 143560,
  },
  {
    cellName: "C1026",
    startX: 193284,
    startY: 134360,
    endX: 199464,
    endY: 143560,
  },
  {
    cellName: "C1027",
    startX: 199464,
    startY: 134360,
    endX: 205644,
    endY: 143560,
  },
  {
    cellName: "C1028",
    startX: 205644,
    startY: 134360,
    endX: 211824,
    endY: 143560,
  },
  {
    cellName: "C1029",
    startX: 211824,
    startY: 134360,
    endX: 218004,
    endY: 143560,
  },
  {
    cellName: "C1030",
    startX: 218004,
    startY: 134360,
    endX: 224184,
    endY: 143560,
  },
  {
    cellName: "C1031",
    startX: 224184,
    startY: 134360,
    endX: 230364,
    endY: 143560,
  },
  {
    cellName: "C1032",
    startX: 230364,
    startY: 134360,
    endX: 236544,
    endY: 143560,
  },
  {
    cellName: "C1101",
    startX: 38784,
    startY: 143560,
    endX: 44964,
    endY: 152760,
  },
  {
    cellName: "C1102",
    startX: 44964,
    startY: 143560,
    endX: 51144,
    endY: 152760,
  },
  {
    cellName: "C1103",
    startX: 51144,
    startY: 143560,
    endX: 57324,
    endY: 152760,
  },
  {
    cellName: "C1104",
    startX: 57324,
    startY: 143560,
    endX: 63504,
    endY: 152760,
  },
  {
    cellName: "C1105",
    startX: 63504,
    startY: 143560,
    endX: 69684,
    endY: 152760,
  },
  {
    cellName: "C1106",
    startX: 69684,
    startY: 143560,
    endX: 75864,
    endY: 152760,
  },
  {
    cellName: "C1107",
    startX: 75864,
    startY: 143560,
    endX: 82044,
    endY: 152760,
  },
  {
    cellName: "C1108",
    startX: 82044,
    startY: 143560,
    endX: 88224,
    endY: 152760,
  },
  {
    cellName: "C1109",
    startX: 88224,
    startY: 143560,
    endX: 94404,
    endY: 152760,
  },
  {
    cellName: "C1110",
    startX: 94404,
    startY: 143560,
    endX: 100584,
    endY: 152760,
  },
  {
    cellName: "C1111",
    startX: 100584,
    startY: 143560,
    endX: 106764,
    endY: 152760,
  },
  {
    cellName: "C1112",
    startX: 106764,
    startY: 143560,
    endX: 112944,
    endY: 152760,
  },
  {
    cellName: "C1113",
    startX: 112944,
    startY: 143560,
    endX: 119124,
    endY: 152760,
  },
  {
    cellName: "C1114",
    startX: 119124,
    startY: 143560,
    endX: 125304,
    endY: 152760,
  },
  {
    cellName: "C1115",
    startX: 125304,
    startY: 143560,
    endX: 131484,
    endY: 152760,
  },
  {
    cellName: "C1116",
    startX: 131484,
    startY: 143560,
    endX: 137664,
    endY: 152760,
  },
  {
    cellName: "C1117",
    startX: 137664,
    startY: 143560,
    endX: 143844,
    endY: 152760,
  },
  {
    cellName: "C1118",
    startX: 143844,
    startY: 143560,
    endX: 150024,
    endY: 152760,
  },
  {
    cellName: "C1119",
    startX: 150024,
    startY: 143560,
    endX: 156204,
    endY: 152760,
  },
  {
    cellName: "C1120",
    startX: 156204,
    startY: 143560,
    endX: 162384,
    endY: 152760,
  },
  {
    cellName: "C1121",
    startX: 162384,
    startY: 143560,
    endX: 168564,
    endY: 152760,
  },
  {
    cellName: "C1122",
    startX: 168564,
    startY: 143560,
    endX: 174744,
    endY: 152760,
  },
  {
    cellName: "C1123",
    startX: 174744,
    startY: 143560,
    endX: 180924,
    endY: 152760,
  },
  {
    cellName: "C1124",
    startX: 180924,
    startY: 143560,
    endX: 187104,
    endY: 152760,
  },
  {
    cellName: "C1125",
    startX: 187104,
    startY: 143560,
    endX: 193284,
    endY: 152760,
  },
  {
    cellName: "C1126",
    startX: 193284,
    startY: 143560,
    endX: 199464,
    endY: 152760,
  },
  {
    cellName: "C1127",
    startX: 199464,
    startY: 143560,
    endX: 205644,
    endY: 152760,
  },
  {
    cellName: "C1128",
    startX: 205644,
    startY: 143560,
    endX: 211824,
    endY: 152760,
  },
  {
    cellName: "C1129",
    startX: 211824,
    startY: 143560,
    endX: 218004,
    endY: 152760,
  },
  {
    cellName: "C1130",
    startX: 218004,
    startY: 143560,
    endX: 224184,
    endY: 152760,
  },
  {
    cellName: "C1131",
    startX: 224184,
    startY: 143560,
    endX: 230364,
    endY: 152760,
  },
  {
    cellName: "C1132",
    startX: 230364,
    startY: 143560,
    endX: 236544,
    endY: 152760,
  },
  {
    cellName: "C1201",
    startX: 38784,
    startY: 152760,
    endX: 44964,
    endY: 161960,
  },
  {
    cellName: "C1202",
    startX: 44964,
    startY: 152760,
    endX: 51144,
    endY: 161960,
  },
  {
    cellName: "C1203",
    startX: 51144,
    startY: 152760,
    endX: 57324,
    endY: 161960,
  },
  {
    cellName: "C1204",
    startX: 57324,
    startY: 152760,
    endX: 63504,
    endY: 161960,
  },
  {
    cellName: "C1205",
    startX: 63504,
    startY: 152760,
    endX: 69684,
    endY: 161960,
  },
  {
    cellName: "C1206",
    startX: 69684,
    startY: 152760,
    endX: 75864,
    endY: 161960,
  },
  {
    cellName: "C1207",
    startX: 75864,
    startY: 152760,
    endX: 82044,
    endY: 161960,
  },
  {
    cellName: "C1208",
    startX: 82044,
    startY: 152760,
    endX: 88224,
    endY: 161960,
  },
  {
    cellName: "C1209",
    startX: 88224,
    startY: 152760,
    endX: 94404,
    endY: 161960,
  },
  {
    cellName: "C1210",
    startX: 94404,
    startY: 152760,
    endX: 100584,
    endY: 161960,
  },
  {
    cellName: "C1211",
    startX: 100584,
    startY: 152760,
    endX: 106764,
    endY: 161960,
  },
  {
    cellName: "C1212",
    startX: 106764,
    startY: 152760,
    endX: 112944,
    endY: 161960,
  },
  {
    cellName: "C1213",
    startX: 112944,
    startY: 152760,
    endX: 119124,
    endY: 161960,
  },
  {
    cellName: "C1214",
    startX: 119124,
    startY: 152760,
    endX: 125304,
    endY: 161960,
  },
  {
    cellName: "C1215",
    startX: 125304,
    startY: 152760,
    endX: 131484,
    endY: 161960,
  },
  {
    cellName: "C1216",
    startX: 131484,
    startY: 152760,
    endX: 137664,
    endY: 161960,
  },
  {
    cellName: "C1217",
    startX: 137664,
    startY: 152760,
    endX: 143844,
    endY: 161960,
  },
  {
    cellName: "C1218",
    startX: 143844,
    startY: 152760,
    endX: 150024,
    endY: 161960,
  },
  {
    cellName: "C1219",
    startX: 150024,
    startY: 152760,
    endX: 156204,
    endY: 161960,
  },
  {
    cellName: "C1220",
    startX: 156204,
    startY: 152760,
    endX: 162384,
    endY: 161960,
  },
  {
    cellName: "C1221",
    startX: 162384,
    startY: 152760,
    endX: 168564,
    endY: 161960,
  },
  {
    cellName: "C1222",
    startX: 168564,
    startY: 152760,
    endX: 174744,
    endY: 161960,
  },
  {
    cellName: "C1223",
    startX: 174744,
    startY: 152760,
    endX: 180924,
    endY: 161960,
  },
  {
    cellName: "C1224",
    startX: 180924,
    startY: 152760,
    endX: 187104,
    endY: 161960,
  },
  {
    cellName: "C1225",
    startX: 187104,
    startY: 152760,
    endX: 193284,
    endY: 161960,
  },
  {
    cellName: "C1226",
    startX: 193284,
    startY: 152760,
    endX: 199464,
    endY: 161960,
  },
  {
    cellName: "C1227",
    startX: 199464,
    startY: 152760,
    endX: 205644,
    endY: 161960,
  },
  {
    cellName: "C1228",
    startX: 205644,
    startY: 152760,
    endX: 211824,
    endY: 161960,
  },
  {
    cellName: "C1229",
    startX: 211824,
    startY: 152760,
    endX: 218004,
    endY: 161960,
  },
  {
    cellName: "C1230",
    startX: 218004,
    startY: 152760,
    endX: 224184,
    endY: 161960,
  },
  {
    cellName: "C1231",
    startX: 224184,
    startY: 152760,
    endX: 230364,
    endY: 161960,
  },
  {
    cellName: "C1232",
    startX: 230364,
    startY: 152760,
    endX: 236544,
    endY: 161960,
  },
  {
    cellName: "C1301",
    startX: 38784,
    startY: 161960,
    endX: 44964,
    endY: 171160,
  },
  {
    cellName: "C1302",
    startX: 44964,
    startY: 161960,
    endX: 51144,
    endY: 171160,
  },
  {
    cellName: "C1303",
    startX: 51144,
    startY: 161960,
    endX: 57324,
    endY: 171160,
  },
  {
    cellName: "C1304",
    startX: 57324,
    startY: 161960,
    endX: 63504,
    endY: 171160,
  },
  {
    cellName: "C1305",
    startX: 63504,
    startY: 161960,
    endX: 69684,
    endY: 171160,
  },
  {
    cellName: "C1306",
    startX: 69684,
    startY: 161960,
    endX: 75864,
    endY: 171160,
  },
  {
    cellName: "C1307",
    startX: 75864,
    startY: 161960,
    endX: 82044,
    endY: 171160,
  },
  {
    cellName: "C1308",
    startX: 82044,
    startY: 161960,
    endX: 88224,
    endY: 171160,
  },
  {
    cellName: "C1309",
    startX: 88224,
    startY: 161960,
    endX: 94404,
    endY: 171160,
  },
  {
    cellName: "C1310",
    startX: 94404,
    startY: 161960,
    endX: 100584,
    endY: 171160,
  },
  {
    cellName: "C1311",
    startX: 100584,
    startY: 161960,
    endX: 106764,
    endY: 171160,
  },
  {
    cellName: "C1312",
    startX: 106764,
    startY: 161960,
    endX: 112944,
    endY: 171160,
  },
  {
    cellName: "C1313",
    startX: 112944,
    startY: 161960,
    endX: 119124,
    endY: 171160,
  },
  {
    cellName: "C1314",
    startX: 119124,
    startY: 161960,
    endX: 125304,
    endY: 171160,
  },
  {
    cellName: "C1315",
    startX: 125304,
    startY: 161960,
    endX: 131484,
    endY: 171160,
  },
  {
    cellName: "C1316",
    startX: 131484,
    startY: 161960,
    endX: 137664,
    endY: 171160,
  },
  {
    cellName: "C1317",
    startX: 137664,
    startY: 161960,
    endX: 143844,
    endY: 171160,
  },
  {
    cellName: "C1318",
    startX: 143844,
    startY: 161960,
    endX: 150024,
    endY: 171160,
  },
  {
    cellName: "C1319",
    startX: 150024,
    startY: 161960,
    endX: 156204,
    endY: 171160,
  },
  {
    cellName: "C1320",
    startX: 156204,
    startY: 161960,
    endX: 162384,
    endY: 171160,
  },
  {
    cellName: "C1321",
    startX: 162384,
    startY: 161960,
    endX: 168564,
    endY: 171160,
  },
  {
    cellName: "C1322",
    startX: 168564,
    startY: 161960,
    endX: 174744,
    endY: 171160,
  },
  {
    cellName: "C1323",
    startX: 174744,
    startY: 161960,
    endX: 180924,
    endY: 171160,
  },
  {
    cellName: "C1324",
    startX: 180924,
    startY: 161960,
    endX: 187104,
    endY: 171160,
  },
  {
    cellName: "C1325",
    startX: 187104,
    startY: 161960,
    endX: 193284,
    endY: 171160,
  },
  {
    cellName: "C1326",
    startX: 193284,
    startY: 161960,
    endX: 199464,
    endY: 171160,
  },
  {
    cellName: "C1327",
    startX: 199464,
    startY: 161960,
    endX: 205644,
    endY: 171160,
  },
  {
    cellName: "C1328",
    startX: 205644,
    startY: 161960,
    endX: 211824,
    endY: 171160,
  },
  {
    cellName: "C1329",
    startX: 211824,
    startY: 161960,
    endX: 218004,
    endY: 171160,
  },
  {
    cellName: "C1330",
    startX: 218004,
    startY: 161960,
    endX: 224184,
    endY: 171160,
  },
  {
    cellName: "C1331",
    startX: 224184,
    startY: 161960,
    endX: 230364,
    endY: 171160,
  },
  {
    cellName: "C1332",
    startX: 230364,
    startY: 161960,
    endX: 236544,
    endY: 171160,
  },
  {
    cellName: "C1401",
    startX: 38784,
    startY: 171160,
    endX: 44964,
    endY: 180360,
  },
  {
    cellName: "C1402",
    startX: 44964,
    startY: 171160,
    endX: 51144,
    endY: 180360,
  },
  {
    cellName: "C1403",
    startX: 51144,
    startY: 171160,
    endX: 57324,
    endY: 180360,
  },
  {
    cellName: "C1404",
    startX: 57324,
    startY: 171160,
    endX: 63504,
    endY: 180360,
  },
  {
    cellName: "C1405",
    startX: 63504,
    startY: 171160,
    endX: 69684,
    endY: 180360,
  },
  {
    cellName: "C1406",
    startX: 69684,
    startY: 171160,
    endX: 75864,
    endY: 180360,
  },
  {
    cellName: "C1407",
    startX: 75864,
    startY: 171160,
    endX: 82044,
    endY: 180360,
  },
  {
    cellName: "C1408",
    startX: 82044,
    startY: 171160,
    endX: 88224,
    endY: 180360,
  },
  {
    cellName: "C1409",
    startX: 88224,
    startY: 171160,
    endX: 94404,
    endY: 180360,
  },
  {
    cellName: "C1410",
    startX: 94404,
    startY: 171160,
    endX: 100584,
    endY: 180360,
  },
  {
    cellName: "C1411",
    startX: 100584,
    startY: 171160,
    endX: 106764,
    endY: 180360,
  },
  {
    cellName: "C1412",
    startX: 106764,
    startY: 171160,
    endX: 112944,
    endY: 180360,
  },
  {
    cellName: "C1413",
    startX: 112944,
    startY: 171160,
    endX: 119124,
    endY: 180360,
  },
  {
    cellName: "C1414",
    startX: 119124,
    startY: 171160,
    endX: 125304,
    endY: 180360,
  },
  {
    cellName: "C1415",
    startX: 125304,
    startY: 171160,
    endX: 131484,
    endY: 180360,
  },
  {
    cellName: "C1416",
    startX: 131484,
    startY: 171160,
    endX: 137664,
    endY: 180360,
  },
  {
    cellName: "C1417",
    startX: 137664,
    startY: 171160,
    endX: 143844,
    endY: 180360,
  },
  {
    cellName: "C1418",
    startX: 143844,
    startY: 171160,
    endX: 150024,
    endY: 180360,
  },
  {
    cellName: "C1419",
    startX: 150024,
    startY: 171160,
    endX: 156204,
    endY: 180360,
  },
  {
    cellName: "C1420",
    startX: 156204,
    startY: 171160,
    endX: 162384,
    endY: 180360,
  },
  {
    cellName: "C1421",
    startX: 162384,
    startY: 171160,
    endX: 168564,
    endY: 180360,
  },
  {
    cellName: "C1422",
    startX: 168564,
    startY: 171160,
    endX: 174744,
    endY: 180360,
  },
  {
    cellName: "C1423",
    startX: 174744,
    startY: 171160,
    endX: 180924,
    endY: 180360,
  },
  {
    cellName: "C1424",
    startX: 180924,
    startY: 171160,
    endX: 187104,
    endY: 180360,
  },
  {
    cellName: "C1425",
    startX: 187104,
    startY: 171160,
    endX: 193284,
    endY: 180360,
  },
  {
    cellName: "C1426",
    startX: 193284,
    startY: 171160,
    endX: 199464,
    endY: 180360,
  },
  {
    cellName: "C1427",
    startX: 199464,
    startY: 171160,
    endX: 205644,
    endY: 180360,
  },
  {
    cellName: "C1428",
    startX: 205644,
    startY: 171160,
    endX: 211824,
    endY: 180360,
  },
  {
    cellName: "C1429",
    startX: 211824,
    startY: 171160,
    endX: 218004,
    endY: 180360,
  },
  {
    cellName: "C1430",
    startX: 218004,
    startY: 171160,
    endX: 224184,
    endY: 180360,
  },
  {
    cellName: "C1431",
    startX: 224184,
    startY: 171160,
    endX: 230364,
    endY: 180360,
  },
  {
    cellName: "C1432",
    startX: 230364,
    startY: 171160,
    endX: 236544,
    endY: 180360,
  },
  {
    cellName: "C1501",
    startX: 38784,
    startY: 180360,
    endX: 44964,
    endY: 189560,
  },
  {
    cellName: "C1502",
    startX: 44964,
    startY: 180360,
    endX: 51144,
    endY: 189560,
  },
  {
    cellName: "C1503",
    startX: 51144,
    startY: 180360,
    endX: 57324,
    endY: 189560,
  },
  {
    cellName: "C1504",
    startX: 57324,
    startY: 180360,
    endX: 63504,
    endY: 189560,
  },
  {
    cellName: "C1505",
    startX: 63504,
    startY: 180360,
    endX: 69684,
    endY: 189560,
  },
  {
    cellName: "C1506",
    startX: 69684,
    startY: 180360,
    endX: 75864,
    endY: 189560,
  },
  {
    cellName: "C1507",
    startX: 75864,
    startY: 180360,
    endX: 82044,
    endY: 189560,
  },
  {
    cellName: "C1508",
    startX: 82044,
    startY: 180360,
    endX: 88224,
    endY: 189560,
  },
  {
    cellName: "C1509",
    startX: 88224,
    startY: 180360,
    endX: 94404,
    endY: 189560,
  },
  {
    cellName: "C1510",
    startX: 94404,
    startY: 180360,
    endX: 100584,
    endY: 189560,
  },
  {
    cellName: "C1511",
    startX: 100584,
    startY: 180360,
    endX: 106764,
    endY: 189560,
  },
  {
    cellName: "C1512",
    startX: 106764,
    startY: 180360,
    endX: 112944,
    endY: 189560,
  },
  {
    cellName: "C1513",
    startX: 112944,
    startY: 180360,
    endX: 119124,
    endY: 189560,
  },
  {
    cellName: "C1514",
    startX: 119124,
    startY: 180360,
    endX: 125304,
    endY: 189560,
  },
  {
    cellName: "C1515",
    startX: 125304,
    startY: 180360,
    endX: 131484,
    endY: 189560,
  },
  {
    cellName: "C1516",
    startX: 131484,
    startY: 180360,
    endX: 137664,
    endY: 189560,
  },
  {
    cellName: "C1517",
    startX: 137664,
    startY: 180360,
    endX: 143844,
    endY: 189560,
  },
  {
    cellName: "C1518",
    startX: 143844,
    startY: 180360,
    endX: 150024,
    endY: 189560,
  },
  {
    cellName: "C1519",
    startX: 150024,
    startY: 180360,
    endX: 156204,
    endY: 189560,
  },
  {
    cellName: "C1520",
    startX: 156204,
    startY: 180360,
    endX: 162384,
    endY: 189560,
  },
  {
    cellName: "C1521",
    startX: 162384,
    startY: 180360,
    endX: 168564,
    endY: 189560,
  },
  {
    cellName: "C1522",
    startX: 168564,
    startY: 180360,
    endX: 174744,
    endY: 189560,
  },
  {
    cellName: "C1523",
    startX: 174744,
    startY: 180360,
    endX: 180924,
    endY: 189560,
  },
  {
    cellName: "C1524",
    startX: 180924,
    startY: 180360,
    endX: 187104,
    endY: 189560,
  },
  {
    cellName: "C1525",
    startX: 187104,
    startY: 180360,
    endX: 193284,
    endY: 189560,
  },
  {
    cellName: "C1526",
    startX: 193284,
    startY: 180360,
    endX: 199464,
    endY: 189560,
  },
  {
    cellName: "C1527",
    startX: 199464,
    startY: 180360,
    endX: 205644,
    endY: 189560,
  },
  {
    cellName: "C1528",
    startX: 205644,
    startY: 180360,
    endX: 211824,
    endY: 189560,
  },
  {
    cellName: "C1529",
    startX: 211824,
    startY: 180360,
    endX: 218004,
    endY: 189560,
  },
  {
    cellName: "C1530",
    startX: 218004,
    startY: 180360,
    endX: 224184,
    endY: 189560,
  },
  {
    cellName: "C1531",
    startX: 224184,
    startY: 180360,
    endX: 230364,
    endY: 189560,
  },
  {
    cellName: "C1532",
    startX: 230364,
    startY: 180360,
    endX: 236544,
    endY: 189560,
  },
  {
    cellName: "C1601",
    startX: 38784,
    startY: 189560,
    endX: 44964,
    endY: 198760,
  },
  {
    cellName: "C1602",
    startX: 44964,
    startY: 189560,
    endX: 51144,
    endY: 198760,
  },
  {
    cellName: "C1603",
    startX: 51144,
    startY: 189560,
    endX: 57324,
    endY: 198760,
  },
  {
    cellName: "C1604",
    startX: 57324,
    startY: 189560,
    endX: 63504,
    endY: 198760,
  },
  {
    cellName: "C1605",
    startX: 63504,
    startY: 189560,
    endX: 69684,
    endY: 198760,
  },
  {
    cellName: "C1606",
    startX: 69684,
    startY: 189560,
    endX: 75864,
    endY: 198760,
  },
  {
    cellName: "C1607",
    startX: 75864,
    startY: 189560,
    endX: 82044,
    endY: 198760,
  },
  {
    cellName: "C1608",
    startX: 82044,
    startY: 189560,
    endX: 88224,
    endY: 198760,
  },
  {
    cellName: "C1609",
    startX: 88224,
    startY: 189560,
    endX: 94404,
    endY: 198760,
  },
  {
    cellName: "C1610",
    startX: 94404,
    startY: 189560,
    endX: 100584,
    endY: 198760,
  },
  {
    cellName: "C1611",
    startX: 100584,
    startY: 189560,
    endX: 106764,
    endY: 198760,
  },
  {
    cellName: "C1612",
    startX: 106764,
    startY: 189560,
    endX: 112944,
    endY: 198760,
  },
  {
    cellName: "C1613",
    startX: 112944,
    startY: 189560,
    endX: 119124,
    endY: 198760,
  },
  {
    cellName: "C1614",
    startX: 119124,
    startY: 189560,
    endX: 125304,
    endY: 198760,
  },
  {
    cellName: "C1615",
    startX: 125304,
    startY: 189560,
    endX: 131484,
    endY: 198760,
  },
  {
    cellName: "C1616",
    startX: 131484,
    startY: 189560,
    endX: 137664,
    endY: 198760,
  },
  {
    cellName: "C1617",
    startX: 137664,
    startY: 189560,
    endX: 143844,
    endY: 198760,
  },
  {
    cellName: "C1618",
    startX: 143844,
    startY: 189560,
    endX: 150024,
    endY: 198760,
  },
  {
    cellName: "C1619",
    startX: 150024,
    startY: 189560,
    endX: 156204,
    endY: 198760,
  },
  {
    cellName: "C1620",
    startX: 156204,
    startY: 189560,
    endX: 162384,
    endY: 198760,
  },
  {
    cellName: "C1621",
    startX: 162384,
    startY: 189560,
    endX: 168564,
    endY: 198760,
  },
  {
    cellName: "C1622",
    startX: 168564,
    startY: 189560,
    endX: 174744,
    endY: 198760,
  },
  {
    cellName: "C1623",
    startX: 174744,
    startY: 189560,
    endX: 180924,
    endY: 198760,
  },
  {
    cellName: "C1624",
    startX: 180924,
    startY: 189560,
    endX: 187104,
    endY: 198760,
  },
  {
    cellName: "C1625",
    startX: 187104,
    startY: 189560,
    endX: 193284,
    endY: 198760,
  },
  {
    cellName: "C1626",
    startX: 193284,
    startY: 189560,
    endX: 199464,
    endY: 198760,
  },
  {
    cellName: "C1627",
    startX: 199464,
    startY: 189560,
    endX: 205644,
    endY: 198760,
  },
  {
    cellName: "C1628",
    startX: 205644,
    startY: 189560,
    endX: 211824,
    endY: 198760,
  },
  {
    cellName: "C1629",
    startX: 211824,
    startY: 189560,
    endX: 218004,
    endY: 198760,
  },
  {
    cellName: "C1630",
    startX: 218004,
    startY: 189560,
    endX: 224184,
    endY: 198760,
  },
  {
    cellName: "C1631",
    startX: 224184,
    startY: 189560,
    endX: 230364,
    endY: 198760,
  },
  {
    cellName: "C1632",
    startX: 230364,
    startY: 189560,
    endX: 236544,
    endY: 198760,
  },
];

export const getPlantCoordinates = (plant, renderObject) => {
  if (plant === "CAR") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 4500,
        rectTopRightX: 553500,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 453500,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 455000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 450000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ATL") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 78000,
        rectBottomLeftY: 12000,
        rectTopRightX: 533000,
        rectTopRightY: 195000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: 32000, //T->B
        rectTopRightX: 472000, //R->L shrink
        rectTopRightY: 153500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -1300,
        rectBottomLeftY: 2000,
        rectTopRightX: 384000,
        rectTopRightY: 125300,
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000, //15000
        rectBottomLeftY: 13000, //333000
        rectTopRightX: 523000, //0
        rectTopRightY: 171000, //143000
      };
    }
  } else if (plant === "DET") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 80000,
        rectBottomLeftY: 12000,
        rectTopRightX: 473000,
        rectTopRightY: 195000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 14000, // decreasing L->R
        rectBottomLeftY: -4500, // shrink B->T 1000
        rectTopRightX: 334700,
        rectTopRightY: 145500, // increase T->B 146000
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 127000, //R->L
        rectBottomLeftY: 36000, //T->B
        rectTopRightX: 449000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ALA") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: -20000, //T->B
        rectTopRightX: 415000, //R->L shrink
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 38000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 188500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 42500,
        rectBottomLeftY: 108000,
        rectTopRightX: 339000,
        rectTopRightY: 267000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 20000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "HAZ") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 237000, //R->L
        rectBottomLeftY: 200000, //T->B
        rectTopRightX: 902000, //R->L shrink
        rectTopRightY: 450000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 207000, //R->L
        rectBottomLeftY: 270000, //T->B
        rectTopRightX: 642000, //R->L shrink
        rectTopRightY: 470000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 116000,
        rectBottomLeftY: 212500,
        rectTopRightX: 555000,
        rectTopRightY: 410000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
    // return {
    //   rectBottomLeftX: 116000,
    //   rectBottomLeftY: 214000,
    //   rectTopRightX: 555000,
    //   rectTopRightY: 410000,
    // };
  } else if (plant === "HOU") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 108000, //R->L
        rectBottomLeftY: -9000, //T->B
        rectTopRightX: 522000, //R->L shrink
        rectTopRightY: 131000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -2500,
        rectBottomLeftY: 0,
        rectTopRightX: 376900,
        rectTopRightY: 118000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 116500, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 495000, //R->L shrink
        rectTopRightY: 151000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "LAS") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 84000, //R->L
        rectBottomLeftY: -16000, //T->B
        rectTopRightX: 392000, //R->L shrink
        rectTopRightY: 152000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 19000,
        rectBottomLeftY: -53000,
        rectTopRightX: 317000,
        rectTopRightY: 210000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 370000, //R->L shrink
        rectTopRightY: 169000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MIA") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: -5000, //T->B
        rectTopRightX: 380000, //R->L shrink
        rectTopRightY: 165000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 355000, //R->L shrink
        rectTopRightY: 190000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -25000,
        rectBottomLeftY: 136000,
        rectTopRightX: 250000,
        rectTopRightY: -25000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "TAC") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 90000, //R->L
        rectBottomLeftY: -30000, //T->B
        rectTopRightX: 315000, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 45000, //R->L
        rectBottomLeftY: 17500, //T->B
        rectTopRightX: 401000, //R->L shrink
        rectTopRightY: 167000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -40000,
        rectBottomLeftY: 55000,
        rectTopRightX: 310000,
        rectTopRightY: 208000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "JAX") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: -50000, //T->B
        rectTopRightX: 565000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 465000, //R->L shrink
        rectTopRightY: 200000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 11000,
        rectBottomLeftY: 8500,
        rectTopRightX: 409000,
        rectTopRightY: 185000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "LAN") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: -18000, //R->L
        rectBottomLeftY: -75000, //T->B
        rectTopRightX: 568000, //R->L shrink
        rectTopRightY: 150000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -1500, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 525000, //R->L shrink
        rectTopRightY: 245000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ST3") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: -100000, //T->B
        rectTopRightX: 510500, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 462500, //R->L shrink
        rectTopRightY: 165000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 1000,
        rectBottomLeftY: 5000,
        rectTopRightX: 383000,
        rectTopRightY: 150000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BAL") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: -145000, //T->B
        rectTopRightX: 470000, //R->L shrink BAL
        rectTopRightY: 100000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: 1500, //T->B
        rectTopRightX: 463500, //R->L shrink BAL
        rectTopRightY: 169000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KC2") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 91000, //R->L
        rectBottomLeftY: -58000, //T->B
        rectTopRightX: 470500, //R->L shrink KC2
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 91500, //R->L
        rectBottomLeftY: 1500, //T->B
        rectTopRightX: 459900, //R->L shrink BAL
        rectTopRightY: 169000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MOR") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 63000, //R->L
        rectBottomLeftY: -90000, //T->B
        rectTopRightX: 510000, //R->L shrink BAL
        rectTopRightY: 134000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 78500, //R->L
        rectBottomLeftY: 1800, //T->B
        rectTopRightX: 499500, //R->L shrink BAL
        rectTopRightY: 168500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 0, //R->L
        rectBottomLeftY: 0, //T->B
        rectTopRightX: 0, //R->L shrink
        rectTopRightY: 0, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 529700,
        rectTopRightY: 161000,
      };
    }
  } else if (plant === "TEM") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: -90000, //T->B
        rectTopRightX: 520000, //R->L shrink BAL
        rectTopRightY: 120000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -46800, //R->L
        rectBottomLeftY: -55500, //T->B
        rectTopRightX: 350500, //R->L shrink TEM
        rectTopRightY: 121000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 0, //R->L
        rectBottomLeftY: 0, //T->B
        rectTopRightX: 0, //R->L shrink
        rectTopRightY: 0, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 161000,
      };
    }
  } else if (plant === "LOU") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 82000,
        rectBottomLeftY: 15500,
        rectTopRightX: 463000,
        rectTopRightY: 148000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 92000, //R->L
        rectBottomLeftY: 13000, //T->B
        rectTopRightX: 445000, //R->L shrink LOU
        rectTopRightY: 148500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BAY") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 100000,
        rectBottomLeftY: -15500,
        rectTopRightX: 433000,
        rectTopRightY: 170000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: 500, //T->B
        rectTopRightX: 425000, //R->L shrink BAY
        rectTopRightY: 175000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -96000,
        rectBottomLeftY: -55800,
        rectTopRightX: 240000,
        rectTopRightY: 125000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KNC") {
    //KNC PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 5000,
        rectTopRightX: 403000,
        rectTopRightY: 218000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 55500, //T->B
        rectTopRightX: 300000, //R->L shrink
        rectTopRightY: 212000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "COL") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 82000,
        rectBottomLeftY: -40500,
        rectTopRightX: 343000,
        rectTopRightY: 120000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 303800, //R->L
        rectBottomLeftY: 13000, //T->B
        rectTopRightX: 675000, //R->L shrink RCH
        rectTopRightY: 150000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 34000,
        rectTopRightX: 425000,
        rectTopRightY: 176000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "RCH") {
    if (renderObject === "heatmap") {
      return {
        //RCH plant
        rectBottomLeftX: 91600,
        rectBottomLeftY: -7500,
        rectTopRightX: 508000,
        rectTopRightY: 148000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 61000, //R->L
        rectBottomLeftY: 24000, //T->B
        rectTopRightX: 462000, //R->L shrink RCH
        rectTopRightY: 159000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 0,
        rectBottomLeftY: -6000,
        rectTopRightX: 435000,
        rectTopRightY: 131000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BLM") {
    //BLM PLANT CURRENT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 130000,
        rectBottomLeftY: 500,
        rectTopRightX: 430000,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 56500, //T->B
        rectTopRightX: 317000, //R->L shrink BLM
        rectTopRightY: 190000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KEN") {
    //KEN PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 74000,
        rectBottomLeftY: -2500,
        rectTopRightX: 415000,
        rectTopRightY: 138000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 1000, //R->L
        rectBottomLeftY: 67000, //T->B
        rectTopRightX: 342000, //R->L shrink KEN
        rectTopRightY: 170000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "SAN") {
    //SAN PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 57000,
        rectBottomLeftY: 35500,
        rectTopRightX: 505000,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 11000, //R->L
        rectBottomLeftY: 36500, //T->B
        rectTopRightX: 460000, //R->L shrink BLM
        rectTopRightY: 158000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 14000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "SL2") {
    //SL2 PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 30500,
        rectTopRightX: 300000,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -6500, //R->L
        rectBottomLeftY: 60000, //T->B
        rectTopRightX: 189500, //R->L shrink SL2
        rectTopRightY: 192000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MES") {
    //MES PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 30500,
        rectTopRightX: 490000,
        rectTopRightY: 215000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 58500, //T->B
        rectTopRightX: 387000, //R->L shrink BLM
        rectTopRightY: 197000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 29000,
        rectBottomLeftY: 30000,
        rectTopRightX: 405000,
        rectTopRightY: 173000,
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "MIS") {
    //MIS PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 120000,
        rectBottomLeftY: 13500,
        rectTopRightX: 450000,
        rectTopRightY: 228000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 27000, //R->L
        rectBottomLeftY: 50000, //T->B
        rectTopRightX: 410000, //R->L shrink MIS
        rectTopRightY: 220000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -8500,
        rectBottomLeftY: 16500,
        rectTopRightX: 380000,
        rectTopRightY: 197200,
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "PIT") {
    //PIT PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 65000,
        rectBottomLeftY: 0,
        rectTopRightX: 409000,
        rectTopRightY: 225000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -12000, //R->L
        rectBottomLeftY: 56000, //T->B
        rectTopRightX: 328000, //R->L shrink PIT
        rectTopRightY: 220000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 10000,
        rectTopRightX: 590000,
        rectTopRightY: 231000,
      };
    }
  } else if (plant === "MTY") {
    //MTY PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 117000,
        rectBottomLeftY: 15500,
        rectTopRightX: 462000,
        rectTopRightY: 215000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 220000, //R->L
        rectBottomLeftY: 46000, //T->B
        rectTopRightX: 561500, //R->L shrink PIT
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "OKC") {
    //OKC PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 115000,
        rectBottomLeftY: 14500,
        rectTopRightX: 450000,
        rectTopRightY: 208000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 19000, //R->L
        rectBottomLeftY: 67700, //T->B
        rectTopRightX: 362500, //R->L shrink OKC
        rectTopRightY: 239500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "MXC") {
    //MIS PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: -20500,
        rectTopRightX: 440000,
        rectTopRightY: 268000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 28000, //R->L
        rectBottomLeftY: 66000, //T->B
        rectTopRightX: 351000, //R->L shrink MXC
        rectTopRightY: 262000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: -42000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "NOR") {
    //NOR PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 4500,
        rectTopRightX: 553500,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 453500,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 455000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 150000,
        rectBottomLeftY: 50000,
        rectTopRightX: 550000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BAK") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 4500,
        rectTopRightX: 553500,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 453500,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 455000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 450000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "RIA") {
    //RIA PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 4500,
        rectTopRightX: 553500,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 453500,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 455000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 130000,
        rectBottomLeftY: -60000,
        rectTopRightX: 450000,
        rectTopRightY: 150000,
      };
    }
  } else if (plant === "ST4") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: -20000, //T->B
        rectTopRightX: 415000, //R->L shrink
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 38000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 188500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 42500,
        rectBottomLeftY: 108000,
        rectTopRightX: 339000,
        rectTopRightY: 267000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 20000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "PLA") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: -20000, //T->B
        rectTopRightX: 415000, //R->L shrink
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 38000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 188500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 42500,
        rectBottomLeftY: 108000,
        rectTopRightX: 339000,
        rectTopRightY: 267000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 20000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  }
};

export const possibleZoomScale = [
  "scale(1)",
  "scale(1.2)",
  "scale(1.4)",
  "scale(1.6)",
  "scale(1.8)",
  "scale(2)",
  "scale(2.2)",
  "scale(2.4)",
  "scale(2.6)",
  "scale(2.8)",
  "scale(3)",
  "scale(3.2)",
  "scale(3.4)",
  "scale(3.6)",
  "scale(3.8)",
  "scale(4)",
  "scale(4.2)",
  "scale(4.4)",
  "scale(4.6)",
  "scale(4.8)",
  "scale(5)",
];
