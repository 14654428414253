import {
  ImageOverlay,
  MapContainer,
  Marker,
  Rectangle,
  useMapEvents,
  Tooltip,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "../../assets/css/NavQualityHeatmap/navQualityHeatmap.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../Context/Context";
import {
  convertReactComponentToHtmlIcon,
  getLocalStorageItem,
  getPlantMapImageUrl,
} from "../../util/helper/helperFunctions";
import {
  getBoundsOfGrids,
  getBoundsOfView,
  getNavIconColor,
  getNavQualityWeightage,
  getSelectedLgvData,
  groupLgvsAndConvertCoordinates,
  sortLgvList,
  toggleMapDrag,
} from "../../util/helper/navHeatmap/navHeatmapHelper";
import {
  DB_ERROR,
  Level0Config,
  Level1Config,
} from "../../util/helper/constants";
import { getNavHeatmapData } from "../../util/network/databricksHandler";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import LgvCheckList from "./components/LgvCheckList/LgvCheckList";
import ZoomIndicator from "./components/ZoomIndicator/ZoomIndicator";
import AlertMessage from "../Reusables/AlertMessage/AlertMessage";
import { initiateSocketConnection } from "../../socket/socket";

const NavQualityHeatmap = ({ socket }) => {
  //Hooks
  const [mapObject, setMapObject] = useState();
  const [dynamicZoom, setDynamicZoom] = useState(0);

  const [loaderLabel, setLoaderLabel] = useState("Loading Nav Heatmap...");
  const apiControllerRef = useRef();
  const [groupedLgvs, setGroupedLgvs] = useState(null);
  const [selectedLgvs, setSelectedLgvs] = useState([]);
  const [alertMessageType, setAlertMessageType] = useState(null);
  const [allLgvsOfThisPlant, setAllLgvsOfThisPlant] = useState(null);
  const [socketConnection, setSocketConnection] = useState();

  //Context
  const {
    navQualityHeatmapIsEnabled,
    setNavQualityHeatmapIsEnabled,
    setTimeRangePopup,
  } = useContext(AppContext);
  const { convertedNavHeatmapData, setConvertedNavHeatmapData } =
    useContext(AppContext);
  const { appConfig, setAppConfig } = useContext(AppContext);
  const { plantConfig, setPlantConfig } = useContext(AppContext);
  const { navHeatmapLoader, setNavHeatmapLoader } = useContext(AppContext);
  const { navHeatmapTimeFrames, setNavHeatmapTimeFrames } =
    useContext(AppContext);
  const { isLevel3ApiComplete, setIsLevel3ApiComplete } =
    useContext(AppContext);
  const { navLevel2Data, setNavLevel2Data } = useContext(AppContext);
  const { navLevel1Data, setNavLevel1Data } = useContext(AppContext);
  const { navLevel0Data, setNavLevel0Data } = useContext(AppContext);
  const { connectionStatus, setConnectionStatus } = useContext(AppContext);
  const { enableCheckList, setEnableCheckList } = useContext(AppContext);

  const localNavHeatMapTimeframe = useMemo(() => {
    return localStorage.getItem("navHeatMapTimeRange")
      ? JSON.parse(localStorage.getItem("navHeatMapTimeRange"))
      : null;
  }, []);

  //@initial mounting
  useEffect(() => {
    if (localNavHeatMapTimeframe) {
      //checks local strorage timeframe and  updates context nav timeframe && sets navHeatMapEnabled
      const {
        fromDate: LfromDate,
        fromTime: LfromTime,
        toDate: LToDate,
        toTime: LtoTime,
      } = localNavHeatMapTimeframe;

      if (LfromDate && LfromTime && LToDate && LtoTime) {
        setNavHeatmapTimeFrames(localNavHeatMapTimeframe);
        setNavQualityHeatmapIsEnabled(true);
      }
    }
  }, [localNavHeatMapTimeframe]);

  /*************************************************************************** */
  /**Function Handlers */
  /**
   * Function - Validate zoom level based on config
   * Inputs - dynamicZoom, zoomLevels
   * Output - boolean
   */
  const validateZoomLevel = (dynamicZoom, zoomLevels) => {
    if (dynamicZoom !== undefined) {
      if (zoomLevels[dynamicZoom]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**Function -  */

  /*************************************************************************** */
  /**Algorithm */

  /**Function - Apply Heat to Grid
   * Inputs - Empty Grids List, Heat Data
   * Output - Grids Object with Heat
   */
  const applyHeatToEmptyGrids = (
    emptyGridData,
    levelDataWithHeat,
    dynamicZoom
  ) => {
    let count = 0;
    for (const [key, value] of Object.entries(levelDataWithHeat)) {
      if (dynamicZoom === 4) {
        // emptyGridData[count].nav = null;
        return undefined;
      } else {
        emptyGridData[count].nav = value.nav;
      }
      count++;
    }
    return emptyGridData;
  };

  /**Function - Create Empty Grid Box with coordinates
   * Inputs - Grids Meta data
   * Output - Grids Array
   */
  const createGridBoxes = (boxMetaData) => {
    const { row, column, size } = boxMetaData;
    const grid = [];
    if (mapObject) {
      // Calculate the coordinates for the grid of rectangles
      for (let i = 0; i < column; i++) {
        for (let j = 0; j < row; j++) {
          let cellName = `${i <= 9 ? "0" : ""}${i + 1}${j <= 9 ? "0" : ""}${
            j + 1
          }`;
          // Calculate the center coordinates relative to the marker [0, 0]
          const lat1 = (i - column / 2) * size;
          const lng1 = (j - row / 2) * size;
          const lat2 = lat1 + size;
          const lng2 = lng1 + size;
          const center = [(lat1 + lat2) / 2, (lng1 + lng2) / 2];
          grid.push({
            bounds: [
              [lat1, lng1],
              [lat2, lng2],
            ],
            center,
            cellName: cellName,
          });
        }
      }
    }
    return grid;
  };

  /**Function - Combine Level Config and previous level data
   * Inputs - Config data, heat data
   * Output - heat Object data
   */
  const mapGridBoxBasedOnZoomLevel = (levelConfig, gridData) => {
    let levelData = {};
    for (const [key, value] of Object.entries(levelConfig)) {
      if (!levelData[key]) {
        levelData[key] = [];
      }
      value.forEach((item) => {
        levelData[key].push(gridData[item]);
      });
    }
    return levelData;
  };

  /**
   * Function - Combine raw data with name as key
   * Inputs - List of Raw data
   * Output - Combined data Object
   */
  const groupGridBoxesWithName = (gridList) => {
    let groupedGridBoxObject = {};

    gridList.forEach((grid) => {
      if (!groupedGridBoxObject[grid.name]) {
        groupedGridBoxObject[grid.name] = [grid];
      } else {
        groupedGridBoxObject[grid.name].push(grid);
      }
    });
    return groupedGridBoxObject;
  };

  /**
   * Function - To create Cell object from raw data
   * Input - Raw list of nav data
   * Output - Object with key as cellname
   */
  const formatListToObjectWithCellName = (level2Data) => {
    let level2Object = {};
    level2Data.forEach((item, index) => {
      let dataObject = {
        name: item.boxName,
        nav: item.navQ === null ? 0 : Math.ceil(Number(item.navQ)),
        records: item.noOfRecords === null ? 0 : Number(item.noOfRecords),
      };
      level2Object[item.boxName] = dataObject;
    });
    return level2Object;
  };

  /**
   * Function - Aggregate data for each grouped cell in data list
   * Input - Grouped Object data
   * Output - Object with single nav data for each cell
   */
  const aggregateNavQualityForGridCells = (groupedObject) => {
    let aggregatedObject = {};

    for (const [key, value] of Object.entries(groupedObject)) {
      let totalNav = 0;
      let totalRecords = 0;
      let aggregatedNav = 0;

      value.forEach((item) => {
        item.nav *= item.records;
        totalRecords += item.records;
        totalNav += item.nav;
      });

      if (totalNav !== 0 && totalRecords !== 0) {
        aggregatedNav = Math.round(totalNav / totalRecords);
      }

      if (!aggregatedObject[key]) {
        aggregatedObject[key] = {
          name: key,
          nav: aggregatedNav,
          records: totalRecords,
        };
      }
    }
    return aggregatedObject;
  };

  /**
   * Function - Generate Data for all 3 levels
   * Input - Heatmap Data
   * Output - null
   */
  const generateDataForAll3Levels = (heatmapRespData) => {
    //Level 2
    //Databricks data
    let level2DataFromDatabricks = heatmapRespData;
    //Formatting raw data for cellName as key
    let level2Data = formatListToObjectWithCellName(level2DataFromDatabricks);
    setNavLevel2Data(level2Data);

    //Level 1
    let level2DataRef = structuredClone(level2Data);
    let level1GroupedData = mapGridBoxBasedOnZoomLevel(
      Level1Config,
      level2DataRef
    );
    let level1Data = aggregateNavQualityForGridCells(level1GroupedData);
    setNavLevel1Data(level1Data);

    //Level 0
    let level1DataRef = structuredClone(level1Data);
    let level0GroupedData = mapGridBoxBasedOnZoomLevel(
      Level0Config,
      level1DataRef
    );
    let level0Data = aggregateNavQualityForGridCells(level0GroupedData);
    setNavLevel0Data(level0Data);
  };

  /**
   * Function - Get Bounds of the Grids in View
   * Input - Map Object
   * Output - Bounds with start and end
   */
  const getBoundsOfTheGridsOnTheView = (bounds, mapObject) => {
    //Get all the grids comes under the bounds
    //let Contained Grids
    let gridsInView = [];
    //Get all the grids available
    let allGridsRendered = mapObject.map.target._targets;
    //Remove first Object

    if (allGridsRendered) {
      const firstKey = Object.keys(allGridsRendered)[0];
      delete allGridsRendered[firstKey];

      Object.values(allGridsRendered).forEach((item) => {
        if (item.options.id) {
          let center = item.getBounds().getCenter();
          let gridPos = L.latLng(center);
          if (bounds.contains(gridPos)) {
            gridsInView.push(item);
          }
        }
      });

      if (gridsInView.length !== 0) {
        return getBoundsOfGrids(gridsInView);
      } else {
        return null;
      }
    }
  };

  /*************************************************************************** */
  /**API/DataBricks Handlers */

  const initiateLevel2Heatmap = async (
    navHeatmapTimeFrames,
    navQualityHeatmapIsEnabled,
    mapObject
  ) => {
    try {
      if (navQualityHeatmapIsEnabled && mapObject) {
        if (Object.keys(navHeatmapTimeFrames).length !== 0) {
          let plant = getLocalStorageItem("locationCode");
          //Get Level 2 Data from Databricks
          setLoaderLabel("Loading Nav Heatmap...");
          let level2DataFromCloud = await getNavHeatmapData(
            plant,
            navHeatmapTimeFrames,
            "navLevel2",
            null,
            apiControllerRef
          );
          if (level2DataFromCloud !== undefined) {
            //Generate all 3 level Grid Data
            generateDataForAll3Levels(level2DataFromCloud);
            setNavHeatmapLoader(false);
          } else {
            setNavHeatmapLoader(true);
            setLoaderLabel(
              "No Data Available. Please select different Time Range..."
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLevel3DataFromCloudWithBounds = async (
    bounds,
    navHeatmapTimeFrames
  ) => {
    try {
      let plant = getLocalStorageItem("locationCode");
      //Abort Controller
      let level3DataFromCloud = await getNavHeatmapData(
        plant,
        navHeatmapTimeFrames,
        "navLevel3",
        bounds,
        apiControllerRef
      );
      let dataWithinBounds = [];
      if (level3DataFromCloud !== undefined) {
        level3DataFromCloud.forEach((item) => {
          if (
            item.longitude >= bounds.startX &&
            item.longitude <= bounds.endX &&
            item.latitude >= bounds.startY &&
            item.latitude <= bounds.endY
          ) {
            dataWithinBounds.push(item);
          }
        });
        return dataWithinBounds;
      } else {
        return undefined;
      }
    } catch (err) {
      console.log("getLevel3DataFromCloudWithBounds Error -", err);
    }
  };

  /*************************************************************************** */
  /**Map Handlers */
  const centerMapView = (plantConfig) => {
    if (mapObject) {
      const { viewport } = plantConfig;
      mapObject.map.target.setView(viewport.center);
    }
  };
  const mapWhenReadyHandler = (map) => {
    if (map) {
      setMapObject({ map });
    }
  };
  const initiateLevel3Heatmap = async () => {
    try {
      if (
        mapObject &&
        plantConfig &&
        navHeatmapTimeFrames &&
        dynamicZoom === 4 &&
        socket
      ) {
        setAlertMessageType(null);
        toggleMapDrag(true);
        //Bounds of the view
        const bounds = mapObject.map.target.getBounds();
        const boundsOfView = getBoundsOfView(plantConfig, bounds);
        setIsLevel3ApiComplete(true);
        if (boundsOfView !== null) {
          const lgvData = await getLevel3DataFromCloudWithBounds(
            boundsOfView,
            navHeatmapTimeFrames
          );
          if (lgvData !== undefined) {
            setConvertedNavHeatmapData(lgvData);
          } else {
            //For debugging
            console.log("::::::NO DATA IN THIS REGION::::::");
            setAlertMessageType("noData");
            setConvertedNavHeatmapData(null);
            setEnableCheckList(false);
          }
        }
      }
    } catch (err) {
      console.log("ERR - MoveEnd", err);
    } finally {
      setIsLevel3ApiComplete(false);
      toggleMapDrag(false);
    }
  };

  const ZoomEventHandlers = () => {
    const map = useMapEvents({
      moveend() {
        initiateLevel3Heatmap();
      },
      zoomend() {
        let zoomLevel = mapObject.map.target.getZoom();
        setDynamicZoom(JSON.parse(JSON.stringify(zoomLevel)));
      },
    });
    return null;
  };

  //Initiates API call on L4 - zoom
  useEffect(() => {
    dynamicZoom === 4 && initiateLevel3Heatmap();
  }, [dynamicZoom]);

  const resetZoom = () => {
    if (mapObject) {
      //Set Zoom
      mapObject.map.target.setZoom(0);

      setTimeout(() => {
        //Set Map Center
        mapObject.map.target.flyTo([0, 0]);
      }, 500);
    }
  };

  const ZoomControlCenter = () => {
    return (
      !navHeatmapLoader && (
        <div className="zoom-control-center-nav">
          <button
            className="zoom-controls-nav zoom-reset-controls-nav"
            onClick={() => resetZoom()}
          >
            <img alt="zoom-refresh-icon" src={zoomRefreshIcon} />
          </button>
        </div>
      )
    );
  };

  const RenderZoomIndicator = useMemo(() => {
    return (
      dynamicZoom &&
      !navHeatmapLoader && <ZoomIndicator zoomLevel={dynamicZoom} />
    );
  }, [dynamicZoom]);

  const renderAlertMessage = useMemo(() => {
    if (alertMessageType !== null) {
      let alertProps = {
        heading: "",
        description: "",
        type: "",
      };
      switch (alertMessageType) {
        case "noData":
          alertProps.heading = "No Data Available";
          alertProps.description =
            "Please drag to any other part of the map to view Nav Quality data";
          alertProps.type = "warning";
          break;
      }
      return (
        <AlertMessage
          heading={alertProps.heading}
          description={alertProps.description}
          type={alertProps.type}
          setAlertMessageType={setAlertMessageType}
        />
      );
    }
  }, [alertMessageType]);

  //auto reset handled below
  const resetTimeoutRef = useRef(null);

  useEffect(() => {
    // Clear any existing timeout if the state updates
    if (resetTimeoutRef.current) {
      clearTimeout(resetTimeoutRef.current);
    }

    if (alertMessageType === "noData") {
      resetTimeoutRef.current = setTimeout(() => {
        setAlertMessageType(null);
      }, 30000);
    }

    return () => {
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
    };
  }, [alertMessageType]);

  /*************************************************************************** */
  /**Components */

  /**
   * Function - Rendering Map on the Map container with Map Object and coordinates
   * Dependency - mapObject, appConfig, plantConfig
   */
  const RenderMap = useMemo(() => {
    let locationCode = getLocalStorageItem("locationCode");
    if (appConfig && mapObject && plantConfig) {
      let img = getPlantMapImageUrl(appConfig, locationCode, "WAREHOUSE_MAP");
      //Get Plant Config
      const { mapBounds } = plantConfig.navHeatmap;
      if (img && mapBounds) {
        //Get Image, Image Bounds (limit), X1,Y1 , X2,Y2 coordinates of image and Image bg color
        const { startX, startY, endX, endY } = mapBounds;
        let overlayBounds = [
          [startX, startY],
          [endX, endY],
        ];
        return <ImageOverlay url={img} bounds={overlayBounds} />;
      }
    }
  }, [mapObject, appConfig, plantConfig]);

  /**
   * Function - Generate Grid Boxes on each level with Heat
   * Dependency - dynamicZoom, plantConfig
   */
  const GenerateNavHeatMap = useMemo(() => {
    if (plantConfig) {
      const { navHeatmap } = plantConfig;
      if (validateZoomLevel(dynamicZoom, navHeatmap.boxCount)) {
        if (
          navHeatmap !== undefined &&
          navLevel0Data &&
          navLevel1Data &&
          navLevel2Data
        ) {
          const { boxCount } = navHeatmap;
          let boxMetaData = boxCount[dynamicZoom];

          //Generate Grid Boxes with Vertical, Horizontal count and size of each box
          let navData;

          if (dynamicZoom >= 0 && dynamicZoom < 1) {
            navData = navLevel0Data;
          } else if (dynamicZoom >= 1 && dynamicZoom < 2) {
            navData = navLevel1Data;
          } else if (dynamicZoom >= 2 && dynamicZoom <= 4.5) {
            navData = navLevel2Data;
          } else {
            return null;
          }

          if (navData) {
            let gridData = createGridBoxes(boxMetaData);
            let heatData = applyHeatToEmptyGrids(
              gridData,
              navData,
              dynamicZoom
            );

            if (heatData !== undefined) {
              return heatData.map((square, index) => {
                let bounds = L.rectangle(square.bounds).getBounds();
                return (
                  <Rectangle
                    id={square.cellName}
                    pathOptions={getNavQualityWeightage(square.nav)}
                    key={index}
                    bounds={bounds}
                    color="black"
                    strokeColor="black"
                    strokeWidth="0.5"
                  ></Rectangle>
                );
              });
            }
          }
        }
      }
    }
  }, [dynamicZoom, plantConfig, navLevel0Data, navLevel1Data, navLevel2Data]);

  /**
   * Function - To reduce the border width of the grids on level 3
   */
  //Reducing the border width of grid boxes after rendering
  const ChangeStrokeWidth = useMemo(() => {
    if (dynamicZoom === 4) {
      let boxElements = document.querySelectorAll("path.leaflet-interactive");
      boxElements.forEach((element) => {
        element.style.strokeWidth = "0.05";
      });
    }
    let boxElements = document.querySelectorAll("path.leaflet-interactive");
    boxElements.forEach((element) => {
      element.style.strokeWidth = "0.5";
    });
  }, [dynamicZoom]);

  /**
   * Function - Create nav icons with x,y, nav value and lgv id
   * Dependency - mapObject, dynamicZoom, convertedNavHeatmapData
   */
  const [isSelectionFirst, setIsSelectionFirst] = useState(true);
  const GenerateNavIcons = useMemo(() => {
    // const startTime = performance.now();
    if (mapObject && plantConfig && convertedNavHeatmapData) {
      const { navHeatmap } = plantConfig;
      if (!validateZoomLevel(navHeatmap, navHeatmap.boxCount)) {
        // const startTime = performance.now();
        if (dynamicZoom === 4) {
          let groupedLgvs = groupLgvsAndConvertCoordinates(
            navHeatmap,
            convertedNavHeatmapData,
            mapObject
          );
          setGroupedLgvs(groupedLgvs);

          const selectedLgvList = getSelectedLgvData(
            isSelectionFirst,
            setIsSelectionFirst,
            groupedLgvs,
            selectedLgvs
          );
          if (selectedLgvList.length !== 0) {
            //Marker Config
            const { marker } = navHeatmap;
            const { size, anchor } = marker;

            //Enable the CheckList
            setEnableCheckList(true);
            setIsSelectionFirst(false);

            let sortedLgvList = sortLgvList(
              structuredClone(Object.values(selectedLgvList).flat())
            );

            return sortedLgvList.map((lgv, index) => {
              //Marker
              let reactIcon = convertReactComponentToHtmlIcon(
                <span className={`nav-icon`}>{lgv.navQ}</span>,
                `nav-icon ${getNavIconColor(lgv.navQ)}`,
                size,
                anchor
              );

              return (
                <Marker
                  key={index}
                  position={lgv.downscaleCoordinates}
                  icon={reactIcon}
                >
                  <Tooltip className="custom-tooltip">
                    <div>
                      <div>LGV : {lgv.lgvId ?? "NA"}</div>
                      <div>NavQ: {lgv.navQ ?? "NA"}</div>
                    </div>
                  </Tooltip>
                </Marker>
              );
            });
          }
        }
        // const endTime = performance.now();
        // console.log(
        //   `Rendering Markers at level 3 took ${endTime - startTime} ms`
        // );
      }
    }
  }, [mapObject, dynamicZoom, convertedNavHeatmapData, selectedLgvs]);

  /**Function - To Render Loader */
  const renderLoader = useMemo(() => {
    // console.log("network", connectionStatus);
    if (navHeatmapLoader) {
      // setLoaderLabel("No Internet. Please check your Internet Connection...");
      return (
        <div className="loader">
          <>
            <h4>{loaderLabel}</h4>
            {!loaderLabel.includes("No Data") ? (
              <div className="spinner"></div>
            ) : null}
          </>
        </div>
      );
    } else {
      return null;
    }
  }, [navHeatmapLoader, connectionStatus, loaderLabel]);

  /**Function - To update the selected LGV List */
  const checkListLgvHandler = (checkedValues) => {
    setSelectedLgvs(checkedValues);
  };

  /*************************************************************************** */
  /**Use Effects */

  /**Component on-mount */

  /**
   * Function - Initial Heatmap
   * Dependency - navQualityHeatmapIsEnabled, navHeatmapTimeFrames, mapObject
   */
  useEffect(() => {
    if (navQualityHeatmapIsEnabled && mapObject) {
      try {
        if (dynamicZoom === 0) {
          initiateLevel2Heatmap(
            navHeatmapTimeFrames,
            navQualityHeatmapIsEnabled,
            mapObject
          );
        }
      } catch (err) {
        console.log("Generating All Zoom Level ERR - ", err);
      }
    }
  }, [navQualityHeatmapIsEnabled, navHeatmapTimeFrames, mapObject]);

  //Component on-mount
  /**
   * Function - Initial LGV List Socket
   * Dependency - socket, locationCode
   */
  useEffect(() => {
    let locationCode = JSON.parse(localStorage.getItem("locationCode"));
    let socketInstance = initiateSocketConnection(locationCode);
    setSocketConnection(socketInstance);

    const initiateActiveShipmentWebSocket = () => {
      socketInstance.on(`${locationCode}_LGV_LIST`, (data) => {
        if (
          data.results &&
          data.results !== undefined &&
          data.results !== DB_ERROR
        ) {
          setAllLgvsOfThisPlant(data.results);
        }
      });
    };
    if (socketInstance && locationCode) {
      initiateActiveShipmentWebSocket();
    }

    socketInstance.on("disconnect", (reason) => {
      // console.log("disconnected")
      setConnectionStatus(false);
      if (reason === "io server disconnect") {
        socketInstance.connect();
        setConnectionStatus(true);
      }
    });

    return () => {
      socketInstance.disconnect();
      setConnectionStatus(false);
    };
  }, []);

  /**
   * Function - Enable and Disable Heatmap when app is offline
   * */
  /*************************************************************************** */
  //Return Component
  return (
    <div className="nav-quality-wrapper">
      <div className="nav-quality-container">
        {Object.keys(navHeatmapTimeFrames).length === 0 ? (
          <div className="loader">
            <h4>Please select timeframe to initiate navHeatmap</h4>
          </div>
        ) : (
          <>
            {renderLoader}
            <MapContainer
              id="nav-heatmap-id-reference"
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
              center={[0, 0]}
              maxZoom={4}
              preferCanvas={true}
              renderer={L.canvas()}
              zoomDelta={0.5}
              minZoom={0}
              zoomSnap={0.5}
              zoom={0}
              crs={L.CRS.Simple}
              zoomControl={true}
              attributionControl={false}
              whenReady={(map) => mapWhenReadyHandler(map)}
              scrollWheelZoom={true}
              disableDoubleClickZoom={true}
              dragging={true}
            >
              {/**Zoom Event Handlers */}
              <ZoomEventHandlers />
              {/**Renders Map */}
              {RenderMap}
              {/**Renders Nav Heatmap */}
              {GenerateNavHeatMap}
              {/**Renders Nav Icons */}
              {GenerateNavIcons}
              {/**Changes Grids Width */}
              {ChangeStrokeWidth}
              {/**Zoom Control Center */}
              <ZoomControlCenter />
              {RenderZoomIndicator}
              {/**LGV Checklist */}
              {convertedNavHeatmapData !== null &&
                dynamicZoom === 4 &&
                allLgvsOfThisPlant !== null &&
                groupedLgvs &&
                !navHeatmapLoader && (
                  <LgvCheckList
                    data={groupedLgvs}
                    allLgv={allLgvsOfThisPlant}
                    onChange={checkListLgvHandler}
                  />
                )}
              {/**Alert Message */}
              {renderAlertMessage}
            </MapContainer>
          </>
        )}
      </div>
    </div>
  );
};

export default NavQualityHeatmap;
