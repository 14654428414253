import { useContext, useEffect, useMemo, useState } from "react";
import {
  convertReactComponentToHtmlIcon,
  filterDuplicateProductionLine,
  getLocationGroupOffset,
  mapCoordinate,
} from "../../util/helper/helperFunctions";
import { AppContext } from "../../Context/Context";
import L from "leaflet";
import LineProductionPopup from "../Modal/LineProductionPopup/LineProductionPopup";
import { Marker } from "react-leaflet";
import { DB_ERROR } from "../../util/helper/constants";

const RenderProductionLines = ({ mapObject, linesData }) => {
  /**Hooks ------------------------------------------------*/
  const [convertedProductionLineData, setConvertedProductionLineData] =
    useState();

  /**Context ------------------------------------------------*/
  const { plantConfig } = useContext(AppContext);

  /**Function Handlers ------------------------------------------------*/
  //Convert Production Line Coordinate from Ignition system to LGV One system
  const productionLineCoordinateConverter = (localProductionLineData) => {
    if (
      plantConfig &&
      mapObject &&
      localProductionLineData &&
      localProductionLineData !== DB_ERROR
    ) {
      //Get Plant Config data
      let { productionLines } = plantConfig.indoorMap;

      localProductionLineData.forEach((data, index) => {
        let convertedCoordinates = mapCoordinate(
          data["longitude"],
          data["latitude"],
          "lines",
          productionLines.bounds
        );
        let convertedPoints = L.point(
          convertedCoordinates.x - productionLines.groupOffset.x,
          convertedCoordinates.y - productionLines.groupOffset.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        data.currentPos = {
          lat: convertedLatLng.lat,
          lng: convertedLatLng.lng,
        };
      });
      // setMapLoader(false);
      setConvertedProductionLineData(localProductionLineData);
    }
  };

  /**Component On-Mount ------------------------------------------------*/
  /**Call Coordinate Converter on fresh Lines data */
  useEffect(() => {
    if (linesData && linesData.length > 0) {
      productionLineCoordinateConverter(linesData);
    }
  }, [linesData]);

  /**Component ------------------------------------------------*/
  const RenderProductionLine = useMemo(() => {
    if (convertedProductionLineData) {
      //Remove duplicate and filter the active production lines
      let filteredData = filterDuplicateProductionLine(
        convertedProductionLineData
      );

      //Get Plant Config data
      let { productionLines } = plantConfig.indoorMap;

      return filteredData.map((productionLine, index) => {
        //Get Correction values
        let correctionUnit = productionLines.eachItemOffset[
          productionLine.LINE_NAME
        ]
          ? productionLines.eachItemOffset[productionLine.LINE_NAME]
          : { x: 0, y: 0 };
        //Popup Data
        let productionData = productionLine;
        //Convert Lat and Lng to X and Y
        let coordinateCorrection = getLocationGroupOffset(
          productionData.currentPos,
          correctionUnit
        );
        //Marker Icon
        let reactIcon = convertReactComponentToHtmlIcon(
          <LineProductionPopup lineData={productionData} index={index} />
        );
        return (
          <Marker
            type="productionLine"
            data={productionData}
            key={index}
            riseOnHover
            position={coordinateCorrection}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedProductionLineData]);

  /**RETURN ------------------------------------------------*/
  return <>{RenderProductionLine}</>;
};

export default RenderProductionLines;
