import { Checkbox, Divider } from "antd";
import { useEffect, useState } from "react";
import "./checklist.css";
import { formatLgvListToCheckList } from "../../../util/helper/navHeatmap/navHeatmapHelper";

const CheckList = ({ options, onChange }) => {
  const [checkListOptions, setCheckListOptions] = useState(options);
  const [checkedList, setCheckedList] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);

  /**
   * Function - Format Check list options to checked values
   * Input - Array of Object
   * Output - String Array
   */
  const formatCheckListOptionToCheckedValues = (listOptions) => {
    let checkedValues = [];
    listOptions.forEach((item) => {
      if (item) {
        checkedValues.push(item.value);
      }
    });
    return checkedValues;
  };

  const onChangeHandler = (type, checkedValues, ...args) => {
    switch (type) {
      case "all":
        //Convert the options to value array and return the value
        if (args[0].target.checked) {
          setIsAllSelected(true);
          let enabledItems = checkListOptions.filter((item) => !item.disabled);
          let allCheckedValues =
            formatCheckListOptionToCheckedValues(enabledItems);
          setCheckedList(allCheckedValues);
          onChange(allCheckedValues);
        } else {
          setIsAllSelected(false);
          setCheckedList([]);
          onChange([]);
        }
        break;
      case "single":
        //Setting the value directly from the event since the checkbox returns value array instead of options
        setCheckedList(checkedValues);
        onChange(checkedValues);
        break;
    }
  };

  const setDefaultItemsToCheckbox = () => {
    //Filter all the non disabled items and convert it to checkbox value and set it.
    let enabledItems = checkListOptions.filter((item) => !item.disabled);
    const allCheckedValues = formatCheckListOptionToCheckedValues(enabledItems);
    setCheckedList(allCheckedValues);
    onChange(allCheckedValues); //updates selectedLGVs state
  };

  /**Component on-mount */
  useEffect(() => {
    setDefaultItemsToCheckbox();

    //Set the items of the option on default
    let enabledItems = options && options.filter((item) => !item.disabled);
    let allCheckedValues = formatCheckListOptionToCheckedValues(enabledItems);
    setCheckedList(allCheckedValues);
  }, []);

  useEffect(() => {
    setCheckListOptions(structuredClone(options));
    onChangeHandler("all", null, { target: { checked: true } });
  }, [options]);

  return (
    <div className="check-list">
      {checkListOptions && checkListOptions.length !== 0 && (
        <>
          <Checkbox
            className="select-all-btn"
            onChange={(e) => onChangeHandler("all", null, e)}
            checked={isAllSelected}
          >
            All
          </Checkbox>
          <Checkbox.Group
            options={checkListOptions}
            value={checkedList}
            onChange={(checkedValues) =>
              onChangeHandler("single", checkedValues)
            }
          />
        </>
      )}
    </div>
  );
};

export default CheckList;
