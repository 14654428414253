const LGVDataSection = ({ lgvData }) => {
  const lgvDataDetails = [
    { label: "Vehicle Number", dataKey: "vehicleNumber" },
    { label: "Status", dataKey: "status" },
    { label: "Source", dataKey: "source" },
    { label: "Destination", dataKey: "destination" },
    { label: "Segment", dataKey: "segment" },
    { label: "X Axis", dataKey: "latitude" },
    { label: "Y Axis", dataKey: "longitude" },
    { label: "Duration", dataKey: "duration" },
    { label: "Alarm Name", dataKey: "alarmName" },
  ];

  const renderLGVData = (label, dataKey) => {
    return (
      <div className="lgv-details" key={dataKey}>
        <h5 className="details-data-title">{label}</h5>
        <h5 className="details-data">{lgvData[dataKey] ?? "null"}</h5>
      </div>
    );
  };
  
  return (
    <>
      <div className="popup-section">
        <h4 className="popup-section-title">LGV DATA</h4>
        <div className="details-section">
          {lgvDataDetails.map(({ label, dataKey }) =>
            renderLGVData(label, dataKey)
          )}
        </div>
      </div>
    </>
  );
};

export default LGVDataSection;
