import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../Reusables/Button/Button";
import Close from "../../assets/icons/close.png";
import { AppContext } from "../../Context/Context";
import DatePicker from "../Reusables/DatePicker/DatePicker";
import TimePicker from "../Reusables/TimePicker/TimePicker";
import moment, { min } from "moment";
import AlertMessage from "../Reusables/AlertMessage/AlertMessage";
import { getTimeBasedOnUserTime } from "../../util/helper/helperFunctions";

const TimeMachineRangePopup = () => {
  //Context
  const {
    setTimeMachineV2ModeIsEnabled,
    timeMachineMaxTimeStamp,
    setTimeMachineTimeFrames,
    setTimeMachineModeIsEnabled,
    setOpenTimeMachineRangePopup,
    openTimeMachineRangePopup,
  } = useContext(AppContext);

  //Hooks
  const [timeFrame, setTimeFrame] = useState({
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
  });
  const [timeFrameValidation, setTimeFrameValidation] = useState({
    fromDate: true,
    toDate: true,
    fromTime: true,
    toTime: true,
  });
  const [isTimeFrameValid, setIsTimeFrameValid] = useState(false);
  const [alertMessageType, setAlertMessageType] = useState(null);
  const [timeRangeMinimumDate, setTimeRangeMinimumDate] = useState("");
  const [timeRangeMaximumDate, setTimeRangeMaximumDate] = useState("");
  const [minDateObj, setMinDateObj] = useState(null);
  const [maxDateObj, setMaxDateObj] = useState(null);

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("time-machine-range-wrapper-id")) {
      setOpenTimeMachineRangePopup(false);
    }
  });

  const [timeFrameSelected, setTimeFrameSelected] = useState("");

  const enterTimeMachineMode = (type) => {
    switch (type) {
      case "v1":
        setTimeMachineModeIsEnabled(true);
      case "v2":
        setTimeMachineV2ModeIsEnabled(true);
    }
    localStorage.setItem("isTimeMachineModeEnabled", "true");
  };

  const resetAlertMessage = () => {
    setTimeout(() => {
      // setIsTimeFrameValid(true);
      setAlertMessageType(null);
    }, 30000);
  };

  const clearTimeFrame = () => {
    setMinAndMaxDateRange();
    setTimeFrameSelected("");
    setIsTimeFrameValid(false);
    setAlertMessageType(null);
  };

  const applyTimeFrame = () => {
    setValidateTimeFrame();

    if (validateTimeFrames()) {
      setTimeMachineTimeFrames({ ...timeFrame });
      enterTimeMachineMode("v2");
      setOpenTimeMachineRangePopup(false);
      setIsTimeFrameValid(true);
    } else {
      const { fromDtTime, toDtTime } = getFromAndToTimeObj();
      setTimeFrameValidation((timeFrameValidation) => ({
        ...timeFrameValidation,
        fromTime: false,
      }));
      setIsTimeFrameValid(false);
      if (
        timeFrame.fromDate == timeFrame.toDate &&
        timeFrame.fromTime == timeFrame.toTime
      ) {
        setAlertMessageType("equal-date-time");
      } else if (fromDtTime > toDtTime) {
        setAlertMessageType("date-time-overlap");
      } else if (isFromOrToTimeBeyondAvailableDateTime())
        setAlertMessageType("date-time-out-of-range");
      else {
        setAlertMessageType("warning");
      }
      resetAlertMessage();
    }
  };

  const getFromAndToTimeObj = () => {
    let fromDateTime = `${timeFrame.fromDate} ${timeFrame.fromTime || "00:00"}`;
    let toDateTime = `${timeFrame.toDate} ${timeFrame.toTime || "00:00"}`;
    let fromDtTime = new Date(fromDateTime).getTime();
    let toDtTime = new Date(toDateTime).getTime();
    return {
      fromDtTime,
      toDtTime,
    };
  };

  const isFromOrToTimeBeyondAvailableDateTime = () => {
    let fromDateTime = `${timeFrame.fromDate} ${timeFrame.fromTime || "00:00"}`;
    let toDateTime = `${timeFrame.toDate} ${timeFrame.toTime || "00:00"}`;

    const fromDtTime = moment(fromDateTime);
    const toDtTime = moment(toDateTime);
    // console.log("fr", minDateObj.format("YYYY-MM-DD HH:mm"));
    // console.log("to", maxDateObj.format("YYYY-MM-DD HH:mm"));
    return minDateObj > fromDtTime || maxDateObj < toDtTime;
  };

  const validateTimeFrames = () => {
    const { fromDtTime, toDtTime } = getFromAndToTimeObj();
    if (
      timeFrame.fromDate == undefined ||
      timeFrame.toDate == undefined ||
      timeFrame.fromTime == undefined ||
      timeFrame.toTime == undefined ||
      timeFrame.fromDate == "" ||
      timeFrame.toDate == "" ||
      timeFrame.fromTime == "" ||
      timeFrame.toTime == "" ||
      (timeFrame.fromDate == timeFrame.toDate &&
        timeFrame.fromTime == timeFrame.toTime) ||
      fromDtTime > toDtTime ||
      isFromOrToTimeBeyondAvailableDateTime()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const setValidateTimeFrame = () => {
    if (timeFrame.fromDate == "" || timeFrame.fromDate == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromDate: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromDate: true,
      }));
    }
    if (timeFrame.toDate == "" || timeFrame.toDate == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        toDate: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({ ...validation, toDate: true }));
    }
    if (timeFrame.fromTime == "" || timeFrame.fromTime == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromTime: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromTime: true,
      }));
    }
    if (timeFrame.toTime == "" || timeFrame.toTime == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        toTime: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({ ...validation, toTime: true }));
    }
  };

  const getTodayDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  const getEarlierMin = (date, timeEarlier) => {
    let newDate = moment(maxDateObj).subtract(timeEarlier, "m")._d;
    let pastTime = String(newDate).split(" ")[4];
    let pastHour = pastTime.split(":")[0];
    let pastMinute = pastTime.split(":")[1];
    return `${pastHour}:${pastMinute}`;
  };

  const getEarlierHr = (date, timeEarlier) => {
    let newDate = moment().subtract(timeEarlier, "h")._d;
    let pastTime = String(newDate).split(" ")[4];
    let pastHour = pastTime.split(":")[0];
    let pastMinute = pastTime.split(":")[1];
    return `${pastHour}:${pastMinute}`;
  };

  const getCurrentTime = (date) => {
    let currentHour = String(date.getHours()).padStart(2, "0");
    let currentMinute = String(date.getMinutes()).padStart(2, "0");
    return `${currentHour}:${currentMinute}`;
  };

  const setEarlierTime = (min) => {
    let fromDateTimeObj = moment(maxDateObj).subtract(min, "minute");
    timeFrame.fromDate = fromDateTimeObj.format("YYYY-MM-DD");
    timeFrame.fromTime = fromDateTimeObj.format("HH:mm");
    timeFrame.toDate = maxDateObj.format("YYYY-MM-DD");
    timeFrame.toTime = maxDateObj.format("HH:mm");
    setTimeFrame(timeFrame);
  };
  const set30MinsTimeFrame = () => {
    setEarlierTime(30);
    setTimeFrameSelected("30");
    setIsTimeFrameValid(false);
    // setAlertMessageType("altered");
    resetAlertMessage();
  };
  const set1hrTimeFrame = () => {
    setEarlierTime(60);
    setTimeFrameSelected("1");

    setIsTimeFrameValid(false);
    // setAlertMessageType("altered");
    resetAlertMessage();
  };

  const set3hrTimeFrame = () => {
    setEarlierTime(180);
    setTimeFrameSelected("3");

    setIsTimeFrameValid(false);
    // setAlertMessageType("altered");
    resetAlertMessage();
  };

  const setTodayTimeFrame = () => {
    // Calculate the currentDateTime from maxDateObj
    const currentDateTime = moment(maxDateObj); // Use maxDateObj as the base

    let fromDateTimeObj;

    // If current time is exactly midnight (00:00), subtract one day for fromDate
    if (currentDateTime.format("HH:mm") === "00:00") {
      fromDateTimeObj = moment(maxDateObj).subtract(1, "day").startOf("day"); // Start of previous day
      timeFrame.fromDate = fromDateTimeObj.format("YYYY-MM-DD");
      timeFrame.fromTime = "00:00";
      timeFrame.toDate = currentDateTime.format("YYYY-MM-DD");
      timeFrame.toTime = currentDateTime.format("HH:mm");
    } else {
      // If current time is not exactly midnight, subtract 'min' minutes from maxDateObj
      fromDateTimeObj = moment(maxDateObj).subtract(min, "minutes"); // Adjust for 'min' minutes
      timeFrame.fromDate = fromDateTimeObj.format("YYYY-MM-DD");
      timeFrame.fromTime = "00:00";
      timeFrame.toDate = currentDateTime.format("YYYY-MM-DD");
      timeFrame.toTime = currentDateTime.format("HH:mm");
    }

    // Set the time frame and update the selected time frame
    setTimeFrame(timeFrame);
    setTimeFrameSelected("today");

    // Set validity and alert message
    setIsTimeFrameValid(false);
    // setAlertMessageType("altered");
    resetAlertMessage();
  };

  const setMinAndMaxDateRange = () => {
    let maximumDate = getTimeBasedOnUserTime(timeMachineMaxTimeStamp).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let maxDateObj = moment(maximumDate);
    let minDateObj = moment(maximumDate).subtract(5, "days");
    let lessOneDateObj = moment(maximumDate).subtract(1, "days");

    setTimeRangeMinimumDate(minDateObj.format("YYYY-MM-DD"));
    setTimeRangeMaximumDate(maxDateObj.format("YYYY-MM-DD"));

    setMaxDateObj(maxDateObj);
    setMinDateObj(minDateObj);

    timeFrame.fromDate = lessOneDateObj.format("YYYY-MM-DD");
    timeFrame.fromTime = lessOneDateObj.format("HH:mm");
    timeFrame.toDate = maxDateObj.format("YYYY-MM-DD");
    timeFrame.toTime = maxDateObj.format("HH:mm");
    setTimeFrame(timeFrame);
    // console.log(
    //   "time range",
    //   minDateObj.format("YYYY-MM-DD HH:mm:ss"),
    //   timeFrame
    // );
  };

  // const

  useEffect(() => {
    console.log("calling setMinmaxDate", timeMachineMaxTimeStamp);
    if (timeMachineMaxTimeStamp) setMinAndMaxDateRange();
  }, [timeMachineMaxTimeStamp]);

  useEffect(() => {
    // if (timeFrame.fromDate !== "") {
    //   setTimeRangeMinimumDate(timeFrame.fromDate);
    // }
    // if (timeFrame.toDate !== "") {
    //   setTimeRangeMaximumDate(timeFrame.toDate);
    // }

    if (
      timeFrame.fromDate &&
      timeFrame.toDate &&
      timeFrame.fromTime &&
      timeFrame.toTime
    ) {
      setIsTimeFrameValid(false);
    }
  }, [
    timeFrame.fromDate,
    timeFrame.toDate,
    timeFrame.fromTime,
    timeFrame.toTime,
  ]);

  //   useEffect(() => {
  //     if (timeMachineTimeFrames?.fromDate !== "") {
  //       setTimeFrame(timeMachineTimeFrames);
  //     }
  //     clearTimeFrame();
  //   }, []);

  const renderAlertMessage = useMemo(() => {
    if (alertMessageType !== null) {
      let alertProps = {
        heading: "",
        description: "",
        type: "",
      };
      switch (alertMessageType) {
        case "warning":
          alertProps.heading = "Invalid Time Range";
          alertProps.description = "Time Range must be greater than 15 mins";
          alertProps.type = "warning";
          break;
        case "restricted":
          alertProps.heading = "Time Range Invalid";
          alertProps.description = "Time Range is only available for 6 hours";
          alertProps.type = "warning";
          break;
        case "equal-date-time":
          alertProps.heading = "Time Range Invalid";
          alertProps.description =
            "From date time & to date time should not be equal";
          alertProps.type = "warning";
          break;
        case "date-time-overlap":
          alertProps.heading = "Time Range Invalid";
          alertProps.description = "Selected date time should not be overlap";
          alertProps.type = "warning";
          break;

        case "date-time-out-of-range":
          alertProps.heading = "Time Range Invalid";
          alertProps.description =
            "Selected date time should be in availabe date time range";
          alertProps.type = "warning";
          break;

        default: {
          alertProps = null;
        }
      }
      return (
        alertProps && (
          <AlertMessage
            heading={alertProps.heading}
            description={alertProps.description}
            type={alertProps.type}
            setAlertMessageType={setAlertMessageType}
          />
        )
      );
    }
  }, [alertMessageType]);

  return (
    <>
      {openTimeMachineRangePopup && (
        <div id="time-machine-range-wrapper-id" className="time-range-wrapper">
          <div className="time-range-container">
            <div className="time-range-top-section">
              <p>Time Range</p>
              <img
                onClick={() => setOpenTimeMachineRangePopup(false)}
                className="time-range-popup-close-btn"
                src={Close}
              />
            </div>
            <div className="time-range-middle-section">
              <div className="picker-section">
                <div className="time-range-left-panel">
                  From
                  <div className="time-range-pickers">
                    <DatePicker
                      validation={timeFrameValidation.fromDate}
                      date={timeFrame.fromDate}
                      getDate={setTimeFrame}
                      name="fromDate"
                      min={timeRangeMinimumDate}
                      max={timeRangeMaximumDate}
                    />
                    <TimePicker
                      validation={timeFrameValidation.fromTime}
                      time={timeFrame.fromTime}
                      getTime={setTimeFrame}
                      name="fromTime"
                    />
                  </div>
                </div>
                <div className="time-range-right-panel">
                  To
                  <div className="time-range-pickers">
                    <DatePicker
                      validation={timeFrameValidation.toDate}
                      date={timeFrame.toDate}
                      getDate={setTimeFrame}
                      name="toDate"
                      min={timeRangeMinimumDate}
                      max={timeRangeMaximumDate}
                    />
                    <TimePicker
                      validation={timeFrameValidation.toTime}
                      time={timeFrame.toTime}
                      getTime={setTimeFrame}
                      name="toTime"
                    />
                  </div>
                </div>
              </div>
              <div className="time-range-info">
                <p>Please enter the time as per your system time format.</p>

                {minDateObj && maxDateObj && (
                  <p className="available-date-time">
                    <span>Available Data - </span> <label>From:</label>{" "}
                    {minDateObj.format("MM/DD/YYYY HH:mm")}{" "}
                    <label className="toTime">To:</label>{" "}
                    {maxDateObj.format("MM/DD/YYYY HH:mm")}
                  </p>
                )}
              </div>

              <div className="time-frame-section">
                {/* <Button
                  onClick={() => set15MinsTimeFrame()}
                  placeholder="Last 15 mins"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "15" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button> */}
                <Button
                  onClick={() => set30MinsTimeFrame()}
                  placeholder="Last 30 mins"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "30" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => set1hrTimeFrame()}
                  placeholder="Last 1 hr"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "1" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => set3hrTimeFrame()}
                  placeholder="Last 3 hr"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "3" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => setTodayTimeFrame()}
                  placeholder="Today"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "today"
                      ? "time-frame-btn-selected"
                      : ""
                  }`}
                ></Button>
              </div>
              {/* {alertMessageType === "altered" && (
            <div className="time-range-info">
              <p className="minutes-altered-info">
                Time Range is altered since last 15 mins data is not available
              </p>
            </div>
          )} */}
            </div>
            <div className="time-range-bottom-section">
              <Button
                onClick={() => clearTimeFrame()}
                placeholder="Reset"
                btnClass="time-range-discard-btn time-range-btn"
              ></Button>
              <Button
                onClick={() => applyTimeFrame()}
                placeholder="Apply"
                btnClass={`time-range-confirm-btn time-range-btn ${
                  isTimeFrameValid ? "time-range-invalid" : ""
                }`}
                disabled={isTimeFrameValid}
              ></Button>
            </div>
            {renderAlertMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default TimeMachineRangePopup;
