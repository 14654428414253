import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../Context/Context";
import "../../../assets/css/Warehouse/warehouse.css";
import Close from "../../../assets/icons/closeButton.png";
import Button from "../../Reusables/Button/Button";
import {
  getScreenSize,
  onDragging,
  onDraggingEnd,
  getLocalStorageItem,
} from "../../../util/helper/helperFunctions";
import DraggableComponent from "../../DragableComponent/DraggableComponent";
import { DB_ERROR } from "../../../util/helper/constants";

const WarehouseModal = ({ warehouseData, shiftData }) => {
  const {
    warehouseModelIsOpen,
    setWarehouseModelIsOpen,
    reRenderKey,
    warehouseTempOrder,
    setWarehouseTempOrder,
  } = useContext(AppContext);

  const closeWarehouseModal = () => {
    setWarehouseModelIsOpen(!warehouseModelIsOpen);
    document.body.classList.remove("prevent-scroll");
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("warehouse-modal-wrapper-id")) {
      closeWarehouseModal();
    }
  });

  useEffect(() => {
    setWarehouseTempOrder(getLocalStorageItem("warehouse-layout-order"));
  }, []);

  const RenderDraggableWarehouseComponents = useMemo(() => {
    // console.log("re render warehouse");
    let featureList = [
      {
        key: "1",
        component: <WarehouseStatus warehouseData={warehouseData} />,
      },
      {
        key: "2",
        component: <NightDayShiftStatus shiftData={shiftData} />,
      },
    ];

    if (warehouseTempOrder) {
      featureList.sort((a, b) => {
        return (
          warehouseTempOrder.indexOf(a.key) - warehouseTempOrder.indexOf(b.key)
        );
      });
    }

    if (featureList) {
      return (
        <DraggableComponent
          id="warehouse-table-id"
          draggableItems={featureList}
        />
      );
    }
  }, [warehouseData, shiftData, reRenderKey]);

  return (
    <div id="warehouse-modal-wrapper-id" className="warehouse-modal-wrapper">
      <div className="warehouse-modal">
        <div className="warehouse-modal-top-section">
          <h3>Warehouse Capacity and Utilization</h3>
          {getScreenSize() < 2500 ? (
            <img src={Close} onClick={() => closeWarehouseModal()} />
          ) : null}
        </div>

        {RenderDraggableWarehouseComponents}

        {/*Table*/}
        {/* <WarehouseStatus
          warehouseDetails={warehouseDetails}
          loader={warehouseLoader}
        /> */}
        {/*Shift Details*/}
        {/* <NightDayShiftStatus shiftData={shiftData} /> */}
      </div>
    </div>
  );
};

const ShiftStatus = (shiftData) => {
  if (shiftData) {
    {
      return Object.entries(shiftData).map(([key, value]) => {
        return (
          <div key={key} className="shift-box">
            <div className="shift-box-top-section">
              <p>{value === "" || value === null ? "-" : value}</p>
            </div>
            <div className="shift-box-bottom-section">
              <p>{key}</p>
            </div>
          </div>
        );
      });
    }
  }
};

const NightDayShiftStatus = ({ shiftData }) => {
  const [shiftToggle, setShiftToggle] = useState("LASTHOUR");
  const [midShiftToggle, setMidShiftToggle] = useState("MIDNIGHT");

  const [shiftPerformanceData, setShiftPerformanceData] = useState([]);
  const [shiftSectionData, setShiftSectionData] = useState({});
  const [midNightShiftSectionData, setMidNightShiftSectionData] = useState({});
  const [shiftLoader, setShiftLoader] = useState(true);
  useEffect(() => {
    if (shiftData && shiftData !== DB_ERROR) {
      setShiftPerformanceData(shiftData);
      setShiftSectionData(shiftDataFetcher(shiftData, shiftToggle));
      setMidNightShiftSectionData(shiftDataFetcher(shiftData, midShiftToggle));
      setShiftLoader(false);
    }
  }, [shiftData]);

  const shiftDataFetcher = (shiftData, shiftCall) => {
    if (shiftData && shiftCall) {
      let shift = shiftData.find((data) => {
        return data["CATEGORY"] == shiftCall;
      });
      if (shift) {
        const { CATEGORY, ...rest } = shift;
        return rest;
      }
    }
  };
  const lastHourDataToggler = (shift) => {
    setShiftToggle(shift);
    if (shift == "LASTHOUR") {
      setShiftSectionData(shiftDataFetcher(shiftPerformanceData, "LASTHOUR"));
    } else if (shift == "SHIFT") {
      setShiftSectionData(shiftDataFetcher(shiftPerformanceData, "SHIFT"));
    }
  };

  const midNightDataToggler = (shift) => {
    setMidShiftToggle(shift);
    if (shift == "MIDNIGHT") {
      setMidNightShiftSectionData(
        shiftDataFetcher(shiftPerformanceData, "MIDNIGHT")
      );
    } else if (shift == "24HOURS") {
      setMidNightShiftSectionData(
        shiftDataFetcher(shiftPerformanceData, "24HOURS")
      );
    }
  };
  return (
    <div
      onMouseDown={(event) => onDragging(event)}
      onMouseUp={(event) => onDraggingEnd(event)}
      className="warehouse-modal-bottom-section"
    >
      {/*Shift Details*/}
      <div className="shift-container">
        <div className="shift-top-section">
          <div className="joint-btn">
            <Button
              placeholder="Last Hour"
              btnClass={
                shiftToggle == "LASTHOUR"
                  ? "shift-on-button"
                  : "shift-off-button"
              }
              onClick={() => {
                lastHourDataToggler("LASTHOUR");
              }}
            />
            <Button
              placeholder="Shift"
              btnClass={
                shiftToggle == "SHIFT" ? "shift-on-button" : "shift-off-button"
              }
              onClick={() => {
                lastHourDataToggler("SHIFT");
              }}
            />
          </div>
        </div>
        <div className="shift-bottom-section">
          {shiftLoader ? (
            <div className="shift-loader">
              <h4>Loading...</h4>
              <div className="spinner"></div>
            </div>
          ) : (
            ShiftStatus(shiftSectionData)
          )}
        </div>
      </div>
      <div className="shift-container mt-20">
        <div className="shift-top-section">
          <div className="joint-btn">
            <Button
              placeholder="Midnight"
              btnClass={
                midShiftToggle == "MIDNIGHT"
                  ? "shift-on-button"
                  : "shift-off-button"
              }
              onClick={() => {
                midNightDataToggler("MIDNIGHT");
              }}
            />
            <Button
              placeholder="24 hrs"
              btnClass={
                midShiftToggle == "24HOURS"
                  ? "shift-on-button"
                  : "shift-off-button"
              }
              onClick={() => {
                midNightDataToggler("24HOURS");
              }}
            />
          </div>
        </div>
        <div className="shift-bottom-section">
          {shiftLoader ? (
            <div className="shift-loader">
              <h4>Loading...</h4>
              <div className="spinner"></div>
            </div>
          ) : (
            ShiftStatus(midNightShiftSectionData)
          )}
        </div>
      </div>
    </div>
  );
};

const WarehouseStatus = ({ warehouseData }) => {
  const [warehouseDetails, setWarehouseDetails] = useState([]);
  const [warehouseLoader, setWarehouseLoader] = useState(true);
  useEffect(() => {
    if (warehouseData && warehouseData !== DB_ERROR) {
      setWarehouseDetails(warehouseData);
      setWarehouseLoader(false);
    }
  }, [warehouseData]);
  if (warehouseLoader) {
    return (
      <div className="warehouse-loader">
        <h4>Loading...</h4>
        <div className="spinner"></div>
      </div>
    );
  } else {
    return (
      <div
        onMouseDown={(event) => onDragging(event)}
        onMouseUp={(event) => onDraggingEnd(event)}
        className="warehouse-details-panel-wrapper"
      >
        <table className="warehouse-details-panel-table">
          <thead>
            <tr className="panel-table-row table-head">
              <th className="panel-table-row-cat">Category</th>
              <th className="panel-table-row-typ">Type</th>
              <th className="panel-table-row-val">Value</th>
            </tr>
          </thead>
          <tbody>
            {warehouseDetails &&
              warehouseDetails.map((data, index) => {
                return (
                  <tr key={index} className="panel-table-row">
                    <td>{data["Category"]}</td>
                    <td>{data["Type"]}</td>
                    <td>{data["Value"]}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
};

export default WarehouseModal;
