import { useContext, useEffect, useMemo, useRef, useState } from "react";
import "../../../assets/css/Heatmap/heatmapModal.css";
import { ImageOverlay, MapContainer, Rectangle } from "react-leaflet";
import L from "leaflet";
import {
  apiHeadersFormatter,
  expireAuthToken,
  getHeatmapWeightage,
  getLocalStorageItem,
  getPlantMapImageUrl,
  mapCoordinate,
  setLocalStorageItem,
  timeFrameFormatter,
} from "../../../util/helper/helperFunctions";
import { AppContext } from "../../../Context/Context";
import { heatmapDetails } from "../../../util/network/apiUrl";
import { getApi } from "../../../util/network/getApi";
import { DB_ERROR } from "../../../util/helper/constants";

const HeatmapModal = () => {
  const [mapObject, setMapObject] = useState();
  const heatmapModalRef = useRef(null);
  const heatmapModalContainerRef = useRef(null);
  const [rawHeatmapData, setRawHeatmapData] = useState([]);
  //   const [lgvObjects, setLgvObjects] = useState();

  const { isHeatmapModeEnabled, setIsHeatmapModeEnabled } =
    useContext(AppContext);
  const { isHeatmapDataAvailable, setIsHeatmapDataAvailable } =
    useContext(AppContext);
  const { convertedHeatmapData, setConvertedHeatmapData } =
    useContext(AppContext);
  const { heatmapTimeFrames, setHeatmapTimeFrames } = useContext(AppContext);
  const { heatmapLoader, setHeatmapLoader } = useContext(AppContext);
  const { heatmapRef, setHeatmapRef } = useContext(AppContext);
  const { loaderText, setLoaderText } = useContext(AppContext);

  //Plant Config
  const { plantConfig, setPlantConfig } = useContext(AppContext);

  //Plant Image Config
  const { appConfig, setAppConfig } = useContext(AppContext);

  useEffect(() => {
    if (heatmapModalContainerRef !== null) {
      setHeatmapRef(heatmapModalContainerRef);
    }
  }, [heatmapModalContainerRef]);

  const mapWhenReadyHandler = (map) => {
    // map.target.on("mousemove", function (e) {
    //   console.log("lat", e.latlng);
    // });
    setMapObject({ map });
  };

  let rectBottomLeftXNew = 25; //L
  let rectBottomLeftYNew = 640.453125; // B
  let rectTopRightXNew = 1515; //R
  let rectTopRightYNew = 25.453125; //T

  //Rendering Map on the Map container with Map Object and coordinates
  const RenderMap = useMemo(() => {
    let locationCode = getLocalStorageItem("locationCode");
    if (appConfig && mapObject && plantConfig) {
      let img = getPlantMapImageUrl(appConfig, locationCode, "WAREHOUSE_MAP");
      //Get Plant Config
      const { mapBg } = plantConfig.heatmap;
      if (img && mapBg) {
        //Get Image, Image Bounds (limit), X1,Y1 , X2,Y2 coordinates of image and Image bg color
        const { bounds, coordinates } = mapBg;
        //(X1,Y1) and (X2,Y2) data of map coordinates
        const { startX, startY, endX, endY } = coordinates;

        //Downscale start coordinate
        let startConvertedCoordinates = mapCoordinate(
          startX,
          startY,
          "map",
          bounds
        );
        let startConvertedPoints = L.point(
          startConvertedCoordinates.x,
          startConvertedCoordinates.y
        );
        let startConvertedLatLng =
          mapObject.map.target.unproject(startConvertedPoints);

        //Downscale end coordinate
        let endConvertedCoordinates = mapCoordinate(endX, endY, "map", bounds);
        let endConvertedPoints = L.point(
          endConvertedCoordinates.x,
          endConvertedCoordinates.y
        );
        let endConvertedLatLng =
          mapObject.map.target.unproject(endConvertedPoints);

        let overlayBounds = [
          [startConvertedLatLng.lat, startConvertedLatLng.lng],
          [endConvertedLatLng.lat, endConvertedLatLng.lng],
        ];
        return <ImageOverlay url={img} bounds={overlayBounds} />;
      }
    }
  }, [mapObject, appConfig, plantConfig]);

  //Reducing the border width of grid boxes after rendering
  const changeStrokeWidth = () => {
    let boxElements = document.querySelectorAll("path.leaflet-interactive");
    boxElements.forEach((element) => {
      element.style.strokeWidth = "0.5";
    });
  };

  //Filtering Alarm and Traffic LGV and converting the coordinates to our LGV One system
  const heatmapCoordinateConverter = (data, bounds, groupOffset) => {
    let filteredData = [];
    if (mapObject) {
      let correctionUnits = groupOffset;
      data.forEach((marker) => {
        if (marker.status === "Alarm" || marker.status === "Traffic") {
          let convertedCoordinates = mapCoordinate(
            marker["longitude"],
            marker["latitude"],
            "heatmap",
            bounds
          );
          let convertedPoints = L.point(
            convertedCoordinates.x,
            convertedCoordinates.y
          );
          let convertedLatLng = mapObject.map.target.unproject(convertedPoints);
          marker.currentPos = {
            lat: convertedLatLng.lat + correctionUnits.x,
            lng: convertedLatLng.lng + correctionUnits.y,
          };
          filteredData.push(marker);
        }
      });
      return filteredData;
    }
  };

  //API call to get the Traffic and Alarm LGV with timeframe
  const getHeatmapDetails = async () => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    let locationCode = getLocalStorageItem("locationCode");
    if (auth.accessToken && locationCode) {
      const { fromTimePlant, toTimePlant } =
        timeFrameFormatter(heatmapTimeFrames);
      let heatmapPageResp = await getApi(
        `${heatmapDetails}/${fromTimePlant}/${toTimePlant}?location=${locationCode}`,
        apiHeadersFormatter(auth.accessToken)
      );
      if (heatmapPageResp.status === 200 && heatmapPageResp.data !== DB_ERROR) {
        if (heatmapPageResp.data && heatmapPageResp.data.length !== 0) {
          setLocalStorageItem(
            "heatmapTotalPageCount",
            heatmapPageResp.data.totalPages
          );
          setRawHeatmapData(heatmapPageResp.data);
          setHeatmapLoader(false);
          return heatmapPageResp.data;
        } else {
          setIsHeatmapDataAvailable(false);
          setLoaderText("No Data");
        }
      } else {
        if (heatmapPageResp.response.status === 401) {
          expireAuthToken();
        } else if (heatmapPageResp.response.status === 500) {
          getHeatmapDetails();
        }
        return [];
      }
    }
  };

  //Converting empty grids to LGV Objects to find the weightage of each boxes
  const convertGridObjectToLgvObject = (gridData, heatmapData) => {
    gridData.forEach((gridBounds, index) => {
      gridBounds.index = index;
      gridBounds.count = 0;
      gridBounds.lgvList = [];
      let rectBounds = L.rectangle(gridBounds.bounds);
      heatmapData &&
        heatmapData.forEach((lgv) => {
          if (lgv.currentPos) {
            let lgvPosition = L.latLng(lgv.currentPos);
            if (rectBounds.getBounds().contains(lgvPosition)) {
              gridBounds.lgvList.push(lgv);
              gridBounds.count += 1;
            }
          }
        });
    });
    return gridData;
  };

  //Create Empty Grid Box with coordinates
  const createGridBoxes = (horizontalBoxCount, verticalBoxCount, box) => {
    const grid = [];
    if (mapObject) {
      // Calculate the coordinates for the grid of rectangles
      for (let i = 0; i < verticalBoxCount; i++) {
        for (let j = 0; j < horizontalBoxCount; j++) {
          // Calculate the center coordinates relative to the marker [0, 0]
          const lat1 = (i - verticalBoxCount / 2) * box.x - 280;
          const lng1 = (j - horizontalBoxCount / 2) * box.y + 700;
          const lat2 = lat1 + box.x;
          const lng2 = lng1 + box.y;
          const center = [(lat1 + lat2) / 2, (lng1 + lng2) / 2];
          grid.push({
            bounds: [
              [lat1, lng1],
              [lat2, lng2],
            ],
            center,
          });
        }
      }
    }
    return grid;
  };

  useEffect(() => {
    //Initiating Heatmap on start of the Component with conditions
    const initiateHeatmapOnStart = async () => {
      if (plantConfig) {
        let heatmapRespData;
        const { heatmap } = plantConfig;
        if (heatmap !== undefined) {
          const {
            groupOffset,
            bounds,
            horizontalBoxCount,
            verticalBoxCount,
            box,
          } = heatmap;
          if (
            isHeatmapModeEnabled &&
            rawHeatmapData.length === 0 &&
            mapObject
          ) {
            if (Object.keys(heatmapTimeFrames).length !== 0) {
              setHeatmapLoader(true);
              heatmapRespData = await getHeatmapDetails();
              if (heatmapRespData && heatmapRespData.length !== 0) {
                let filteredAndConvertedData = heatmapCoordinateConverter(
                  heatmapRespData,
                  bounds,
                  groupOffset
                );
                if (filteredAndConvertedData.length !== 0) {
                  let gridData = createGridBoxes(
                    horizontalBoxCount,
                    verticalBoxCount,
                    box
                  );
                  let gridLgvData = convertGridObjectToLgvObject(
                    gridData,
                    filteredAndConvertedData
                  );
                  if (gridLgvData) {
                    setConvertedHeatmapData(gridLgvData);
                  }
                }
              }
            }
          }
        }
      }
    };
    initiateHeatmapOnStart();
  }, [isHeatmapModeEnabled, heatmapTimeFrames, rawHeatmapData, mapObject]);

  //Rendering the Heatmap as Rectangle with the Grid boxes with weitage as color
  const RenderHeatmap = useMemo(() => {
    return (
      convertedHeatmapData &&
      convertedHeatmapData.map((rectangle, index) => {
        let bounds = L.rectangle(rectangle.bounds).getBounds();
        return (
          <Rectangle
            pathOptions={getHeatmapWeightage(rectangle.lgvList.length)}
            key={index}
            bounds={bounds}
            color="black"
            strokeColor="black"
          ></Rectangle>
        );
      })
    );
  }, [convertedHeatmapData, isHeatmapModeEnabled]);

  //Centering the World space to a particular coordinates to center the Map
  const centerMapView = () => {
    mapObject.map.target.setView([-250, 700]);
  };

  //Centering the Map whenever there is a change in the screen size or map object.
  useEffect(() => {
    if (mapObject) {
      centerMapView();
      // L.marker([0, 0]).addTo(mapObject.map.target);
      mapObject.map.target.doubleClickZoom.disable();
    }
  }, [mapObject]);

  return (
    <div
      ref={heatmapModalContainerRef}
      id="heatmap-modal-map-container-id"
      className="modal-map-container"
    >
      <MapContainer
        style={{ height: "100%", width: "100%", borderRadius: "20px" }}
        center={[0, 0]}
        zoom={0}
        crs={L.CRS.Simple}
        zoomControl={false}
        attributionControl={false}
        whenReady={(map) => mapWhenReadyHandler(map)}
        scrollWheelZoom={false}
        disableDoubleClickZoom={false}
        dragging={false}
        ref={heatmapModalRef}
      >
        {RenderMap}
        {convertedHeatmapData ? RenderHeatmap : null}
        {changeStrokeWidth()}
      </MapContainer>
    </div>
  );
};

export default HeatmapModal;
