import { DB_ERROR, level2Config } from "../constants";
import {
  getLocalStorageItem,
  isBoundsValid,
  mapCoordinate,
} from "../helperFunctions";
import L from "leaflet";

export const getBoundsOfGrids = (gridsInView) => {
  // console.log("grids in view", gridsInView);
  let totalGridsAvailable = Object.keys(gridsInView).length;
  let firstChildOfObject = Object.values(gridsInView)[0];
  let lastChildOfObject = Object.values(gridsInView)[totalGridsAvailable - 1];

  let startCellName = firstChildOfObject.options.id;
  let endCellName = lastChildOfObject.options.id;

  let startBoundsCellName = getStartBounds(startCellName);
  let endBoundsCellName = getEndBounds(endCellName);

  return formBoundsWithCellName(
    `C${startCellName}`,
    `C${endCellName}`,
    startBoundsCellName,
    endBoundsCellName
  );
};

export const getEndBounds = (startIndex) => {
  let startFirstHalf = startIndex.substring(0, 2);
  let startEndHalf = startIndex.substring(2, 4);

  let cellName = "C" + String(startFirstHalf);
  let yIndex = null;
  if (Number(startEndHalf) + 1 !== 0) {
    yIndex = Number(startEndHalf) + 1;
  } else {
    yIndex = Number(startEndHalf);
  }

  if (yIndex > 0 && yIndex <= 9) {
    cellName += String(`0${yIndex}`);
  } else {
    cellName += String(yIndex);
  }

  return cellName;
};

export const getNavIconColor = (value) => {
  let navValue = Number(value);
  if (navValue < 60) {
    return "nav-red";
  } else if (navValue >= 60 && navValue < 85) {
    return "nav-yellow";
  } else if (navValue >= 85) {
    return "nav-green";
  }
};

export const getNavQualityWeightage = (value) => {
  if (value === null || value === 0) {
    return { fillOpacity: "0%", fillColor: "#ffffff10" };
  } else if (value > 0 && value <= 10) {
    return { fillOpacity: "100%", fillColor: "#ff000010" };
  } else if (value > 10 && value <= 20) {
    return { fillOpacity: "100%", fillColor: "#ff000020" };
  } else if (value > 20 && value <= 30) {
    return { fillOpacity: "100%", fillColor: "#ff000030" };
  } else if (value > 30 && value <= 40) {
    return { fillOpacity: "100%", fillColor: "#ff000040" };
  } else if (value > 40 && value <= 50) {
    return { fillOpacity: "100%", fillColor: "#ff000050" };
  } else if (value > 50 && value <= 60) {
    return { fillOpacity: "100%", fillColor: "#ff000060" };
  } else if (value > 60 && value <= 70) {
    return { fillOpacity: "100%", fillColor: "#FFFF0030" };
  } else if (value > 70 && value <= 80) {
    return { fillOpacity: "100%", fillColor: "#FFFF0090" };
  } else if (value > 80 && value <= 90) {
    return { fillOpacity: "100%", fillColor: "#00800030" };
  } else if (value > 90) {
    return { fillOpacity: "100%", fillColor: "#00800090" };
  }
};

export const getStartBounds = (startIndex) => {
  let startFirstHalf = startIndex.substring(0, 2);
  let startEndHalf = startIndex.substring(2, 4);

  let cellName = "C";
  let xIndex = null;

  if (Number(startFirstHalf) - 1 !== 0) {
    xIndex = Number(startFirstHalf) - 1;
  } else {
    xIndex = Number(startFirstHalf);
  }

  if (xIndex > 0 && xIndex <= 9) {
    cellName += String(`0${xIndex}`);
  } else {
    cellName += String(xIndex);
  }
  cellName += String(startEndHalf);

  return cellName;
};

export const validateColumnAndResult = (response) => {
  if (response) {
    const { manifest, result } = response;

    //Get the total number of items of data
    const { schema } = manifest;
    let columnCount = schema.column_count;

    const { columns } = schema;
    let keyList = [];
    columns.forEach((column, index) => {
      if (column.name) {
        keyList[index] = column.name.toLowerCase();
      }
    });

    //Get sample data
    const { data_array } = result;
    let firstObject = data_array[0];

    let dataObject = {};

    if (columnCount === firstObject.length) {
      data_array.forEach((data, dataIndex) => {
        data.forEach((value, index) => {
          if (!dataObject[dataIndex]) {
            dataObject[dataIndex] = {};
          }
          dataObject[dataIndex][keyList[index]] = value;
        });
      });

      return { result: dataObject, message: "success" };
    } else {
      return { result: null, message: "column count and data count mismatch" };
    }
  }
};

export const validateHeatAndTimeMachineResponse = (data) => {
  if (data?.status && data?.result?.data_array) {
    const { state } = data.status;
    switch (state) {
      case "SUCCEEDED":
        return true;
      case "FAILED":
        return false;
      default:
        return false;
    }
  }
};

const findCellFromList = (config, itemToFind) => {
  let itemFound = config.find((item) => item.cellName == itemToFind);
  if (itemFound) {
    return itemFound;
  }
};

export const formBoundsWithCellName = (
  startCellName,
  endCellName,
  startCell,
  endCell
) => {
  // console.log("cells initial", startCellName, endCellName);

  let startCellBounds = null;
  let endCellBounds = null;

  let startCellFromList = findCellFromList(level2Config, startCell);
  let endCellFromList = findCellFromList(level2Config, endCell);

  // console.log("cells bounds", startCellFromList, endCellFromList);

  if (startCellFromList !== undefined && startCellFromList !== null) {
    startCellBounds = startCellFromList;
  } else {
    // console.log(startCell, "not found", "trying with ", startCellName);
    let prevStepCell = findCellFromList(level2Config, startCellName);
    startCellBounds = prevStepCell;
  }
  if (endCellFromList !== undefined && endCellFromList !== null) {
    endCellBounds = endCellFromList;
  } else {
    // console.log(endCell, "not found", "trying with ", endCellName);
    let prevStepCell = findCellFromList(level2Config, endCellName);
    endCellBounds = prevStepCell;
  }

  let bounds = null;
  if (
    startCellBounds.startX !== undefined &&
    startCellBounds.startY !== undefined &&
    endCellBounds.startX !== undefined &&
    endCellBounds.startY !== undefined
  ) {
    bounds = {
      startX: startCellBounds.startX,
      startY: startCellBounds.startY,
      endX: endCellBounds.startX,
      endY: endCellBounds.startY,
    };
  }

  return bounds;
};

export const timestampFormatter = (timestamp) => {
  let fromTime = `${timestamp.fromDate} ${timestamp.fromTime}`;
  let toTime = `${timestamp.toDate} ${timestamp.toTime}`;
  if (fromTime.length !== 0 && toTime.length !== 0) {
    return { fromTime, toTime };
  } else {
    return null;
  }
};

export const convertTimeFormatStyle = (timestamp) => {
  if (timestamp) {
    let fromDateArray = timestamp.fromTime.split(" ");
    let toDateArray = timestamp.toTime.split(" ");

    let fromDate = fromDateArray[0].split("-").reverse().join("-");
    let fromTime = fromDateArray[1];

    let toDate = toDateArray[0].split("-").reverse().join("-");
    let toTime = toDateArray[1];

    let formattedFromDate = fromDate.concat(` ${fromTime}`);
    let formattedToDate = toDate.concat(` ${toTime}`);

    return {
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    };
  }
};

export const getLevelBasedQuery = (level, plant, timestamp) => {
  let { fromTime, toTime } = timestamp;
  switch (level) {
    case 2:
      return {
        warehouse_id: "60f5c1811e17df15",
        statement:
          "select boxName, sum(lgv_navquality_avg*lgv_navquality_count)/ sum(lgv_navquality_count) navQ, sum(lgv_navquality_count) noOfRecords from devcatalog.lgvdev.lgv_navquality_aggregate_static_test_001  where WHSE = :whse and timestamp >= :startdate AND timestamp <= :enddate group by boxName",
        parameters: [
          { name: "whse", value: { plant }, type: "string" },
          { name: "startdate", value: { fromTime }, type: "TIMESTAMP" },
          { name: "enddate", value: { toTime }, type: "TIMESTAMP" },
        ],
      };
    case 3:
      return {
        warehouse_id: "60f5c1811e17df15",
        statement:
          "select distinct LGV_ID lgvId, longitude, latitude, navquality navQ from devcatalog.lgvdev.lgv_time_machine_static_test_001  where WHSE = :whse and timestamp >= :startdate AND timestamp <= :enddate and longitude >= :startX and longitude < :endX and  latitude >= :startY and latitude < :endY",
        parameters: [
          { name: "whse", value: { plant }, type: "string" },
          { name: "startdate", value: { fromTime }, type: "TIMESTAMP" },
          { name: "enddate", value: { toTime }, type: "TIMESTAMP" },
          { name: "startX", value: 168564, type: "DOUBLE" },
          { name: "endX", value: 224184, type: "DOUBLE" },
          { name: "startY", value: 115960, type: "DOUBLE" },
          { name: "endY", value: 125160, type: "DOUBLE" },
        ],
      };
    default:
      return null;
  }
};

export const enhancedCoordinateConverter = (
  bigBounds,
  smallBounds,
  coordinate,
  mode
) => {
  if ((bigBounds, smallBounds, coordinate)) {
    let bigWarehouseBounds = {
      bigStartX: bigBounds.startX,
      bigStartY: bigBounds.startY,
      bigEndX: bigBounds.endX,
      bigEndY: bigBounds.endY,
    };
    let smallWarehouseBounds = {
      smallStartX: smallBounds.startX,
      smallStartY: smallBounds.startY,
      smallEndX: smallBounds.endX,
      smallEndY: smallBounds.endY,
    };

    const { bigStartX, bigStartY, bigEndX, bigEndY } = bigWarehouseBounds;
    const { smallStartX, smallStartY, smallEndX, smallEndY } =
      smallWarehouseBounds;

    let scaledX;
    let scaledY;

    const { x, y } = coordinate;

    switch (mode) {
      case "downscale":
        scaledX =
          smallStartX +
          ((x - bigStartX) * (smallEndX - smallStartX)) / (bigEndX - bigStartX);
        scaledY =
          smallStartY +
          ((y - bigStartY) * (smallEndY - smallStartY)) / (bigEndY - bigStartY);
        break;
      case "upscale":
        scaledX =
          bigStartX +
          ((x - smallStartX) * (bigEndX - bigStartX)) /
            (smallEndX - smallStartX);
        scaledY =
          bigStartY +
          ((y - smallStartY) * (bigEndY - bigStartY)) /
            (smallEndY - smallStartY);
        break;
      default:
        scaledX = null;
        scaledY = null;
        break;
    }

    return [scaledY, scaledX];
  }
};

/**
 * Function - To convert coordinates of nav icons
 * Input - longitude, latitude
 * output - downscaled coordinates
 */
export const navIconCoordinateConverter = (
  bounds,
  longitude,
  latitude,
  mapObject
) => {
  if (mapObject && bounds && longitude && latitude) {
    let convertedCoordinates = mapCoordinate(
      longitude,
      latitude,
      "lgv",
      bounds
    );
    let convertedPoints = L.point(
      convertedCoordinates.x,
      convertedCoordinates.y
    );
    let convertedLatLng =
      mapObject.map.target.layerPointToLatLng(convertedPoints);

    return [convertedLatLng.lat, convertedLatLng.lng];
  }
};

export const groupLgvsAndConvertCoordinates = (config, lgvList, mapObject) => {
  let lgvObjects = {};
  if (mapObject && lgvList && config) {
    const { bigBounds, smallBounds, lgvCorrection } = config;
    lgvList.map((lgv) => {
      if (!lgvObjects[lgv.lgvId]) {
        lgvObjects[lgv.lgvId] = [];
      }

      let coordinate = {
        x: lgv.latitude,
        y: lgv.longitude,
      };
      let downScaleCoordinate = enhancedCoordinateConverter(
        bigBounds,
        smallBounds,
        coordinate,
        "downscale"
      );

      lgv.downscaleCoordinates = [
        downScaleCoordinate[1],
        downScaleCoordinate[0],
      ];

      lgv.downscaleCoordinates[0] += lgvCorrection.x;
      lgv.downscaleCoordinates[1] += lgvCorrection.y;

      lgvObjects[lgv.lgvId].push(lgv);
    });
    return lgvObjects;
  }
};

export const getFeatureApiBody = (feature, config) => {
  const { plantCode, fromTime, toTime } = config;
  let body = {};
  switch (feature) {
    case "navLevel2":
      body = {
        whse: plantCode,
        fromDate: fromTime,
        toDate: toTime,
      };
      return body;
    case "navLevel3":
      const { startX, endX, startY, endY } = config;
      body = {
        whse: plantCode,
        fromDate: fromTime,
        toDate: toTime,
        startX: startX,
        endX: endX,
        startY: startY,
        endY: endY,
      };
      return body;
  }
};

export const toggleMapDrag = (state) => {
  if (!state) {
    document
      .getElementsByClassName("nav-quality-container")[0]
      .classList.remove("disable-map-drag");
  } else {
    document
      .getElementsByClassName("nav-quality-container")[0]
      .classList.add("disable-map-drag");
  }
};

export const formatLgvListToCheckListWithData = (data, allLgvList) => {
  let finalCheckList = [];
  let currentLgvCheckList = [];
  let allListOptions = [];

  //Convert all lgv list to Options
  Object.values(allLgvList).forEach((item) => {
    let payload = {
      label: `LGV ${item.lgvId}`,
      value: String(item.lgvId),
    };
    allListOptions.push(payload);
  });

  Object.keys(data).forEach((item) => {
    let payload = {
      label: `LGV ${item}`,
      value: String(item),
    };
    currentLgvCheckList.push(payload);
  });

  //Create a non-duplicate set array from options available
  let nonDuplicateSet = new Set(
    currentLgvCheckList.map((lgvItem) => lgvItem.value)
  );

  //Add disabled property if option is available in all lgv list
  finalCheckList = allListOptions.map((option) => ({
    ...option,
    disabled: nonDuplicateSet.has(option.value) ? false : true,
  }));

  //sort the final array as per disabled property
  finalCheckList.sort((a, b) => a.disabled - b.disabled);
  return structuredClone(finalCheckList);
};

export const getSelectedLgvData = (
  isSelectionFirst,
  setIsSelectionFirst,
  lgvList,
  selectedLgvsList
) => {
  let selectedLgvData = [];

  //Return empty Array if no data from cloud
  if (lgvList.length === 0) {
    return [];
  }

  //No LGVs selected - First time rendering of the markers
  //Push all the LGVs in the data list
  if (selectedLgvsList.length === 0) {
    Object.values(lgvList).forEach((item) => {
      selectedLgvData.push(item);
    });
  }

  //Few or more LGVs selected,
  //With key comparison, filtering the selected LGVs
  selectedLgvsList.forEach((item) => {
    for (const [key, value] of Object.entries(lgvList)) {
      if (item === key) {
        selectedLgvData.push(value);
      }
    }
  });

  return selectedLgvData;
};

export const sortLgvList = (lgvList) => {
  lgvList.sort((a, b) => {
    if (a.navQ > b.navQ) return -1;
    if (a.navQ < b.navQ) return 1;
    return 0;
  });
  return lgvList;
};

export const getBoundsOfView = (plantConfig, bounds) => {
  //Map Config
  const { navHeatmap } = plantConfig;
  const { bigBounds, smallBounds } = navHeatmap;

  const { _northEast, _southWest } = bounds;

  //Right Top
  const rightTop = {
    x: _southWest.lat,
    y: _southWest.lng,
  };
  const startCoordinate = enhancedCoordinateConverter(
    bigBounds,
    smallBounds,
    rightTop,
    "upscale"
  );

  //Left Bottom
  const leftBottom = {
    x: _northEast.lat,
    y: _northEast.lng,
  };
  const endCoordinate = enhancedCoordinateConverter(
    bigBounds,
    smallBounds,
    leftBottom,
    "upscale"
  );
  return {
    startX: Math.ceil(startCoordinate[0]),
    startY: Math.ceil(startCoordinate[1]),
    endX: Math.ceil(endCoordinate[0]),
    endY: Math.ceil(endCoordinate[1]),
  };


};

export const getAllLgvOfThisPlant = async (socket) => {
  try {
    let locationCode = getLocalStorageItem("locationCode");
    await socket.on(`${locationCode}_LGV_LIST`, (data) => {
      if (
        data.results &&
        data.results !== undefined &&
        data.results !== DB_ERROR
      ) {
        return data.results;
        // setAllLgvsOfThisPlant(data.results);
      } else {
        return null;
      }
    });
  } catch (err) {
    console.log("getAllLgvOfThisPlant", err);
  }
};
