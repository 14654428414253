import { useContext, useEffect, useState } from "react";
import {
  apiHeadersFormatter,
  dockDoorStatusColor,
  expireAuthToken,
  findDockDoorPosition,
  getTimeBasedOnUserTime,
  setLocalStorageItem,
} from "../../../util/helper/helperFunctions";
import { AppContext } from "../../../Context/Context";
import { dockDoorBriefStatus } from "../../../util/network/apiUrl";
import { Progress } from "antd";
import check from "../../../assets/icons/check.png";
import error from "../../../assets/icons/error.png";
import minimizeButton from "../../../assets/icons/minimize.png";
import closeButton from "../../../assets/icons/close.png";
import DockDoorStatus from "./DockDoorStatus";
import { getApi } from "../../../util/network/getApi";
import "../../../assets/css/DockDoor/dockDoorPopup.css";
import { DB_ERROR } from "../../../util/helper/constants";

const noData = "No Data Found";

const DockDoorPopup = ({ dockData, dockMode, socket }) => {
  const {
    isMarkerPopupOpen,
    setIsMarkerPopupOpen,
    plantConfig,
    selectedMarkerData,
    minimizedWebObjectsList,
    setMinimizedWebObjectsList,
    setAlertObject,
  } = useContext(AppContext);

  const [dockDoorBriefData, setDockDoorBriefData] = useState();
  const [dockModeLocData, setDockModeLocData] = useState();
  const [palletsLoader, setPalletsLoader] = useState(true);
  const [additionalDataLoader, setAdditionalDataLoader] = useState(true);
  const [additionalDockDoorDetails, setAdditionalDockDoorDetails] = useState({
    dockDoorStatus: {},
    palletsInTrailer: [],
  });
  const [minimizeInitiated, setMinimizeInitiated] = useState(false);

  let dockDetailsInterval;

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsMarkerPopupOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("dock-door-wrapper-id")) {
      killTimeInterval();
      modalCloseHandler();
    }
  });

  const groupDockDoorWithPallets = (rawData) => {
    let groupedDocks = {};
    rawData.map((dock, index) => {
      if (!groupedDocks[dock.POSITION]) {
        groupedDocks[dock.POSITION] = [];
      }
      groupedDocks[dock.POSITION].push(dock);
    });
    return groupedDocks;
  };

  useEffect(() => {
    let dockDoorPosition = findDockDoorPosition(plantConfig);
    if (dockDoorPosition == "DOWN") {
      let localDockDoor = JSON.parse(JSON.stringify(dockData));
      let groupedDocks = groupDockDoorWithPallets(localDockDoor);
      setDockModeLocData(Object.values(groupedDocks).reverse());
    } else {
      let groupedDocks = groupDockDoorWithPallets(dockData);
      setDockModeLocData(groupedDocks);
    }
    setTimeout(() => {
      setPalletsLoader(false);
    }, 1500);

    return () => {
      setPalletsLoader(true);
    };
  }, []);

  useEffect(() => {
    if (selectedMarkerData) {
      let dockDoorPosition = findDockDoorPosition(plantConfig);
      if (dockDoorPosition == "DOWN") {
        let localDockDoor = JSON.parse(JSON.stringify(selectedMarkerData));
        let groupedDocks = groupDockDoorWithPallets(localDockDoor);
        setDockModeLocData(Object.values(groupedDocks).reverse());
      } else {
        let groupedDocks = groupDockDoorWithPallets(selectedMarkerData);
        setDockModeLocData(groupedDocks);
      }
      setTimeout(() => {
        setPalletsLoader(false);
      }, 1500);
    }
  }, [selectedMarkerData]);

  const killTimeInterval = () => {
    clearInterval(dockDetailsInterval);
    dockDetailsInterval = null;
  };

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    setLocalStorageItem("dockDoorTouched", dockData[0]["DOCK"]);
  }, []);

  useEffect(() => {
    if (isMarkerPopupOpen && dockData[0]["DOCK"] !== 0) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
      if (auth && locationCodeLocal) {
        const getDockDoorDetails = async () => {
          try {
            let selectedDock = dockData[0]["DOCK"];
            let dockDoorData = await getApi(
              `${dockDoorBriefStatus}/${selectedDock}?location=${locationCodeLocal}`,
              apiHeadersFormatter(auth.accessToken)
            );
            localStorage.setItem("isModalDetailExist", JSON.stringify(true));
            if (dockDoorData.status == 200) {
              if (dockDoorData.data !== "") {
                setDockDoorBriefData(dockDoorData.data);
              } else {
                setDockDoorBriefData(null);
              }
            } else {
              if (dockDoorData.response.status == 401) {
                expireAuthToken();
              }
            }
          } catch (err) {
            console.log(err);
          }
        };
        //Simple Dock door
        const initiateAdditionalDockDoorSimpleModeWebSocket = () => {
          let locationCodeLocal = JSON.parse(
            localStorage.getItem("locationCode")
          );
          if (socket && locationCodeLocal) {
            socket.on(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`, (data) => {
              if (data.results.length !== 0 && data.results !== DB_ERROR) {
                if (!dockMode) {
                  let metaData = dockData[0];
                  let metaDataObject = {
                    lgvInside: metaData.LGV_IN_TRAILER,
                    doorOpen: metaData.DOOR_OPEN,
                    trailerAtDoor: metaData.TRAILER_AT_DOOR,
                    readyForLoading: metaData.READY_FOR_LOADING,
                    doorOperation: metaData.DOOR_IN_MANUAL,
                    airbagHold: metaData.AIRBAG_HOLD,
                    fault: metaData.FAULT,
                  };
                  setAdditionalDockDoorDetails({
                    ...additionalDockDoorDetails,
                    dockDoorStatus: metaDataObject,
                    palletsInTrailer: dockData,
                  });
                  setAdditionalDataLoader(false);
                  // setAdditionalDockDoorDetails(dockData);
                }
              }
            });
          }
        };
        //Complex Dock door
        const initiateAdditionalDockDoorComplexModeWebSocket = () => {
          let locationCodeLocal = JSON.parse(
            localStorage.getItem("locationCode")
          );
          if (socket && locationCodeLocal) {
            socket.on(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`, (data) => {
              if (data.results.length !== 0 && data.results !== DB_ERROR) {
                if (dockMode) {
                  let metaData = dockData[0];
                  let metaDataObject = {
                    lgvInside: metaData.LGV_IN_TRAILER,
                    doorOpen: metaData.DOOR_OPEN,
                    trailerAtDoor: metaData.TRAILER_AT_DOOR,
                    readyForLoading: metaData.READY_FOR_LOADING,
                    doorOperation: metaData.DOOR_IN_MANUAL,
                    airbagHold: metaData.AIRBAG_HOLD,
                    fault: metaData.FAULT,
                  };
                  setAdditionalDockDoorDetails({
                    ...additionalDockDoorDetails,
                    dockDoorStatus: metaDataObject,
                    palletsInTrailer: dockData,
                  });
                  setAdditionalDataLoader(false);
                }
              }
            });
          }
        };

        initiateAdditionalDockDoorSimpleModeWebSocket();
        initiateAdditionalDockDoorComplexModeWebSocket();

        dockDetailsInterval = setInterval(() => {
          getDockDoorDetails();
        }, 3000);
      }
      return () => {
        clearInterval(dockDetailsInterval);
        dockDetailsInterval = null;
        socket.off(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`);
        socket.off(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`);
      };
    }
  }, [isMarkerPopupOpen]);

  const renderPallets = (value) => {
    if (value.length === 1) {
      return value.map(({ STATUS, SKU }, index) => (
        <Pallet
          key={index}
          pallet={{ STATUS, SKU }}
          className="dock-popup dock-popup-small"
          dockMode={dockMode}
        />
      ));
    } else if (value.length === 2) {
      return (
        <div className="dock dock-popup-big">
          {value.map(({ STATUS, SKU }, index) => (
            <Pallet
              key={index}
              pallet={{ STATUS, SKU }}
              className={`dock-popup ${
                index === 0
                  ? "dock-popup-big-complex-left"
                  : "dock-popup-big-complex-right"
              }`}
              dockMode={dockMode}
            />
          ))}
        </div>
      );
    }
  };

  const renderCardStatus = ({ lgvInside, doorOpen, trailerAtDoor }) => (
    <div className="status-card-status-wrapper">
      {[
        { isActive: lgvInside === 1, label: "LGV Inside" },
        { isActive: doorOpen === 1, label: "Door Open" },
        { isActive: trailerAtDoor === 1, label: "Trailer At Door" },
      ].map(({ isActive, label }) => (
        <div className="status-card">
          <img src={isActive ? check : error} alt={label} />
          <p>{label}</p>
        </div>
      ))}
    </div>
  );

  const labelMapping = {
    doorOperation: {
      0: <p className="auto-text-tag">Auto</p>,
      1: <p className="manual-text-tag">Manual</p>,
    },
    normal: {
      0: <p className="invalid-text-tag">No</p>,
      1: <p className="valid-text-tag">Yes</p>,
    },
  };

  const getTextCardValue = (label, data) =>
    data !== undefined ? labelMapping[label][data] : null;

  const renderTextCardStatus = ({
    readyForLoading,
    doorOperation,
    airbagHold,
    fault,
  }) => (
    <div className="status-card-status-wrapper">
      {[
        {
          label: "Ready for Loading",
          value: getTextCardValue("normal", readyForLoading),
        },
        {
          label: "Door Operation",
          value: getTextCardValue("doorOperation", doorOperation),
        },
        { label: "Airbag Hold", value: getTextCardValue("normal", airbagHold) },
        { label: "Fault", value: getTextCardValue("normal", fault) },
      ].map(({ label, value }) => (
        <div className="status-card-text">
          <p>{label}</p>
          {value}
        </div>
      ))}
    </div>
  );

  const renderTrailerInPalletsTable = (palletsInTrailer) => {
    if (palletsInTrailer.length !== 0) {
      return (
        <table className="pallets-table">
          <tr className="pallets-table-header-section">
            <th className="pallets-table-header">Position in Trailer</th>
            <th className="pallets-table-header">SKU</th>
            <th className="pallets-table-header">LPN</th>
          </tr>
          {palletsInTrailer.map((pallet, index) => {
            return (
              <tr key={index} className="pallets-table-data">
                <td className="pallets-table-row-data">
                  {pallet.POSITION !== null ? pallet.POSITION : "-"}
                </td>
                <td className="pallets-table-row-data">
                  {pallet.SKU !== null ? pallet.SKU : "-"}
                </td>
                <td className="pallets-table-row-data">
                  {pallet.LPN !== null ? pallet.LPN : "-"}
                </td>
              </tr>
            );
          })}
        </table>
      );
    }
  };

  const minimizeModalHandler = () => {
    let currentDockList = minimizedWebObjectsList.docks;
    if (currentDockList.length < 10) {
      let isDockAvailableInList = false;
      currentDockList.forEach((item) => {
        if (item[0].DOCK == dockData[0].DOCK) {
          isDockAvailableInList = true;
        }
      });
      if (isDockAvailableInList == false) {
        currentDockList.push(dockData);
        setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
          ...minimizedWebObjectsList,
          docks: currentDockList,
        }));
        setMinimizeInitiated(true);
        setTimeout(() => {
          modalCloseHandler();
        }, 450);
      } else {
        // setAlertObject({
        //   type: "warning",
        //   payload: {
        //     heading: "Dock Door Already Minimized",
        //     description: "Cannot add the Dock Door which is already in List.",
        //   },
        // });
        modalCloseHandler();
      }
    } else {
      setAlertObject({
        type: "warning",
        payload: {
          heading: "Limit reached!",
          description: "Cannot minimize more than 10 Dock Doors..",
        },
      });
    }
  };

  return (
    <div id="dock-door-wrapper-id" className="dock-door-wrapper">
      <div
        className={`dock-door-wrapper-container ${
          minimizeInitiated ? "modal-animation" : ""
        }`}
      >
        <div className="dock-door-details-section">
          <div className="dock-door-details-header">
            <div className="left-detail-title-section">
              <h3>Dock Door Details</h3>
              <div className="modal-action-buttons">
                <div
                  onClick={() => minimizeModalHandler()}
                  className="modal-action-button-wrapper"
                >
                  <img
                    className="modal-action-button-no-height"
                    src={minimizeButton}
                  />
                </div>
                <div
                  onClick={() => modalCloseHandler()}
                  className="modal-action-button-wrapper"
                >
                  <img className="modal-action-button" src={closeButton} />
                </div>
              </div>
            </div>
          </div>

          <div className="dock-door-details-body">
            <div className="dock-door-detail-left-section">
              {dockDoorBriefData ? (
                <div className="left-detail-data-section">
                  <div className="detail-section-container">
                    {[
                      { label: "Door", value: dockDoorBriefData?.DOOR },
                      {
                        label: "Shipment",
                        value: dockDoorBriefData?.SHIPMENT,
                      },
                      {
                        label: "Snapshot",
                        value: dockDoorBriefData?.CURRENT_DATE_TIME,
                      },
                    ].map(({ label, value }) => (
                      <div className="detail-section">
                        <h4>{label}</h4>
                        <p>
                          {value !== "" && value !== null
                            ? label !== "Snapshot"
                              ? value
                              : getTimeBasedOnUserTime(value).format(
                                  "M/D/YYYY h:mm:ss A z"
                                )
                            : noData}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="detail-section-container">
                    {[
                      { label: "Type", value: dockDoorBriefData?.TYPE },
                      { label: "Status", value: dockDoorBriefData?.STATUS },
                      {
                        label: "Remaining Pallets",
                        value: dockDoorBriefData?.REMAINPALL,
                      },
                      {
                        label: "Load Time",
                        value: dockDoorBriefData?.LOADTIME,
                      },
                      {
                        label: "Process Time",
                        value: dockDoorBriefData?.PROCESSTIME,
                      },
                      {
                        label: "Progress",
                        value: dockDoorBriefData?.PROGRESS,
                      },
                      {
                        label: "Allocated Pallets",
                        value: dockDoorBriefData?.ALLOCATED,
                      },
                      {
                        label: "Released Pallets",
                        value: dockDoorBriefData?.RELEASED,
                      },
                      {
                        label: "Active Pallets",
                        value: dockDoorBriefData?.ACTIVE,
                      },
                    ].map(({ label, value }) => (
                      <div className="detail-section">
                        <h4>{label}</h4>
                        {label !== "Progress" ? (
                          <p>
                            {value !== "" && value !== null ? value : noData}
                          </p>
                        ) : (
                          <Progress
                            percent={value !== "" && value !== null ? value : 0}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  <DockDoorStatus
                    additionalDataLoader={additionalDataLoader}
                    additionalDockDoorDetails={additionalDockDoorDetails}
                    renderTextCardStatus={renderTextCardStatus}
                    renderCardStatus={renderCardStatus}
                  />
                  {/* Pallets in Trailer */}
                  <div className="detail-section-container">
                    <div className="additional-data-section-title extra-bottom-margin">
                      <h3>Pallets in Trailer</h3>
                    </div>
                    {additionalDataLoader ? (
                      <div className="dock-spinner-wrapper">
                        <h4>Fetching Pallets in Trailer...</h4>
                        <div className="spinner"></div>
                      </div>
                    ) : (
                      <>
                        {additionalDockDoorDetails.palletsInTrailer.length !==
                        0 ? (
                          <div className="details-wrapper details-wrapper-scroller">
                            {renderTrailerInPalletsTable(
                              additionalDockDoorDetails.palletsInTrailer
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              ) : null}
              {dockDoorBriefData == undefined ? (
                <div className="dock-door-brief-loader">
                  {dockDoorBriefData === null ? (
                    <>
                      {/* <h4>No Data Available!</h4> */}
                      <DockDoorStatus
                        additionalDataLoader={additionalDataLoader}
                        additionalDockDoorDetails={additionalDockDoorDetails}
                        renderTextCardStatus={renderTextCardStatus}
                        renderCardStatus={renderCardStatus}
                      />
                    </>
                  ) : (
                    <>
                      <h4>Fetching Dock Data...</h4>
                      <div className="spinner"></div>
                    </>
                  )}
                </div>
              ) : null}
            </div>

            <div className="dock-door-detail-right-section">
              <div className="dock-door-popup">
                <div className="dock-door-popup-container">
                  {!palletsLoader ? (
                    <>
                      <div className="dock-popup-top-section">
                        {dockModeLocData[0]
                          ? dockModeLocData[0][0]["DOCK"] &&
                            dockModeLocData[0][0]["DOCK"].split(".")[1]
                          : dockModeLocData[1] &&
                            dockModeLocData[1][0]["DOCK"] &&
                            dockModeLocData[1][0]["DOCK"].split(".")[1]}
                      </div>
                      <div className="dock-popup-bottom-section">
                        {dockModeLocData &&
                          Object.values(dockModeLocData).map((value) =>
                            renderPallets(value)
                          )}
                      </div>
                    </>
                  ) : (
                    <div className="rm-loader">
                      <h4>Loading...</h4>
                      <div className="spinner"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DockDoorPopup;

const Pallet = ({ pallet: { STATUS, SKU }, className, dockMode }) => (
  <div
    style={{
      backgroundColor: dockDoorStatusColor(
        STATUS,
        !dockMode ? "SIMPLE" : "COMPLEX"
      ),
    }}
    className={className}
  >
    <h4>
      {SKU}
      <br />
      {STATUS}
    </h4>
  </div>
);
