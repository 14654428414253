import { useContext } from "react";
import Button from "../Reusables/Button/Button";
import Arrow from "../../assets/icons/arrow.png";
import LeftArrow from "../../assets/icons/leftArrow.png";
import LeftArrowTail from "../../assets/icons/arrowLeft.png";
import InfoSmall from "../../assets/icons/infoSmall.png";
import { AppContext } from "../../Context/Context";
import "../../assets/css/Header/header.css";

const Header = () => {
  const {
    plantConfig,
    timeMachineModeIsEnabled,
    setTimeMachineModeIsEnabled,
    navQualityHeatmapIsEnabled,
    navHeatmapTimeFrames,
    isLevel3ApiComplete,
    setTimeMachineState,
    activeLGVModule,
    setYardSummaryModalIsOpen,
    setIsYardLegendsPopupOpen,
    timeMachineTimeFrames,
    setTimeMachineTimeFrames,
    timeRangePopup,
    setTimeRangePopup,
    customizePopup,
    setCustomizePopup,
    warehouseModelIsOpen,
    setWarehouseModelIsOpen,
    layoutPopup,
    setLayoutPopup,
    showSaveDiscardLayout,
    saveCustomizedLayout,
    discardCustomizedLayout,
  } = useContext(AppContext);

  const exitTimeMachineMode = () => {
    setTimeMachineModeIsEnabled(false);
    localStorage.setItem("isTimeMachineModeEnabled", "false");
    setTimeMachineState(false);
    setTimeMachineTimeFrames({
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
    });
    // reloadApp();
    //Change the Action on back click
    window.close();
  };

  //Nav Quality Heatmap
  // const exitNavQualityHeatmapMode = () => {
  //   setNavQualityHeatmapIsEnabled(false);
  //   setTimeMachineTimeFrames({
  //     fromDate: "",
  //     toDate: "",
  //     fromTime: "",
  //     toTime: "",
  //   });
  //   setNavLevel2Data({});
  //   setNavLevel1Data({});
  //   setNavLevel0Data({});
  //   reloadApp();
  // };

  const openWarehouseModal = () => {
    setWarehouseModelIsOpen(!warehouseModelIsOpen);
    document.body.classList.add("prevent-scroll");
  };

  const renderHeadingBasedOnRoute = () => {
    switch (activeLGVModule) {
      case "yard-view":
        return "Yard View";
      case "allocated-view":
        return "Allocated View";
      case "time-machine":
        return "Time Machine";
      default:
        return "Dashboard";
    }
  };

  const renderFeatureHeading = () => {
    if (timeMachineModeIsEnabled) {
      return (
        <div className="time-machine-header-topic">
          <img onClick={() => exitTimeMachineMode()} src={LeftArrowTail} />
          <h3>Time Machine</h3>
        </div>
      );
    } else if (navQualityHeatmapIsEnabled) {
      return (
        <div className="time-machine-header-topic">
          {/* <img
            onClick={() => exitNavQualityHeatmapMode()}
            src={LeftArrowTail}
          /> */}
          <h3>NavQuality Heatmap</h3>
          {isLevel3ApiComplete ? (
            <div className="level-loader">
              <div className="spinner"></div>
            </div>
          ) : null}
        </div>
      );
    } else {
      return <h3>{renderHeadingBasedOnRoute()}</h3>;
    }
  };

  const renderTimeSelector = (timestamp) => {
    return (
      <div
        onClick={() => setTimeRangePopup(!timeRangePopup)}
        className="time-frame-displayer"
      >
        <div className="from-date">
          {timestamp.fromDate} - {timestamp.fromTime}
        </div>
        to
        <div className="to-date">
          {timestamp.toDate} - {timestamp.toTime}
        </div>
      </div>
    );
  };

  const renderTimeFrame = () => {
    if (timeMachineModeIsEnabled) {
      return renderTimeSelector(timeMachineTimeFrames);
    } else if (navQualityHeatmapIsEnabled) {
      return renderTimeSelector(navHeatmapTimeFrames);
    }
  };

  const renderActiveModule = () => {
    if (timeMachineModeIsEnabled || navQualityHeatmapIsEnabled) {
      return renderTimeFrame();
    } else {
      if (activeLGVModule === "yard-view") {
        return (
          <>
            <Button
              placeholder="Legend"
              btnClass="reverse-content"
              imgClass="reverse-content-img"
              icon={InfoSmall}
              onClick={() => setIsYardLegendsPopupOpen(true)}
            />

            <Button
              placeholder="Yard Summary"
              btnClass="reverse-content"
              imgClass="reverse-content-img"
              icon={Arrow}
              onClick={() => setYardSummaryModalIsOpen(true)}
            />
          </>
        );
      } else if (activeLGVModule === "allocated-view") {
        return null;
      } else {
        if (showSaveDiscardLayout) {
          return (
            <div className="options-section">
              <Button
                placeholder="Discard"
                btnClass="reverse-content customize-btn"
                imgClass="reverse-content-img"
                onClick={() => discardCustomizedLayout()}
              />
              <Button
                placeholder="Save Layout"
                btnClass="reverse-content customize-btn selected"
                imgClass="reverse-content-img"
                onClick={() => saveCustomizedLayout()}
              />
            </div>
          );
        } else {
          return (
            <div className="options-section">
              <Button
                placeholder="Layout"
                icon={Arrow}
                btnClass="reverse-content customize-btn"
                imgClass="reverse-content-img"
                onClick={() => setLayoutPopup(!layoutPopup)}
              />
              <Button
                disabled={!plantConfig}
                placeholder="Customize"
                icon={Arrow}
                btnClass="reverse-content customize-btn"
                imgClass="reverse-content-img"
                onClick={() => setCustomizePopup(!customizePopup)}
              />
              <Button
                placeholder="Warehouse Info"
                icon={LeftArrow}
                btnClass="reverse-content customize-btn warehouse-btn"
                imgClass="reverse-content-img"
                onClick={() => openWarehouseModal()}
              />
            </div>
          );
        }
      }
    }
  };

  return (
    <div className="header-container">
      <div className="title-section">{renderFeatureHeading()}</div>
      <div className="time-options-section">{renderActiveModule()}</div>
    </div>
  );
};

export default Header;
