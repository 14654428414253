import { useEffect, useState } from "react";
import "../../assets/css/RackFrontViewGrid/rackFrontViewGridStyles.css";

const isVerticalPallets = (data) =>
  data?.every(
    ({ rackType, row }) => rackType === "RACK_2" || row === data?.[0].row
  );

const RackFrontViewGrid = ({ locationData }) => {
  const [palletData, setPalletData] = useState([]);

  useEffect(() => {
    if (locationData) {
      const shortenedLocation = locationData?.map(
        ({ location, lpn, quantity, rackType, row, sku }) => ({
          location,
          lpn,
          quantity,
          rackType,
          row,
          sku,
        })
      );
      const groupByLocation = Object.groupBy(
        shortenedLocation,
        ({ location }) => location
      );

      const sortedLocations = Object.keys(groupByLocation).sort(
        (palletA, palletB) => palletB.localeCompare(palletA)
      );

      let modifiedPalletData = sortedLocations
        .map((location) => groupByLocation[location])
        .flat();

      setPalletData(modifiedPalletData);
    }
  }, [locationData]);

  return (
    <div
      data-testid="non-lgv-rack-grid"
      className={`pallet-card-container${
        isVerticalPallets(palletData) ? " flex-column" : ""
      }`}
      style={{
        gridTemplateColumns: `repeat(${Math.max(
          ...palletData?.map((pallet) => pallet.row)
        )}, 1fr)`,
      }}
    >
      {palletData?.map((pallet, index) => (
        <PalletCard key={index} pallet={pallet} />
      ))}
    </div>
  );
};

export default RackFrontViewGrid;

const PalletCard = ({ pallet: { lpn, sku, quantity, location } }) => (
  <div
    className={`${!lpn && !sku && !quantity ? "invisible " : ""}pallet-card`}
  >
    <p className="pallet-value">{location || "No Location"}</p>
    <div className="pallet-details-container">
      <p className="pallet-label">LPN</p>
      <p className="pallet-value">{lpn || "No LPN"}</p>
    </div>
    <div className="pallet-details-container">
      <p className="pallet-label">SKU</p>
      <p className="pallet-value">{sku || "No SKU"}</p>
    </div>
    <div className="pallet-details-container">
      <p className="pallet-label">Quantity</p>
      <p className="pallet-value">{quantity || "No Quantity"}</p>
    </div>
  </div>
);
