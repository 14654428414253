export const getDynamicApiLink = () => {
  const origin = window.location.origin;
  if (
    origin.includes("dev-lgvone") ||
    origin.includes("localhost") ||
    origin.includes("qa-lgvone")
  ) {
    return process.env.REACT_APP_DEV_POINT;
  } else if (origin.includes("uat-lgvone")) {
    return process.env.REACT_APP_UAT_POINT;
  } else if (origin.includes("lgvone.niagarawater")) {
    return process.env.REACT_APP_PROD_POINT;
  }
};

export const getSocketEndPoint = () => {
  const origin = window.location.origin;
  if (
    origin.includes("dev-lgvone") ||
    origin.includes("localhost") ||
    origin.includes("qa-lgvone")
  ) {
    return process.env.REACT_APP_ON_PREM_POINT;
  } else if (origin.includes("uat-lgvone")) {
    return process.env.REACT_APP_ON_PREM_POINT_UAT;
  } else if (origin.includes("lgvone.niagarawater")) {
    return process.env.REACT_APP_PROD_ON_PREM_POINT;
  }
};

export const plantLocations = `${getDynamicApiLink()}/plantlocations`;
export const yardLegend = `${window.location.origin}/dummyAPI/yardLegend.json`;
export const plantDetailsAPI = `${window.location.origin}/dummyAPI/plants/`;
export const lgvHistoryStatus = `${getDynamicApiLink()}/lgvtraffic`;
export const heatmapDetails = `${getDynamicApiLink()}/heatmapdetails`;
export const dockDoorBriefStatus = `${getDynamicApiLink()}/dockstatus`;
export const plantConfigUrl = `${getDynamicApiLink()}/config`;

//Databricks

//TimeMachine

export const DOCKDOOR_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=DOCK_DOORS`;
export const LGV_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=LGV_MOVEMENTS`;
export const PRODUCTION_LINES_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=PRODUCTION_LINES`;
export const RACK_STORAGES_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=RACK_STORAGES`;
export const BLOCK_STORAGES_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=BLOCK_STORAGES`;
export const MAX_TIMESTAMP_TIMEMACHINE_URL = `${getDynamicApiLink()}/queryexecutor?featureType=MAX_TIMESTAMP_FINDER`;

//Heatmap
export const navHeatmapLevel2 = `${getDynamicApiLink()}/queryexecutor?featureType=AGGREGATED_NAVQ`;
export const navHeatmapLevel3 = `${getDynamicApiLink()}/queryexecutor?featureType=RAW_NAVQ`;
