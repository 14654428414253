import { useContext, useEffect, useMemo, useState } from "react";
import "../../../assets/css/NonLgvModal/nonLgvModalStyles.css";
import { AppContext } from "../../../Context/Context";
import closeBtn from "../../../assets/icons/close.png";
import whiteTick from "../../../assets/icons/whitetick.png";
import BlockRackTable from "../BlockRackTable/BlockRackTable";

const NonLgvModal = ({ nonLgvData }) => {
  const { isNonLgvModalOpen, setIsNonLgvModalOpen } = useContext(AppContext);
  const [nonLgvSocketData, setNonLgvSocketData] = useState({});
  const [selectedBlockKey, setSelectedBlockKey] = useState(null);
  const [selectedBlockData, setSelectedBlockData] = useState(null);

  const closeModalHandler = () => {
    setIsNonLgvModalOpen(false);
    document.body.classList.remove("prevent-scroll");
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("lgv-wrapper-id")) {
      closeModalHandler();
    }
  });

  //Group the raw data to Object with the Location Name as Key
  const formatNonLgvStructure = (data) => {
    let locationGroup = {};
    data.map((location) => {
      let name = location.location;
      if (name.split(".").length > 1) {
        name = name.split(".").slice(0, 2).join(".");
      }
      if (!locationGroup[name]) {
        locationGroup[name] = [];
      }
      locationGroup[name].push(location);
    });
    return locationGroup;
  };

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    setNonLgvSocketData(formatNonLgvStructure(nonLgvData));
  }, [nonLgvData]);

  const lgvListItemHandler = (key, value) => {
    setSelectedBlockKey(key);
    setSelectedBlockData(JSON.parse(JSON.stringify(value)));
  };

  //Get the logic to Render Block or Rack
  const RenderBlockOrRackDetails = useMemo(() => {
    if (selectedBlockData !== null && selectedBlockKey !== null) {
      return <BlockRackTable locationData={selectedBlockData} />;
    } else {
      return <div>Select Any Location to View Details!</div>;
    }
  }, [selectedBlockKey, selectedBlockData]);

  //Return Either the List item is selected or unselected
  const enableSelectionOnList = (key) => {
    if (selectedBlockKey !== null) {
      if (selectedBlockKey == key) {
        return "selected";
      } else {
        return "unselected";
      }
    } else {
      return "unselected";
    }
  };

  return (
    <div id="lgv-wrapper-id" className="popup-wrapper">
      <div className="popup-container">
        <div className="popup-header">
          <div className="popup-title">Non LGV Inventories</div>
          <div className="popup-close">
            <div
              onClick={closeModalHandler}
              className="close-btn"
              id="close-btn"
            >
              <img src={closeBtn} alt="close button" />
            </div>
          </div>
        </div>
        {Object.keys(nonLgvSocketData).length !== 0 ? (
          <div className="popup-bottom">
            <div className="left-selector-section">
              <div className="left-section-scroller">
                <ul className="storage-list">
                  {nonLgvSocketData &&
                    Object.entries(nonLgvSocketData).map(([key, value]) => {
                      return (
                        <li
                          key={key}
                          data-testid="non-lgv-list-item"
                          className={`storage-list-item ${enableSelectionOnList(
                            key
                          )}`}
                          onClick={() => lgvListItemHandler(key, value)}
                        >
                          {" "}
                          {selectedBlockKey == key ? (
                            <img src={whiteTick} />
                          ) : null}
                          <p>{key}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div
              className={`right-display-section ${
                selectedBlockKey == null ? "display-flex" : ""
              }`}
            >
              {RenderBlockOrRackDetails}
            </div>
          </div>
        ) : (
          <div className="popup-bottom display-flex">
            <div className="non-lgv-table-loader">
              <h4>Loading Non-LGV Inventory...</h4>
              <div className="non-lgv-table-spinner"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NonLgvModal;
